import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledContentContainer, StyledPageContent } from './ServerOfflineLayout.styled';
import Typography from '../../components/Typography';
import BoxOffline from '../../components/BoxOfflineSvg';
import { HeaderProps } from '../../components/Header';
import styled from 'styled-components';
import Spinner from '../../components/Spinner';

export type ServerOfflineLayoutProps = {
  renderHeader: () => ReactElement<HeaderProps>;
};

const LargeSpinner = styled(Spinner)`
  height: 3rem;
  width: 3rem;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ServerOfflineLayout = (props: ServerOfflineLayoutProps) => {
  const { t } = useTranslation();
  return (
    <StyledPageContent>
      {props.renderHeader()}
      <StyledContentContainer className="ion-padding">
        <div className="content">
          <BoxOffline className="svg-container" />
          <Typography tag="h5" variant="secondary">
            {t('serverOfflineDesc')}
          </Typography>
          <SpinnerContainer>
            <LargeSpinner />
          </SpinnerContainer>
          <Typography tag="h6" variant="secondary">
            {t('serverOfflineLoading')}
          </Typography>
        </div>
      </StyledContentContainer>
    </StyledPageContent>
  );
};

export default ServerOfflineLayout;
