import { useRef } from 'react';
import Typography from '../Typography';
import { ButtonContainer, StyledButtonChange } from './SwitchBox.styled';
import { swapHorizontal } from 'ionicons/icons';
import { IonIcon, IonLabel, IonRippleEffect, IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SwitchableBox } from '../../services/api.service';

export type SwitchBoxProps = {
  boxName: string;
  displayedBoxes?: SwitchableBox[];
  onBoxChange?: (domain: string) => void;
};

const SwitchBox = (props: SwitchBoxProps) => {
  const { t } = useTranslation();
  const boxesSelectRef = useRef<HTMLIonSelectElement>(null);
  return (
    <ButtonContainer
      button
      detailIcon={undefined}
      onClick={() => {
        boxesSelectRef.current?.open();
      }}
    >
      <IonRippleEffect />
      <div className="button-content">
        <Typography tag="h5" variant="secondary">
          {props.boxName}
        </Typography>
        <StyledButtonChange>
          <Typography tag="h5" variant="secondary" className="swap">
            <span className="icon-swap">
              <IonIcon icon={swapHorizontal} />
            </span>

            <div className="menu-item">
              <IonLabel>{t('changeGym')}</IonLabel>
            </div>
            <IonSelect
              style={{ display: 'none' }}
              label={t('changeGym')}
              mode="ios"
              ref={boxesSelectRef}
              okText={t('change')}
              cancelText={t('cancel')}
              onIonChange={(e) => {
                props.onBoxChange?.(e.detail.value);
              }}
            >
              {(props.displayedBoxes || []).map((box) => (
                <IonSelectOption value={box.domain} key={`select-item-box-${box.domain}`}>
                  {box.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </Typography>
        </StyledButtonChange>
      </div>
    </ButtonContainer>
  );
};

export default SwitchBox;
