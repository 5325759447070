import { useEffect } from 'react';
import Header, { HeaderProps } from '../../components/Header';
import { useGetSettingsQuery } from '../../services/cdn.service';
import { useLazyGetSwitchableBoxesQuery } from '../../services/api.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { setBoxSettingsBottomSheetIsOpen } from '../../store/modaleSlice';
import { isAdminApp } from '../../utils/platform';
import { userIsLogged } from '../../utils/security';

export const HeaderWithSwitchBox = (props: HeaderProps) => {
  const { data: settings } = useGetSettingsQuery();
  const [trigger, { data: switchableBoxes }] = useLazyGetSwitchableBoxesQuery();
  const appState = useSelector((state: RootState) => state.AppReducer);
  const dispatch = useDispatch();

  const enableSwitchBox = !appState.isAdminMode && settings?.button?.href === 'https://www.fitnesspark.fr';

  useEffect(() => {
    if (enableSwitchBox) {
      trigger();
    }
  }, [enableSwitchBox, trigger]);

  return (
    <Header
      {...(isAdminApp() || userIsLogged()
        ? {
            onSettingButton: () => {
              dispatch(setBoxSettingsBottomSheetIsOpen(true));
            },
          }
        : {})}
      switchBox={
        enableSwitchBox
          ? {
              boxName: appState.boxDetail?.name || '',
              displayedBoxes: switchableBoxes || [],
              onBoxChange: (domain) => {
                window.open(`https://${domain}.tshoko.fr`, '_self');
              },
            }
          : undefined
      }
      dotted={settings?.button?.href?.includes('tshoko.fr')}
      {...props}
    />
  );
};
