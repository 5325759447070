import { IonContent, IonItem, IonLabel, IonNavLink, IonRippleEffect } from '@ionic/react';
import {
  StyledPageContent,
  StyledHeaderContainer,
  StyledArtistTitleContainer,
  StyledIonHeader,
  StyledLeftHeader,
  StyledRightHeader,
} from './ArtistLayout.styled';
import InfiniteVirtualScroll from '../../../components/InfiniteVirtualScroll';
import TrackItem, { TrackItemDefaultProps } from '../../../components/TrackItem';
import Typography from '../../../components/Typography';
import IconButton from '../../../components/IconButton';
import { chevronBack, ellipsisVertical } from 'ionicons/icons';
import AlbumCard, { AlbumCardDefaultProps } from '../../../components/AlbumCard';
import List from '../../../components/List';
import { useTranslation } from 'react-i18next';
import { sharpen } from '../../../utils/number';
import ArtistCard, { ArtistCardDefaultProps } from '../../../components/ArtistCard';
import SeeMore from '../../../components/SeeMore';
import { StyledIonListHeader, StyledIonList } from '../../../components/List/List.styled';
import { StyledIonNavLink } from '../../../features/Drawer/Drawer.styled';

export type ArtistLayoutDefaultProps = {
  loading?: boolean;
  artist: {
    id: number;
    name: string;
    img?: string;
    bio?: string;
    tracks: TrackItemDefaultProps['track'][];
    albums: AlbumCardDefaultProps['album'][];
    similarArtists: ArtistCardDefaultProps['artist'][];
  };
  loadDataAlbums?: () => void;
  isAlbumsFetching: boolean;
  onClickTrack: (track: TrackItemDefaultProps['track']) => void;
  onClickMore: (artist: { id: number; name: string; img?: string }) => void;
  onClickTrackVote?: (track: TrackItemDefaultProps['track']) => void;
  onArtistClickDrawerComponent?: (props: { artistId: number }) => JSX.Element;
  onAlbumClickDrawerComponent?: (props: { albumId: string }) => JSX.Element;
  onArtistAlbumsClickDrawerComponent?: (props: { artistId: number }) => JSX.Element;
  onArtistTracksClickDrawerComponent?: (props: { artistId: number }) => JSX.Element;
  adminMode?: boolean;
};

type ArtistLayoutLoadingProps = {
  loading: true;
  onClickMore?: (artist: { id: string; name: string; img?: string }) => void;
};

export type ArtistLayoutProps = ArtistLayoutDefaultProps | ArtistLayoutLoadingProps;

const ArtistLayout = (props: ArtistLayoutProps) => {
  const { t } = useTranslation();
  const nbItemsPerView = sharpen(window.innerWidth / (320 / 2));
  const onArtistClickDrawerComponent = 'onArtistClickDrawerComponent' in props && props.onArtistClickDrawerComponent;
  const onAlbumClickDrawerComponent = 'onAlbumClickDrawerComponent' in props && props.onAlbumClickDrawerComponent;
  const onArtistAlbumsClickDrawerComponent =
    'onArtistAlbumsClickDrawerComponent' in props && props.onArtistAlbumsClickDrawerComponent;
  const onArtistTracksClickDrawerComponent =
    'onArtistTracksClickDrawerComponent' in props && props.onArtistTracksClickDrawerComponent;

  return (
    <IonContent>
      <StyledPageContent>
        <StyledHeaderContainer>
          <StyledIonHeader>
            <StyledLeftHeader>
              <IonNavLink routerDirection="back">
                <IconButton icon={chevronBack} size="large" />
              </IonNavLink>
            </StyledLeftHeader>
            <StyledRightHeader>
              {!props.loading && props.adminMode && props.onClickMore && (
                <IconButton icon={ellipsisVertical} onClick={() => props.onClickMore?.(props.artist)} />
              )}
            </StyledRightHeader>
          </StyledIonHeader>
        </StyledHeaderContainer>
        <IonContent>
          <div>
            <StyledArtistTitleContainer>
              <div className="artistcard-container" style={{ width: `calc(100% / ${nbItemsPerView / 1.5})` }}>
                {props.loading ? <ArtistCard loading hideText /> : <ArtistCard artist={props.artist} hideText />}
              </div>
              <div className="artist-name">
                {props.loading ? (
                  <Typography loading tag="h2" />
                ) : (
                  <Typography tag="h2">{props.artist.name}</Typography>
                )}
              </div>
            </StyledArtistTitleContainer>
            {props.loading ? (
              <div style={{ marginBottom: 12 }}>
                <StyledIonListHeader>
                  <IonItem className="header-item" button>
                    <IonRippleEffect />
                    <IonLabel className="header-label">
                      <div>
                        <Typography tag="h3" loading />
                      </div>
                    </IonLabel>
                  </IonItem>
                </StyledIonListHeader>
                <StyledIonList className="list">
                  {Array.from({ length: 3 }).map((track, i) => (
                    <TrackItem key={`track-loading-${i}`} loading />
                  ))}
                </StyledIonList>
              </div>
            ) : props.artist.tracks.length ? (
              <div style={{ marginBottom: 12 }}>
                {onArtistTracksClickDrawerComponent ? (
                  <IonNavLink
                    routerDirection="forward"
                    key={`ionnavlink-${props.artist.id}-artistTracks`}
                    component={() => onArtistTracksClickDrawerComponent({ artistId: props.artist.id })}
                  >
                    <StyledIonListHeader>
                      <IonItem className="header-item" button>
                        <IonRippleEffect />
                        <IonLabel className="header-label">
                          <div>
                            <Typography tag="h3" loading={props.loading}>
                              {t('tracks')}
                            </Typography>
                          </div>
                        </IonLabel>
                      </IonItem>
                    </StyledIonListHeader>
                  </IonNavLink>
                ) : (
                  <StyledIonListHeader>
                    <IonItem className="header-item" button>
                      <IonRippleEffect />
                      <IonLabel className="header-label">
                        <div>
                          <Typography tag="h3" loading={props.loading}>
                            {t('tracks')}
                          </Typography>
                        </div>
                      </IonLabel>
                    </IonItem>
                  </StyledIonListHeader>
                )}
                <StyledIonList className="list">
                  {props.artist.tracks.slice(0, 3).map((track, i) => (
                    <TrackItem
                      key={`track-${track.id}-${i}`}
                      track={track}
                      onClick={() => props.onClickTrack(track)}
                      onVoteClick={
                        props.onClickTrackVote
                          ? () => {
                              props.onClickTrackVote?.(track);
                            }
                          : undefined
                      }
                    />
                  ))}
                </StyledIonList>
              </div>
            ) : null}
            {props.loading || props.isAlbumsFetching ? (
              <div style={{ marginBottom: 12 }}>
                <StyledIonListHeader>
                  <IonItem className="header-item" button>
                    <IonRippleEffect />
                    <IonLabel className="header-label">
                      <div>
                        <Typography tag="h3" loading />
                      </div>
                    </IonLabel>
                  </IonItem>
                </StyledIonListHeader>
                <StyledIonList className="list">
                  <InfiniteVirtualScroll
                    fetching
                    loading
                    nbItemsPerView={nbItemsPerView}
                    spinningElement={<AlbumCard loading />}
                  />
                </StyledIonList>
              </div>
            ) : props.artist.albums.length ? (
              <div style={{ marginBottom: 12 }}>
                {onArtistAlbumsClickDrawerComponent ? (
                  <IonNavLink
                    routerDirection="forward"
                    key={`ionnavlink-${props.artist.id}-artistAlbums`}
                    component={() => onArtistAlbumsClickDrawerComponent({ artistId: props.artist.id })}
                  >
                    <StyledIonListHeader>
                      <IonItem className="header-item" button>
                        <IonRippleEffect />
                        <IonLabel className="header-label">
                          <div>
                            <Typography tag="h3" loading={props.loading}>
                              {t('albums')}
                            </Typography>
                          </div>
                        </IonLabel>
                      </IonItem>
                    </StyledIonListHeader>
                  </IonNavLink>
                ) : (
                  <StyledIonListHeader>
                    <IonItem className="header-item" button>
                      <IonRippleEffect />
                      <IonLabel className="header-label">
                        <div>
                          <Typography tag="h3" loading={props.loading}>
                            {t('albums')}
                          </Typography>
                        </div>
                      </IonLabel>
                    </IonItem>
                  </StyledIonListHeader>
                )}
                <StyledIonList className="list">
                  <InfiniteVirtualScroll
                    loadData={props.loading ? undefined : props.loadDataAlbums}
                    fetching={props.loading ? undefined : props.isAlbumsFetching}
                    loading={props.loading}
                    nbItemsPerView={nbItemsPerView}
                    spinningElement={<AlbumCard loading />}
                  >
                    {props.loading
                      ? undefined
                      : props.artist.albums.map((album, i) => {
                          return onAlbumClickDrawerComponent ? (
                            <StyledIonNavLink
                              key={`album-${album.title}-${i}`}
                              routerDirection="forward"
                              component={() => onAlbumClickDrawerComponent({ albumId: album.id })}
                            >
                              <AlbumCard album={album} onClick={() => {}} />
                            </StyledIonNavLink>
                          ) : (
                            <AlbumCard album={album} />
                          );
                        })}
                  </InfiniteVirtualScroll>
                </StyledIonList>
              </div>
            ) : null}
            {(props.loading || props.artist.bio) && (
              <List
                header={
                  <Typography tag="h3" loading={props.loading}>
                    {t('about')}
                  </Typography>
                }
              >
                <div className="artist-bio">
                  {props.loading ? (
                    <Typography loading variant="secondary" />
                  ) : (
                    <Typography variant="secondary">
                      <SeeMore>
                        <div dangerouslySetInnerHTML={{ __html: props.artist.bio || '' }} />
                      </SeeMore>
                    </Typography>
                  )}
                </div>
              </List>
            )}
            {(props.loading || props.artist.similarArtists.length > 0) && (
              <List
                header={
                  <Typography tag="h3" loading={props.loading}>
                    {t('similarArtists')}
                  </Typography>
                }
              >
                <InfiniteVirtualScroll
                  loading={props.loading}
                  nbItemsPerView={nbItemsPerView}
                  spinningElement={<ArtistCard loading />}
                >
                  {props.loading
                    ? undefined
                    : props.artist.similarArtists.map((artist, i) => {
                        return onArtistClickDrawerComponent ? (
                          <StyledIonNavLink
                            key={`artist-${artist.name}-${i}`}
                            routerDirection="forward"
                            component={() => onArtistClickDrawerComponent({ artistId: artist.id })}
                          >
                            <ArtistCard artist={artist} onClick={() => {}} />
                          </StyledIonNavLink>
                        ) : (
                          <ArtistCard artist={artist} />
                        );
                      })}
                </InfiniteVirtualScroll>
              </List>
            )}
          </div>
        </IonContent>
      </StyledPageContent>
    </IonContent>
  );
};

export default ArtistLayout;
