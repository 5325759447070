export const transformJsonKeys = (jsonFile: Record<string, unknown>) => {
  const originalObject = Object(jsonFile);
  const transformedObject = {} as Record<string, string>;

  for (const key in originalObject) {
    if (typeof originalObject[key] === 'object') {
      const nestedKeys = transformJsonKeys(originalObject[key]);
      for (const nestedKey in nestedKeys) {
        if (Object.prototype.hasOwnProperty.call(nestedKeys, nestedKey)) {
          transformedObject[`${key}.${nestedKey}`] = nestedKeys[nestedKey];
        }
      }
    } else {
      transformedObject[key] = originalObject[key];
    }
  }

  return transformedObject;
};

export const spotImageSourceToCdnUrl = (s3Key: string) => `${process.env.REACT_APP_CDN_HOSTNAME}spots/${s3Key}`;
