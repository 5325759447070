import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Typography from '../../components/Typography';
import { playSkipForward, shuffle } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import IconButton from '../../components/IconButton';
import {
  StyledPageContent,
  StyledContentContainer,
  StyledIonLabel,
  StyledCurrentTitle,
  StyledCurrentArtist,
  StyledButtonContainer,
} from './PlayerContentLayout.styled';
import PlayerProgressBar from '../../components/PlayerProgressBar';
import Explicit from '../../components/Explicit';
import { CurrentTrack } from '../../store/playerSlice';
import { differenceInMilliseconds } from 'date-fns';
import JukeboxIcon from '../../components/Icons/JukeboxIcon';
import Button from '../../components/Button';
import { HeaderProps } from '../../components/Header';
import BackToProgButton from '../../components/BackToProgButton';
import SpotIcon from '../../components/Icons/SpotIcon';
import { isSpot } from '../../utils/typeGuards';
import VolumeHandlerLayout from '../VolumeHandlerLayout';
import { spotImageSourceToCdnUrl } from '../../utils/file';

export type PlayerContentLayoutProps = {
  loading?: boolean;
  admin?: boolean;
  progName?: string;
  isEphemeral?: boolean;
  track?: CurrentTrack;
  toasted?: boolean;
  volumeValue: number;
  onVolumeChange: (volumeValue: number) => void;
  onMuteChange: (action: string) => void;
  renderHeader: () => ReactElement<HeaderProps>;
  onSkipForward: () => void;
  jukebox: boolean;
  jukeboxDisabled: boolean;
  shuffle: boolean;
  onJukeboxButton: () => void;
  onBackToProgButton: () => void;
  onShuffleButton: () => void;
};

type Widths = { scrollWidth: number; clientWidth: number };
const isOverflown = ({ clientWidth, scrollWidth }: Widths) => scrollWidth > clientWidth;

const PlayerContentLayout = (props: PlayerContentLayoutProps) => {
  const { t } = useTranslation();
  const [widthsTitle, setWidthsTitle] = useState<Widths>({
    scrollWidth: 0,
    clientWidth: 0,
  });
  const [widthsArtist, setWidthsArtist] = useState<Widths>({
    scrollWidth: 0,
    clientWidth: 0,
  });
  const [forcedRender, setForcedRender] = useState(0);
  const titleDivRef = useRef<HTMLDivElement>(null);
  const artistDivRef = useRef<HTMLDivElement>(null);

  const titleOverflown = isOverflown(widthsTitle);
  const artistOverflown = isOverflown(widthsArtist);

  useEffect(() => {
    const titleAndArtistDivRendered =
      !!titleDivRef.current?.scrollWidth &&
      !!titleDivRef.current?.clientWidth &&
      !!artistDivRef.current?.scrollWidth &&
      !!artistDivRef.current?.clientWidth;
    if (titleAndArtistDivRendered) {
      setWidthsTitle({
        scrollWidth: titleDivRef.current.scrollWidth,
        clientWidth: titleDivRef.current.clientWidth,
      });
      setWidthsArtist({
        scrollWidth: artistDivRef.current.scrollWidth,
        clientWidth: artistDivRef.current.clientWidth,
      });
    } else {
      setForcedRender((prevForcedRender) => ++prevForcedRender);
    }
  }, [props, forcedRender]);

  return (
    <StyledPageContent>
      {props.renderHeader()}
      <StyledContentContainer className={`ion-padding ${props.toasted ? 'toasted' : ''}`}>
        <div className="controls">
          <div className="prog-name">
            {!props.loading && props.admin && (
              <>
                {props.isEphemeral ? (
                  <BackToProgButton label={props.progName} onClick={() => props.onBackToProgButton()} />
                ) : (
                  <Typography tag="h6"> {props.progName} </Typography>
                )}
              </>
            )}
          </div>
          <div className="cover">
            {props.track?.img ? (
              <div
                className="current"
                style={{
                  backgroundImage: `url(${
                    typeof props.track.id === 'number'
                      ? props.track?.img?.replace('_230.', '_600.')
                      : spotImageSourceToCdnUrl(props.track.img)
                  })`,
                }}
              />
            ) : isSpot(props.track) ? (
              <div className="current spot">
                {props.track.img ? (
                  <img src={spotImageSourceToCdnUrl(props.track.img)} style={{ borderRadius: 4 }} />
                ) : (
                  <SpotIcon />
                )}
              </div>
            ) : null}
          </div>
          <div className="progress-bar-container">
            {!props.loading && props.track && (
              <PlayerProgressBar
                timer={differenceInMilliseconds(new Date(), new Date(props.track.playingDate))}
                duration={props.track.duration}
                displayTime
              />
            )}
          </div>
          <div>
            {props.loading ? (
              <StyledIonLabel>
                <StyledCurrentTitle widths={widthsTitle}>
                  <Typography tag="h3" loading />
                </StyledCurrentTitle>
                <StyledCurrentArtist widths={widthsArtist}>
                  <Typography tag="h4" loading />
                </StyledCurrentArtist>
              </StyledIonLabel>
            ) : (
              <StyledIonLabel>
                <StyledCurrentTitle widths={widthsTitle}>
                  <h3 className={`title ion-text-nowrap ${titleOverflown ? 'overflown' : ''}`}>
                    <div ref={titleDivRef}>{props.track?.title || 'Annonce'}</div>
                  </h3>
                  {props.track?.explicit && <Explicit />}
                </StyledCurrentTitle>
                <StyledCurrentArtist widths={widthsArtist}>
                  <h4 className={`artist ion-text-nowrap ${artistOverflown ? 'overflown' : ''}`}>
                    <div ref={artistDivRef}>{props.track?.artist.name}</div>
                  </h4>
                </StyledCurrentArtist>
              </StyledIonLabel>
            )}
          </div>
          {props.admin && (
            <VolumeHandlerLayout
              volumeValue={props.volumeValue}
              onChange={(volumeValue) => props.onVolumeChange(volumeValue)}
              onMuteChange={(action) => props.onMuteChange(action)}
            />
          )}
          <div className="music-controls">
            {!props.loading && props.admin && (
              <>
                {
                  <StyledButtonContainer
                    {...(props.jukeboxDisabled ? { style: { opacity: 0 } } : {})}
                    className={props.jukebox ? 'active' : ''}
                  >
                    <Button fill="clear" onClick={() => props.onJukeboxButton()}>
                      <JukeboxIcon />
                      <Typography>{t(props.jukebox ? 'jukeboxActivated' : 'jukeboxDeactivated')}</Typography>
                    </Button>
                  </StyledButtonContainer>
                }
                <div>
                  <IconButton
                    icon={shuffle}
                    color={props.shuffle ? 'primary' : undefined}
                    onClick={() => props.onShuffleButton()}
                  />
                  <IconButton icon={playSkipForward} onClick={() => props.onSkipForward()} />
                </div>
              </>
            )}
          </div>
        </div>
      </StyledContentContainer>
    </StyledPageContent>
  );
};

export default PlayerContentLayout;
