import { IonItem, IonImg, IonLabel, IonThumbnail } from '@ionic/react';
import styled from 'styled-components';
import { StyledIonThumbnail } from '../AmbianceCard/AmbianceCard.styled';

export const StyledIonItem = styled(IonItem)`
  --border-color: transparent;
  --background: none;
  --padding-start: 0;
  --inner-padding-end: 0;
  flex: 1;

  &::part(native) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 16px;
  }
`;

export const StyledSoundDesignIonThumbnail = styled(IonThumbnail)`
  &.soundDesign-thumbnail {
    position: relative;
    display: grid;
    height: auto;
    aspect-ratio: 1 / 1;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 4%;
    overflow: hidden;

    & ion-skeleton-text {
      height: 100%;
      width: 100%;
      border-radius: 4%;
      margin: 0;
    }

    &:not(.loading) {
      background: rgba(var(--ion-color-dark-contrast-rgb), 1);
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    & ${StyledIonThumbnail} {
      border-radius: 0;
    }
  }
`;

export const StyledIonImg = styled(IonImg)`
  aspect-ratio: 1 / 1;
`;

export const StyledIonLabel = styled(IonLabel)`
  width: 100%;
  padding-bottom: 0px;
  margin-bottom: 0px;
`;

export const StyledSoundDesignTitle = styled.div`
  display: flex;
  align-items: center;
  & ion-text {
    overflow: hidden;
    & h3 {
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;

      & ion-skeleton-text {
        margin: 0;
        height: 19px !important;
      }
    }
  }
  & ion-icon {
    margin-top: 0;
  }
`;
