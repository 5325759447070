import { IonItem, IonImg, IonLabel } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonItem = styled(IonItem)`
  --border-color: transparent;
  --background: none;
  --padding-start: 0;
  --inner-padding-end: 0;
  flex: 1;

  &::part(native) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 16px;
  }

  & .album-thumbnail {
    height: auto;
    aspect-ratio: 1 / 1;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  &.is-blacklisted {
    filter: grayscale(1);
    opacity: 0.5;
  }
`;

export const StyledIonImg = styled(IonImg)`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

export const StyledIonLabel = styled(IonLabel)`
  width: 100%;
  padding-bottom: 0px;
  margin-bottom: 0px;
`;

export const StyledAlbumTitle = styled.div`
  display: flex;
  align-items: center;
  & ion-text {
    overflow: hidden;
    & h3 {
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;

      & ion-skeleton-text {
        margin: 0;
        height: 19px !important;
      }
    }
  }
  & ion-icon {
    margin-top: 0;
  }
`;

export const StyledAlbumArtist = styled.div`
  display: flex;
  justify-content: space-between;
  & ion-text {
    overflow: hidden;
    & h4 {
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;

      & ion-skeleton-text {
        margin: 0;
        margin-top: 2px;
        height: 18px !important;
      }
    }
  }
`;
