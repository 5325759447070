import { IonContent } from '@ionic/react';
import { SpotCardDefaultProps } from '../../../../components/SpotCard';
import useKeyboard from '../../../../utils/hooks/useKeyboard';
import InfiniteGrid from '../../../../components/InfiniteGrid';
import SpotListItem from '../../../../components/SpotListItem';
import { useFeatureAvailable } from '../../../../utils/hooks/useFeatureAvailable';

const SearchTabSpots = (props: {
  loading?: boolean;
  onLoadData?: () => void;
  onClickSpot: (spot: SpotCardDefaultProps['spot']) => void;
  spots: SpotCardDefaultProps['spot'][];
}) => {
  const { hideKeyboard } = useKeyboard();
  const playSpotFeatureAvailable = useFeatureAvailable('playSpotFeature');
  return (
    <>
      {playSpotFeatureAvailable ? (
        <IonContent scrollEvents onIonScrollStart={() => hideKeyboard?.()}>
          <InfiniteGrid loadingElement={<SpotListItem loading />} loading={props.loading} onScroll={props.onLoadData}>
            {props.spots.map((spot, index) => (
              <SpotListItem
                key={`spot-${index}`}
                spot={spot}
                onClick={() => {
                  props.onClickSpot(spot);
                }}
              />
            ))}
          </InfiniteGrid>
        </IonContent>
      ) : null}
    </>
  );
};

export default SearchTabSpots;
