import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../../components/AlertModal';
import { closeAlbumBottomSheet, closeConfirmBlacklistAlbumModale } from '../../../store/modaleSlice';
import { RootState } from '../../../store/rootReducer';
import { setToast } from '../../../store/toastSlice';
import SocketService from '../../../services/socket';
import { useUpdateBlacklistMutation } from '../../../services/api.service';

const ConfirmBlacklistAlbum = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appState = useSelector((state: RootState) => state.AppReducer);

  const confirmBlacklistAlbumModale = useSelector(
    (state: RootState) => state.ModaleReducer.confirmBlacklistAlbumModale,
  );
  const confirmBlacklistAlbumModaleIsOpen = useSelector(
    (state: RootState) => state.ModaleReducer.confirmBlacklistAlbumModaleIsOpen,
  );
  const [updateBlacklist] = useUpdateBlacklistMutation();

  return (
    <AlertModal
      isOpen={confirmBlacklistAlbumModaleIsOpen}
      onDidDismiss={() => {
        dispatch(closeConfirmBlacklistAlbumModale());
        dispatch(closeAlbumBottomSheet());
      }}
      header={t('blacklistAlbumTitle')}
      subHeader={t('blacklistAlbumDesc')}
      buttons={[
        {
          text: t('back'),
          role: 'cancel',
        },
        {
          text: t('confirm'),
          role: 'confirm',
          handler: () => {
            if (!confirmBlacklistAlbumModale) {
              return;
            }
            updateBlacklist({
              type: 'album',
              albumId: confirmBlacklistAlbumModale.id,
              title: confirmBlacklistAlbumModale.title,
              artist: confirmBlacklistAlbumModale.artist.name,
              clientId: appState.boxDetail?._id || '',
              img: confirmBlacklistAlbumModale.img,
            })
              .unwrap()
              .then(() => {
                SocketService.blacklistChanged();
                dispatch(
                  setToast({
                    isOpen: true,
                    message: t('albumBlacklisted'),
                    duration: 5000,
                  }),
                );
              })
              .catch(() => {
                dispatch(
                  setToast({
                    isOpen: true,
                    message: t('albumBlacklistedError'),
                    duration: 5000,
                  }),
                );
              });
          },
        },
      ]}
    />
  );
};

export default ConfirmBlacklistAlbum;
