import { useRef, useState } from 'react';
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import Button from '../../components/Button';
import { useTranslation, TFuncKey } from 'react-i18next';
import TextField from '../../components/TextField';
import Typography from '../../components/Typography';
import { lockClosed, personCircleOutline } from 'ionicons/icons';
import { StyledContentContainer, StyledForgotPasswordButton, StyledForm } from './LoginLayout.styled';
import DottedWrapper from '../../components/DottedWrapper/DottedWrapper';
import { useGetSettingsQuery } from '../../services/cdn.service';
import { isWhiteLabel } from '../../utils/platform';

export type LoginLayoutProps = {
  error?: TFuncKey;
  spinning?: boolean;
  loading?: boolean;
  username?: string;
  password?: string;
  onClick: (username: string, password: string) => void;
  img?: string;
};

export const LoginLayout = (props: LoginLayoutProps): JSX.Element => {
  const { t } = useTranslation();
  const usernameRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);
  const [username, setUsername] = useState(props.username || '');
  const [password, setPasword] = useState(props.password || '');
  const { data: settings } = useGetSettingsQuery();

  const onKeyPressed = (key: string, fieldType: string) => {
    if (key == 'Enter') {
      if (fieldType === 'username') {
        passwordRef.current?.setFocus();
      } else {
        props.onClick(username, password);
      }
    }
  };

  const logoNode = (
    <div>
      <img src="./theme/mobileapp-logo.png" style={{ width: '100%' }} />
    </div>
  );

  return (
    <StyledContentContainer>
      <div
        className="content"
        style={{
          backgroundImage: `url(${
            props.img || 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
          })`,
        }}
      >
        <StyledForm>
          <IonGrid style={{ margin: 'auto' }} className="ion-align-items-center">
            <IonRow className="ion-justify-content-center ion-text-center">
              <IonCol style={{ maxWidth: 320 }}>
                {settings?.button?.href?.includes('tshoko.fr') ? (
                  <DottedWrapper size={22}>{logoNode}</DottedWrapper>
                ) : (
                  logoNode
                )}

                <TextField
                  aria-label={t('username')}
                  type="text"
                  name="username"
                  id="username"
                  ref={usernameRef}
                  enterkeyhint="next"
                  clearInput
                  startIcon={<IonIcon icon={personCircleOutline} />}
                  disabled={props.spinning}
                  required
                  loading={props.loading}
                  onKeyUp={(e) => onKeyPressed(e.key, 'username')}
                  onIonInput={(e) => setUsername(e.target.value ? `${e.target.value}` : '')}
                  value={username}
                />
                <TextField
                  aria-label={t('password')}
                  type="password"
                  name="password"
                  id="password"
                  ref={passwordRef}
                  enterkeyhint="go"
                  clearInput
                  startIcon={<IonIcon icon={lockClosed} />}
                  disabled={props.spinning}
                  required
                  loading={props.loading}
                  onKeyUp={(e) => onKeyPressed(e.key, 'password')}
                  onIonInput={(e) => setPasword(e.target.value ? `${e.target.value}` : '')}
                  value={password}
                />
                <IonRow class="ion-margin ion-justify-content-center">
                  <Button
                    spinning={props.spinning}
                    onClick={() => props.onClick(username, password)}
                    disabled={!username && !password}
                    loading={props.loading}
                  >
                    {t('login')}
                  </Button>
                  <a
                    href={
                      isWhiteLabel()
                        ? 'https://espaceclient.music-pro.fr/login/forgot-password'
                        : 'https://espaceclient.tshoko.fr/login/forgot-password'
                    }
                    target="_blank"
                  >
                    <StyledForgotPasswordButton fill="clear" size="small">
                      {t('forgotPassword')}
                    </StyledForgotPasswordButton>
                  </a>
                </IonRow>
              </IonCol>
            </IonRow>
            {props.error && (
              <IonRow className="ion-justify-content-center">
                <IonCol size="auto">
                  <Typography color={'danger'}>{t(props.error)}</Typography>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </StyledForm>
      </div>
    </StyledContentContainer>
  );
};

export default LoginLayout;
