import styled from 'styled-components';
import { IonModal } from '@ionic/react';

export const StyledIonModal = styled(IonModal)`
  --width: 100%;
  --height: 100%;
  --background: rgba(var(--ion-color-dark-contrast-rgb), 0.54);
  --border-radius: 0;
  backdrop-filter: blur(5px);
  margin-bottom: calc(var(--ion-safe-area-bottom) + 51px);

  &::part(handle) {
    width: 100%;
    height: calc(var(--ion-safe-area-top) + 16px);
    opacity: 0;
  }
`;
