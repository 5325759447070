import styled from 'styled-components';

export const StyledToast = styled.div`
  position: fixed;
  z-index: 2;
  bottom: var(--ion-safe-area-bottom);
  height: 50px;
  width: 100%;
  opacity: 0;
  transition: all 0.25s;
  background: var(--ion-color-primary);
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  --text-color-primary: rgba(0, 0, 0, 0.87);
  --text-color-secondary: rgba(0, 0, 0, 0.54);
  visibility: hidden;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  & h4,
  & h5 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }

  & h4 {
    font-weight: 600;
  }

  &.clickable {
    cursor: pointer;
  }
`;
