import AlbumLayout, { AlbumLayoutProps } from '../../layouts/Search/AlbumLayout';
import { useGetBlacklistsQuery } from '../../services/api.service';
import { useGetAlbumQuery } from '../../services/qobuz.service';
import { buildAlbumDetailedFromAlbumDetailedDto } from '../Search/types';
import useConnectionLostGuard from '../hooks/useConnectionLostGuard';
import { RootState } from '../../store/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useVoteTrackHook } from '../hooks/useVoteTrack';
import { setAlbumBottomSheet, setConfirmPlayAlbumModale, setTrackBottomSheet } from '../../store/modaleSlice';

type AlbumDrawerType = { albumId: string };

const AlbumDrawer = (props: AlbumDrawerType) => {
  const dispatch = useDispatch();
  const appState = useSelector((state: RootState) => state.AppReducer);

  const connectionLostGuard = useConnectionLostGuard();
  const { data, isFetching } = useGetAlbumQuery({
    album_id: `${props.albumId}`,
  });
  const votesList = useSelector((state: RootState) => state.PlayerReducer.votesList);
  const isJukeboxModeOn = useSelector((state: RootState) => state.PlayerReducer.params.isJukeboxModeOn);
  const voteTrack = useVoteTrackHook();

  const { data: aggregatedBlacklists } = useGetBlacklistsQuery(appState.boxDetail?._id || '');
  const albumDetail = data && buildAlbumDetailedFromAlbumDetailedDto(data, aggregatedBlacklists, votesList);

  const albumsActions: Pick<AlbumLayoutProps, 'onPlayClick' | 'onMoreClick'> = appState.isAdminMode
    ? {
        onPlayClick: (album) => {
          connectionLostGuard(() => {
            dispatch(setConfirmPlayAlbumModale({ trackIds: album.tracks.map((t) => t.id) }));
          });
        },
        onMoreClick: (album) => {
          dispatch(setAlbumBottomSheet(album));
        },
      }
    : {};

  return albumDetail && !isFetching ? (
    <AlbumLayout
      adminMode={appState.isAdminMode}
      album={albumDetail}
      onClickTrackVote={
        isJukeboxModeOn
          ? (track) => {
              voteTrack(track);
            }
          : undefined
      }
      onClickTrack={(track) => {
        dispatch(setTrackBottomSheet(track));
      }}
      {...albumsActions}
    />
  ) : (
    <AlbumLayout loading {...albumsActions} />
  );
};

export default AlbumDrawer;
