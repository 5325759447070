import { IonBadge, IonButton } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonButton = styled(IonButton)`
  &.vote-button {
    --padding-start: 0;
    --padding-end: 0;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    font-size: 20px;
    aspect-ratio: 1/1;
    opacity: 1;
  }

  & div {
    width: 20px;
    height: 20px;
    pointer-events: none;
  }
`;

export const StyledIonBadge = styled(IonBadge)`
  position: absolute;
  border-radius: 16px;
  font-weight: 600;
  left: calc(50% + 7px);
  top: 12px;
  font-size: 8px;
  align-items: center;
  display: flex;
  line-height: inherit;
  height: 14px;
  min-width: 14px;
  text-align: center;
  justify-content: center;
  padding: 0 4px;
`;

export const VoteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  & ion-button {
    margin: 0;
  }
  & > div {
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
