import React from 'react';
import { useTranslation } from 'react-i18next';
import BottomSheet, { BottomSheetProps } from '../../components/BottomSheet';
import { Without } from '../../utils/types';
import { IonIcon, IonLabel, IonThumbnail } from '@ionic/react';
import {
  StyledTrackIonItem,
  StyledIonItem,
  StyledTrackSubTitle,
  StyledTrackTitle,
} from './TrackBottomSheetLayout.styled';
import { person, play, banOutline } from 'ionicons/icons';
import Explicit from '../../components/Explicit';
import Typography from '../../components/Typography';
import PlaylistRemoveIcon from '../../components/PlaylistRemoveIcon';
import PlayLaterIcon from '../../components/Icons/PlayLaterIcon';
import CurrentMusicIcon from '../../components/Icons/CurrentMusicIcon';
import VoteButton from '../../components/VoteButton';
import { Track } from '../../store/playerSlice';
import { StyledTrackBlacklistedTag } from '../../components/TrackItem/TrackItem.styled';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import AddToPlaylist from '../../components/AddToPlaylistModale/AddToPlaylist';

export type TrackBottomSheetLayoutProps = Without<BottomSheetProps, 'onDidDismiss' | 'isOpen'>;

type TrackBottomSheetProps = {
  track: Track;
  actions?: {
    onSeeArtistButton?: () => void;
    removeTrackFromWaitlistButton?: () => void;
    blacklistTitle?: () => void;
    playNow?: () => void;
    prependToPriorityQueue?: () => void;
    appendToPriorityQueue?: () => void;
    vote?: () => void;
    addToPlaylist?: boolean;
  };
};

export const useTrackBottomSheetLayoutProps = () => {
  const { t } = useTranslation();
  const { isAdminMode } = useSelector((state: RootState) => state.AppReducer);

  const TrackBottomSheetLayoutBuilder = (props: TrackBottomSheetProps) => {
    return {
      header: (
        <StyledTrackIonItem className={`track-item`} mode="md">
          <IonThumbnail className="track-thumbnail" slot="start">
            <img src={props.track.img} />
          </IonThumbnail>
          <IonLabel>
            <StyledTrackTitle>
              <Typography tag="h3" className="ion-text-nowrap">
                {props.track.title}
              </Typography>
              {props.track.explicit && <Explicit />}
            </StyledTrackTitle>
            <StyledTrackSubTitle>
              <Typography tag="h4" className="ion-text-nowrap">
                {props.track.artist?.name || ''}
              </Typography>
            </StyledTrackSubTitle>
          </IonLabel>
          {isAdminMode && props.track.blacklisted && (
            <StyledTrackBlacklistedTag>{t('blacklistedTrack')}</StyledTrackBlacklistedTag>
          )}
        </StyledTrackIonItem>
      ),
      content: (
        <>
          {props.actions?.onSeeArtistButton && (
            <StyledIonItem button mode="md" onClick={() => props.actions?.onSeeArtistButton?.()}>
              <div className="menu-item">
                <div className="item-icon">
                  <IonIcon icon={person} />
                </div>
                <IonLabel>{t('seeArtistPage')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
          {props.actions?.removeTrackFromWaitlistButton && (
            <StyledIonItem button mode="md" onClick={() => props.actions?.removeTrackFromWaitlistButton?.()}>
              <div className="menu-item">
                <div className="item-icon">
                  <PlaylistRemoveIcon />
                </div>
                <IonLabel>{t('removeTrack')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
          {props.actions?.vote && (
            <StyledIonItem button mode="md" disabled={props.track.blacklisted} onClick={() => props.actions?.vote?.()}>
              <div className="menu-item">
                <div style={{ width: 24, display: 'block' }} className="item-icon">
                  <VoteButton voted={props.track.voted} votes={props.track.votes} iconOnly />
                </div>
                <IonLabel>{props.track.voted ? t('downvoteMusic') : t('voteMusic')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
          {props.actions?.playNow && (
            <StyledIonItem
              button
              mode="md"
              onClick={() => {
                props.actions?.playNow?.();
              }}
            >
              <div className="menu-item">
                <div className="item-icon">
                  <IonIcon icon={play} />
                </div>
                <IonLabel>{t('playNow')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
          {props.actions?.appendToPriorityQueue && (
            <StyledIonItem button mode="md" onClick={() => props.actions?.appendToPriorityQueue?.()}>
              <div className="menu-item">
                <div className="item-icon">
                  <CurrentMusicIcon />
                </div>
                <IonLabel>{t('appendToQueue')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
          {props.actions?.prependToPriorityQueue && (
            <StyledIonItem button mode="md" onClick={() => props.actions?.prependToPriorityQueue?.()}>
              <div className="menu-item">
                <div className="item-icon">
                  <PlayLaterIcon />
                </div>
                <IonLabel>{t('addToPriorityQueue')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
          {props.actions?.addToPlaylist && <AddToPlaylist track={props.track} />}
          {props.actions?.blacklistTitle && (
            <StyledIonItem
              button
              mode="md"
              disabled={props.track.blacklisted}
              onClick={() => props.actions?.blacklistTitle?.()}
            >
              <div className="menu-item">
                <div className="item-icon">
                  <IonIcon icon={banOutline} />
                </div>
                <IonLabel>{t('blacklistTitle')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
        </>
      ),
      initialBreakpoint:
        Math.ceil(
          ((148 + Object.values(props.actions || {}).filter((a) => !!a).length * 48) * 100) / window.innerHeight,
        ) / 100,
    };
  };
  return TrackBottomSheetLayoutBuilder;
};

// used only in storybook :
const TrackBottomSheetLayout = (
  props: Without<BottomSheetProps, keyof TrackBottomSheetLayoutProps> & TrackBottomSheetProps,
) => {
  const TrackBottomSheetLayoutPropsBuilder = useTrackBottomSheetLayoutProps();
  const { track, actions, ...bottomSheetProps } = props;
  return (
    <BottomSheet
      {...bottomSheetProps}
      {...TrackBottomSheetLayoutPropsBuilder({
        track,
        actions,
      })}
    />
  );
};

export default TrackBottomSheetLayout;
