import styled from 'styled-components';

export const StyledTabs = styled.div`
  & ion-tab-bar {
    --background: var(--ion-background-color);
    --ion-color-primary: var(--ion-color-step-900);
    border-top: 1px solid var(--text-color-secondary);
    display: flex !important;
    &.tab-bar-hidden {
      display: flex !important;
    }
  }
  & ion-label {
    font-size: 10px;
    font-family: 'Jost';
  }
`;
