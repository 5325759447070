import { IonItem } from '@ionic/react';
import styled from 'styled-components';

export const StyledTrackIonItem = styled(IonItem)`
  &.track-item {
    --background: none;
    --inner-border-width: 0;
    --border-color: transparent;
    scroll-snap-stop: normal;
    scroll-snap-align: start;

    & .track-thumbnail img {
      border-radius: 4px;
    }
  }
  & .sc-ion-label-md-s {
    margin-bottom: 0;
    padding-bottom: 11px;

    & h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }
    & h4 {a
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      color: var(--text-color-secondary);
    }
  }

  &:not(.loading) {
    & .spot-thumbnail {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: var(--text-color-secondary);

      & ion-icon {
        width: 32px;
        height: 32px;
      }
    }
  }
}
`;

export const StyledTrackSubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  & ion-text {
    overflow: hidden;
  }
`;

export const StyledTrackTitle = styled.div`
  display: flex;
  align-items: center;
  & ion-text {
    overflow: hidden;
  }
`;

export const StyledIonItem = styled(IonItem)`
  --background: none;
  --border-color: transparent;
  color: var(--text-color-secondary);

  &.item-disabled {
    filter: grayscale(1);
  }

  & .menu-item {
    display: flex;
    width: 100%;
    height: 48px;
    justify-content: center;
    align-items: center;

    & .item-icon {
      display: flex;
      margin-inline-end: 12px;
      margin-top: 12px;
      margin-bottom: 12px;
      font-size: 24px;
    }
    & ion-label {
      width: 212px;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
`;
