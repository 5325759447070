import React from 'react';
import { IonContent, IonHeader, IonNavLink } from '@ionic/react';
import { StyledPageContent, StyledHeaderContainer, StyledArtistTitleContainer } from './ArtistAlbumsLayout.styled';
import Typography from '../../../components/Typography';
import IconButton from '../../../components/IconButton';
import { chevronBack } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import AlbumCard, { AlbumCardDefaultProps } from '../../../components/AlbumCard';
import { StyledIonNavLink } from '../../../features/Drawer/Drawer.styled';
import InfiniteGrid from '../../../components/InfiniteGrid';
import AlbumDrawer from '../../../features/Drawer/AlbumDrawer';

export type ArtistAlbumsLayoutDefaultProps = {
  loading?: boolean;
  artist: {
    id: number;
    name: string;
  };
  albums: AlbumCardDefaultProps['album'][];
  loadDataAlbums?: () => void;
  isAlbumsFetching: boolean;
  onAlbumClickDrawerComponent?: (p: { albumId: string }) => JSX.Element;
};

type ArtistAlbumsLayoutLoadingProps = {
  loading: true;
};

export type ArtistAlbumsLayoutProps = ArtistAlbumsLayoutDefaultProps | ArtistAlbumsLayoutLoadingProps;

const ArtistAlbumsLayout = (props: ArtistAlbumsLayoutProps) => {
  const { t } = useTranslation();

  return (
    <>
      <IonContent>
        <StyledPageContent>
          <StyledHeaderContainer>
            <IonHeader>
              <IonNavLink routerDirection="back">
                <IconButton icon={chevronBack} size="large" />
              </IonNavLink>
              <StyledArtistTitleContainer>
                {props.loading ? (
                  <Typography tag="h2" loading />
                ) : (
                  <Typography tag="h2">{props.artist.name}</Typography>
                )}
                {props.loading ? (
                  <Typography tag="h5" variant="secondary" loading />
                ) : (
                  <Typography tag="h5" variant="secondary">
                    {t('lastAlbums')}
                  </Typography>
                )}
              </StyledArtistTitleContainer>
            </IonHeader>
          </StyledHeaderContainer>
          <IonContent>
            {!props.loading && (
              <InfiniteGrid
                loadingElement={<AlbumCard loading />}
                loading={props.isAlbumsFetching}
                onScroll={props.loadDataAlbums}
                elementWidth={`calc(100% / ${Math.floor(window.innerWidth / (320 / 3))})`}
              >
                {props.albums.map((album, index) => (
                  <StyledIonNavLink
                    key={`album-${album.id}-${index}`}
                    routerDirection="forward"
                    component={() => <AlbumDrawer albumId={album.id} />}
                  >
                    <AlbumCard album={album} onClick={() => {}} />
                  </StyledIonNavLink>
                ))}
              </InfiniteGrid>
            )}
          </IonContent>
        </StyledPageContent>
      </IonContent>
    </>
  );
};

export default ArtistAlbumsLayout;
