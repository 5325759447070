import React from 'react';
import { IonListHeader, IonSkeletonText } from '@ionic/react';
import { StyledIonHeader, StyledHeaderContainer } from './Header.styled';
import Typography from '../Typography';
import { chevronDown, settingsSharp } from 'ionicons/icons';
import IconButton from '../IconButton';
import SwitchBox, { SwitchBoxProps } from '../SwitchBox';
import DottedWrapper from '../DottedWrapper/DottedWrapper';

export type HeaderProps = {
  title?: string;
  subtitle?: string;
  onSettingButton?: () => void;
  onDismissModal?: () => void;
  loading?: boolean;
  switchBox?: SwitchBoxProps;
  dotted?: boolean;
};

const Header = (props: HeaderProps) => {
  const subtitleNode = (
    <Typography tag="h2" loading={props.loading}>
      {props.subtitle}
    </Typography>
  );
  return (
    <StyledHeaderContainer>
      <StyledIonHeader>
        <div className="header-logo">
          <IonListHeader>
            {props.loading ? (
              <IonSkeletonText animated={true} style={{ height: 41, width: 150 }} />
            ) : (
              <img src="./theme/mobileapp-logo.png" className="logo" />
            )}
          </IonListHeader>
          <div>
            {!props.loading && props.onSettingButton && (
              <IconButton icon={settingsSharp} onClick={() => props.onSettingButton?.()} />
            )}
          </div>
        </div>
        <div>
          {props.onDismissModal && <IconButton icon={chevronDown} size="large" onClick={props.onDismissModal} />}
        </div>
      </StyledIonHeader>
      {props.title && (
        <div className="title-header">
          <div className="title">
            <Typography tag="h1" loading={props.loading}>
              {props.title}
            </Typography>
          </div>
        </div>
      )}
      {props.subtitle && (props.dotted ? <DottedWrapper size={12}>{subtitleNode}</DottedWrapper> : subtitleNode)}
      {!props.loading && props.switchBox && <SwitchBox {...props.switchBox} />}
    </StyledHeaderContainer>
  );
};

export default Header;
