import BottomSheet, { BottomSheetProps } from '../../components/BottomSheet';
import { Spot } from '../../store/playerSlice';
import { Without } from '../../utils/types';
import {
  StyledIonItem,
  StyledTrackIonItem,
  StyledTrackSubTitle,
  StyledTrackTitle,
} from './SpotBottomSheetLayout.styled';
import { IonIcon, IonLabel, IonThumbnail } from '@ionic/react';
import Typography from '../../components/Typography';
import SpotIcon from '../../components/Icons/SpotIcon';
import { useTranslation } from 'react-i18next';
import { play } from 'ionicons/icons';
import { addSeconds, format } from 'date-fns';
import { spotImageSourceToCdnUrl } from '../../utils/file';

export type SpotBottomSheetLayoutProps = Without<BottomSheetProps, 'onDidDismiss' | 'isOpen'>;

type SpotBottomSheetProps = {
  spot: Spot;
  actions: {
    playNow?: () => void;
  };
};

export const useSpotBottomSheetLayoutProps = () => {
  const { t } = useTranslation();

  const SpotBottomSheetLayoutBuilder = (props: SpotBottomSheetProps) => {
    return {
      header: (
        <StyledTrackIonItem className={`track-item`} mode="md">
          <IonThumbnail className="spot-thumbnail" slot="start">
            {props.spot.img ? (
              <img src={spotImageSourceToCdnUrl(props.spot.img)} style={{ borderRadius: 4 }} />
            ) : (
              <SpotIcon />
            )}
          </IonThumbnail>
          <IonLabel>
            <StyledTrackTitle>
              <Typography tag="h3" className="ion-text-nowrap">
                {props.spot.title}
              </Typography>
            </StyledTrackTitle>
            <StyledTrackSubTitle>
              <Typography tag="h4" className="ion-text-nowrap">
                {format(addSeconds(new Date(0), props.spot.duration / 1000 || 30), 'mm:ss')}
              </Typography>
            </StyledTrackSubTitle>
          </IonLabel>
        </StyledTrackIonItem>
      ),
      content: (
        <>
          {props.actions.playNow && (
            <StyledIonItem
              button
              mode="md"
              onClick={() => {
                props.actions?.playNow?.();
              }}
            >
              <div className="menu-item">
                <div className="item-icon">
                  <IonIcon icon={play} />
                </div>
                <IonLabel>{t('playNow')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
        </>
      ),
      initialBreakpoint:
        Math.ceil(
          ((148 + Object.values(props.actions || {}).filter((a) => !!a).length * 48) * 100) / window.innerHeight,
        ) / 100,
    };
  };
  return SpotBottomSheetLayoutBuilder;
};

// used only in storybook :
const TrackBottomSheetLayout = (
  props: Without<BottomSheetProps, keyof SpotBottomSheetLayoutProps> & SpotBottomSheetProps,
) => {
  const SpotBottomSheetLayoutBuilder = useSpotBottomSheetLayoutProps();
  const { spot, actions, ...bottomSheetProps } = props;
  return (
    <BottomSheet
      {...bottomSheetProps}
      {...SpotBottomSheetLayoutBuilder({
        spot,
        actions,
      })}
    />
  );
};

export default TrackBottomSheetLayout;
