import React from 'react';
import { IonContent } from '@ionic/react';
import InfiniteGrid from '../../../../components/InfiniteGrid';
import AmbianceCard, { AmbianceCardDefaultProps } from '../../../../components/AmbianceCard';
import AmbianceDrawer from '../../../../features/Drawer/AmbianceDrawer';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import { StyledIonNavLink } from '../../../../features/Drawer/Drawer.styled';

const SearchTabAmbiances = (props: { loading?: boolean; ambiances: AmbianceCardDefaultProps['ambiance'][] }) => {
  return (
    <IonContent scrollEvents onIonScrollStart={() => Capacitor.getPlatform() !== 'web' && Keyboard.hide()}>
      <InfiniteGrid
        loadingElement={<AmbianceCard loading />}
        loading={props.loading}
        elementWidth={`calc(100% / ${Math.floor(window.innerWidth / (320 / 2))})`}
      >
        {props.ambiances.map((ambiance, index) => (
          <StyledIonNavLink
            key={`ambiance-${ambiance.id}-${index}`}
            routerDirection="forward"
            component={() => <AmbianceDrawer ambianceId={ambiance.id} color={ambiance.color} />}
          >
            <AmbianceCard key={`ambiance-${ambiance.id}-${index}`} ambiance={ambiance} onClick={() => {}} />
          </StyledIonNavLink>
        ))}
      </InfiniteGrid>
    </IonContent>
  );
};

export default SearchTabAmbiances;
