import { IonButton, IonRippleEffect } from '@ionic/react';
import { ReactNode } from 'react';
import { StyledIonButton, StyledIonButtonSpinner, StyledIonButtonSkeletonContent } from './Button.styled';

type IonButtonProps = React.ComponentPropsWithRef<typeof IonButton>;
export type ButtonProps = Omit<IonButtonProps, 'children'> & {
  children?: ReactNode;
  spinning?: boolean;
  loading?: boolean;
};

const Button = (props: ButtonProps) => {
  const shape = props.shape || 'round';
  return (
    <StyledIonButton className={'button'} shape={shape} {...props} color="secondary">
      <IonRippleEffect />
      {props.spinning && <StyledIonButtonSpinner />}
      {props.loading && <StyledIonButtonSkeletonContent />}
      <div className={'button-content'} style={props.spinning || props.loading ? { opacity: 0 } : {}}>
        {props.children}
      </div>
    </StyledIonButton>
  );
};

export default Button;
