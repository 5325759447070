import React, { useState } from 'react';
import { SearchBarContainer, StyledPageContent } from '../SearchLayout/SearchLayout.styled';
import { StyledHeaderContainer } from './RestrictedSearchLayout.styled';
import { IonHeader } from '@ionic/react';
import SearchBar from '../../../components/SearchBar';
import { t } from 'i18next';
import TrackItem, { TrackItemDefaultProps } from '../../../components/TrackItem';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { stringsAreSimilar } from '../../../utils/string';

type RestrictedSearchProps = {
  tracks: TrackItemDefaultProps['track'][];
  onClickTrack: (track: TrackItemDefaultProps['track']) => void;
  onClickTrackVote?: (track: TrackItemDefaultProps['track']) => void;
};

const RestrictedSearchLayout = ({ tracks, onClickTrack, onClickTrackVote }: RestrictedSearchProps) => {
  const [searchQuery, setSearchQuery] = useState<string | null | undefined>('');

  const searchedTracks = searchQuery ? tracks.filter((track) => stringsAreSimilar(track.title, searchQuery)) : tracks;

  return (
    <StyledPageContent style={{ height: '100vh' }}>
      <StyledHeaderContainer>
        <IonHeader>
          <SearchBarContainer>
            <SearchBar
              forceOpen
              placeholder={t('searchRestrictedJukeboxPlaceholder')}
              onIonInput={(e) => setSearchQuery(e.detail.value)}
              debounce={250}
            />
          </SearchBarContainer>
        </IonHeader>
      </StyledHeaderContainer>
      <AutoSizer>
        {({ height, width }) => (
          <List
            itemCount={searchedTracks.length}
            itemSize={72}
            itemData={searchedTracks}
            height={height}
            width={width}
            style={{ paddingBottom: 72 * 2 }}
          >
            {({ index, data, style }) => (
              <div style={{ ...style }}>
                <TrackItem
                  track={data[index]}
                  onClick={() => onClickTrack(data[index])}
                  onVoteClick={() => onClickTrackVote?.(data[index])}
                />
              </div>
            )}
          </List>
        )}
      </AutoSizer>
    </StyledPageContent>
  );
};

export default RestrictedSearchLayout;
