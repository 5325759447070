import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const cdn = createApi({
  reducerPath: 'cdn',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
  }),
  endpoints: (builder) => ({
    getSettings: builder.query<{ button?: { label?: string; href?: string } }, void>({
      query: () => ({ url: `/theme/mobileapp-settings.json` }),
    }),
  }),
});

export const { useGetSettingsQuery } = cdn;
