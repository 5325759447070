import styled from 'styled-components';

export const StyledPageContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: calc(var(--ion-safe-area-top) - 8px);

  & .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    background-position: center;
    background-size: cover;
  }
`;
