import React from 'react';
import { IonContent, IonHeader, IonNavLink } from '@ionic/react';
import { StyledPageContent, StyledHeaderContainer, StyledArtistTitleContainer } from './SoundDesignLayout.styled';
import Typography from '../../../components/Typography';
import IconButton from '../../../components/IconButton';
import { chevronBack } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import AmbianceCard, { AmbianceCardDefaultProps } from '../../../components/AmbianceCard';
import InfiniteGrid from '../../../components/InfiniteGrid';
import { Without } from '../../../utils/types';
import { StyledIonNavLink } from '../../../features/Drawer/Drawer.styled';

export type SoundDesignLayoutDefaultProps = {
  loading?: boolean;
  soundDesign: {
    id: string;
    name: string;
  };
  ambiancesCount: number;
  ambiances: Without<AmbianceCardDefaultProps['ambiance'], 'soundDesignName'>[];
  onAmbianceClickDrawerComponent?: (props: { ambianceId: string; color: string }) => JSX.Element;
};

type SoundDesignLayoutLoadingProps = {
  loading: true;
};

export type SoundDesignLayoutProps = SoundDesignLayoutDefaultProps | SoundDesignLayoutLoadingProps;

const SoundDesignLayout = (props: SoundDesignLayoutProps) => {
  const { t } = useTranslation();
  const onAmbianceClickDrawerComponent =
    'onAmbianceClickDrawerComponent' in props && props.onAmbianceClickDrawerComponent;

  return (
    <IonContent>
      <StyledPageContent>
        <StyledHeaderContainer>
          <IonHeader>
            <IonNavLink routerDirection="back">
              <IconButton icon={chevronBack} size="large" />
            </IonNavLink>
            <StyledArtistTitleContainer>
              {props.loading ? (
                <Typography tag="h2" loading />
              ) : (
                <Typography tag="h2">{props.soundDesign.name}</Typography>
              )}
              {props.loading ? (
                <Typography tag="h5" variant="secondary" loading />
              ) : (
                <Typography tag="h5" variant="secondary">
                  {`${props.ambiancesCount} ${t('ambiances')}`}
                </Typography>
              )}
            </StyledArtistTitleContainer>
          </IonHeader>
        </StyledHeaderContainer>
        <IonContent>
          <InfiniteGrid
            loadingElement={<AmbianceCard loading />}
            loading={props.loading}
            elementWidth={`calc(100% / ${Math.floor(window.innerWidth / (320 / 2))})`}
          >
            {props.loading
              ? undefined
              : props.ambiances.map((ambiance, index) =>
                  onAmbianceClickDrawerComponent ? (
                    <StyledIonNavLink
                      key={`ambiance-${ambiance.id}-${index}`}
                      routerDirection="forward"
                      component={() =>
                        onAmbianceClickDrawerComponent({ ambianceId: ambiance.id, color: ambiance.color })
                      }
                    >
                      <AmbianceCard
                        ambiance={{ ...ambiance, soundDesignName: props.soundDesign.name }}
                        hideSoundDesignName
                        onClick={() => {}}
                      />
                    </StyledIonNavLink>
                  ) : (
                    <AmbianceCard
                      ambiance={{ ...ambiance, soundDesignName: props.soundDesign.name }}
                      hideSoundDesignName
                    />
                  ),
                )}
          </InfiniteGrid>
        </IonContent>
      </StyledPageContent>
    </IonContent>
  );
};

export default SoundDesignLayout;
