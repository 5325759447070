import { FixedSizeList } from 'react-window';
import TrackItem, { TrackItemProps } from '../TrackItem';
import AutoSizer from 'react-virtualized-auto-sizer';

type TrackListProps = {
  tracks: TrackItemProps[];
};

const TrackList = (props: TrackListProps) => {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height}
          width={width}
          itemCount={props.tracks.length}
          itemSize={72}
          itemData={props.tracks}
          style={{ paddingBottom: 72 }}
        >
          {({ index, data, style }) => (
            <div style={style}>
              <TrackItem {...data[index]} />
            </div>
          )}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default TrackList;
