import React, { Ref } from 'react';
import { IonButton, IonIcon, IonRippleEffect, IonSkeletonText } from '@ionic/react';
import { StyledIonButton } from './IconButton.styled';

type IonButtonProps = React.ComponentProps<typeof IonButton>;
export type IconButtonProps = IonButtonProps & {
  icon?: string;
  loading?: boolean;
  innerRef?: Ref<HTMLIonButtonElement>;
};

const IconButton = (props: IconButtonProps) => {
  return props.loading ? (
    <IonSkeletonText
      animated={true}
      style={{ width: props.size == 'small' ? '32px' : '48px', height: props.size == 'small' ? '32px' : '48px' }}
    />
  ) : (
    <StyledIonButton
      {...props}
      fill="clear"
      className={`button ${props.className || ''}`}
      shape={'round'}
      ref={props.innerRef}
    >
      <IonRippleEffect />
      <div>
        {props.icon && <IonIcon slot="icon-only" icon={props.icon} />}
        {props.children}
      </div>
    </StyledIonButton>
  );
};

export default IconButton;
