import jwtDecode from 'jwt-decode';
import { differenceInHours } from 'date-fns';

export const getJwt = () => localStorage.getItem('token');
export const setJwt = (newJwt?: string) => {
  if (newJwt) {
    localStorage.setItem('token', newJwt);
  } else {
    localStorage.removeItem('token');
  }
};

export type JWTContent = {
  userId: string;
  name: string;
  roles: JWTRole[];
  iat: number;
  exp: number;
  aud: string;
  iss: string;
  currentBox: {
    admin: boolean;
    id: string;
  };
};
export type JWTRole = 'ClientAdmin' | 'ClientUser' | 'DesignAdmin' | 'DesignUser' | 'Superadmin';

export const getUserDecodedJwt = (): JWTContent | undefined => {
  try {
    return jwtDecode(localStorage.getItem('token') || '');
  } catch (error) {
    return undefined;
  }
};

export const isLoggedUserAdminOfCurrentBox = () => {
  const decoded = getUserDecodedJwt();
  return !!decoded?.currentBox.admin;
};

const hasUser = (obj: unknown): obj is { userId: string; name: string } =>
  typeof obj === 'object' && obj !== null && 'userId' in obj && 'name' in obj;

export const getUser = () => {
  const decoded = getUserDecodedJwt();
  if (hasUser(decoded)) {
    return decoded;
  }
  throw new Error('no user logged');
};

export const userIsLogged = (): boolean => {
  try {
    return !!getUserDecodedJwt();
  } catch (error) {
    return false;
  }
};

export const jwtNeedRefresh = () => {
  const decoded = getUserDecodedJwt();
  if (decoded) {
    const expirationDate = new Date(decoded.exp * 1000);
    if (differenceInHours(expirationDate, new Date()) <= 1) {
      return true;
    }
  }
  return false;
};

export const getUniqueUserUUID = () => {
  const uuid = localStorage.getItem('userUUID');
  if (uuid) {
    return uuid;
  }
  const newUUID = crypto.randomUUID();
  localStorage.setItem('userUUID', newUUID);
  return newUUID;
};
