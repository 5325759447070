import React from 'react';
import { useTranslation } from 'react-i18next';
import BottomSheet, { BottomSheetProps } from '../../components/BottomSheet';
import { Without } from '../../utils/types';
import { IonIcon, IonLabel, IonNavLink, IonThumbnail } from '@ionic/react';
import {
  StyledAlbumIonItem,
  StyledIonItem,
  StyledAlbumSubTitle,
  StyledAlbumTitle,
} from './AlbumBottomSheetLayout.styled';
import { person, banOutline } from 'ionicons/icons';
import Explicit from '../../components/Explicit';
import Typography from '../../components/Typography';
import PlayLaterIcon from '../../components/Icons/PlayLaterIcon';

export type AlbumBottomSheetLayoutProps = Without<BottomSheetProps, 'onDidDismiss' | 'isOpen'>;

type AlbumBottomSheetProps = {
  album: {
    id: string;
    title: string;
    artist: {
      id: number;
      name: string;
    };
    img: string;
    explicit?: boolean;
  };
  actions?: {
    onSeeArtistButton?: {
      artistId: number;
      callback: () => void;
      artistDrawerComponent: (props: { artistId: number }) => JSX.Element;
    };
    playLater?: () => void;
    blacklistAlbum?: () => void;
  };
};

export const useAlbumBottomSheetLayoutProps = () => {
  const { t } = useTranslation();

  const AlbumBottomSheetLayoutBuilder = (props: AlbumBottomSheetProps) => {
    const artistAction = props.actions?.onSeeArtistButton;
    return {
      header: (
        <StyledAlbumIonItem className={`album-item`} mode="md">
          <IonThumbnail className="album-thumbnail" slot="start">
            <img src={props.album.img} />
          </IonThumbnail>
          <IonLabel>
            <StyledAlbumTitle>
              <Typography tag="h3" className="ion-text-nowrap">
                {props.album.title}
              </Typography>
              {props.album.explicit && <Explicit />}
            </StyledAlbumTitle>
            <StyledAlbumSubTitle>
              <Typography tag="h4" className="ion-text-nowrap">
                {props.album.artist?.name || ''}
              </Typography>
            </StyledAlbumSubTitle>
          </IonLabel>
        </StyledAlbumIonItem>
      ),
      content: (
        <>
          {artistAction && (
            <IonNavLink
              routerDirection="forward"
              component={() => artistAction.artistDrawerComponent({ artistId: props.album.artist.id })}
            >
              <StyledIonItem
                button
                mode="md"
                onClick={() => {
                  artistAction.callback();
                }}
              >
                <div className="menu-item">
                  <IonIcon icon={person} />
                  <IonLabel>{t('seeArtistPage')}</IonLabel>
                </div>
              </StyledIonItem>
            </IonNavLink>
          )}
          {props.actions?.playLater && (
            <StyledIonItem button mode="md" onClick={() => props.actions?.playLater?.()}>
              <div className="menu-item">
                <PlayLaterIcon />
                <IonLabel>{t('playLater')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
          {props.actions?.blacklistAlbum && (
            <StyledIonItem button mode="md" onClick={() => props.actions?.blacklistAlbum?.()}>
              <div className="menu-item">
                <IonIcon icon={banOutline} />
                <IonLabel>{t('blacklistAlbum')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
        </>
      ),
      initialBreakpoint:
        Math.ceil(
          ((148 + Object.values(props.actions || {}).filter((a) => !!a).length * 48) * 100) / window.innerHeight,
        ) / 100,
    };
  };
  return AlbumBottomSheetLayoutBuilder;
};

// used only in storybook :
const AlbumBottomSheetLayout = (
  props: Without<BottomSheetProps, keyof AlbumBottomSheetLayoutProps> & AlbumBottomSheetProps,
) => {
  const AlbumBottomSheetLayoutPropsBuilder = useAlbumBottomSheetLayoutProps();
  const { album, actions, ...bottomSheetProps } = props;
  return (
    <BottomSheet
      {...bottomSheetProps}
      {...AlbumBottomSheetLayoutPropsBuilder({
        album,
        actions,
      })}
    />
  );
};

export default AlbumBottomSheetLayout;
