import React from 'react';
import { IonSkeletonText } from '@ionic/react';
import Typography from '../Typography';
import {
  StyledIonItem,
  StyledIonLabel,
  StyledSoundDesignTitle,
  StyledSoundDesignIonThumbnail,
} from './SoundDesignCard.styled';
import { useTranslation } from 'react-i18next';
import { AmbianceThumbnail } from '../AmbianceCard';

export type SoundDesignCardDefaultProps = {
  soundDesign: {
    id: string;
    name: string;
    ambiances: {
      id: string;
      img: string;
      name: string;
      color: string;
      soundDesignName: string;
    }[];
    ambiancesCount: number;
  };
  onClick?: () => void;
  loading?: boolean;
  hideText?: boolean;
};

type SoundDesignCardLoading = { loading: true; hideText?: boolean };

export type SoundDesignCardProps = SoundDesignCardDefaultProps | SoundDesignCardLoading;

export const SoundDesignThumbnail = (
  props:
    | {
        loading?: boolean;
        ambiances: {
          id: string;
          img: string;
          name: string;
          color: string;
          soundDesignName: string;
        }[];
      }
    | { loading: true },
) => {
  return props.loading ? (
    <StyledSoundDesignIonThumbnail className="soundDesign-thumbnail loading">
      <IonSkeletonText animated={true} />
    </StyledSoundDesignIonThumbnail>
  ) : (
    <StyledSoundDesignIonThumbnail className="soundDesign-thumbnail">
      {props.ambiances.slice(0, 4).map((ambiance) => (
        <AmbianceThumbnail ambiance={ambiance} key={`ambiance-thumb-${ambiance.id}`} />
      ))}
    </StyledSoundDesignIonThumbnail>
  );
};

const SoundDesignCard = (props: SoundDesignCardProps) => {
  const { t } = useTranslation();
  return props.loading ? (
    <StyledIonItem mode="md">
      <SoundDesignThumbnail loading />
      {!props.hideText && (
        <StyledIonLabel>
          <StyledSoundDesignTitle>
            <Typography tag="h3" className="ion-text-nowrap" loading style={{ width: 150 }} />
          </StyledSoundDesignTitle>
        </StyledIonLabel>
      )}
    </StyledIonItem>
  ) : (
    <StyledIonItem button={!!props.onClick} onClick={props.onClick} mode="md">
      <SoundDesignThumbnail ambiances={props.soundDesign.ambiances.slice(0, 4)} />
      {!props.hideText && (
        <StyledIonLabel>
          <StyledSoundDesignTitle>
            <Typography tag="h3" className="ion-text-nowrap">
              {`${props.soundDesign.ambiancesCount} ${t('ambiances', { count: props.soundDesign.ambiancesCount })}`}
            </Typography>
          </StyledSoundDesignTitle>
        </StyledIonLabel>
      )}
    </StyledIonItem>
  );
};

export default SoundDesignCard;
