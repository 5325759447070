import { configureStore } from '@reduxjs/toolkit';
import { api, legacyApi } from '../services/api.service';
import { auth } from '../services/auth.service';
import { cdn } from '../services/cdn.service';
import { qobuz } from '../services/qobuz.service';
import { setupListeners } from '@reduxjs/toolkit/query';
import combinedReducers from './rootReducer';
import { jwtTokenRefresher } from './jwtMiddleware';

const store = configureStore({
  reducer: combinedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      legacyApi.middleware,
      auth.middleware,
      qobuz.middleware,
      cdn.middleware,
      jwtTokenRefresher,
    ),
});

setupListeners(store.dispatch);

export default store;
