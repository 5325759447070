import React from 'react';
import { IonIcon, IonRippleEffect } from '@ionic/react';
import { StyledIonButton, StyledIonBadge, VoteButtonContainer } from './VoteButton.styled';
import { heartOutline, heart } from 'ionicons/icons';

export type VoteButtonProps = {
  onClick?: React.MouseEventHandler<HTMLIonButtonElement>;
  voted?: boolean;
  votes?: number;
  readonly?: boolean;
  iconOnly?: boolean;
};

const IconVoteButton = (props: VoteButtonProps) => {
  return (
    <VoteButtonContainer>
      <div>
        <IonIcon
          slot="icon-only"
          icon={props.voted ? heart : heartOutline}
          color={props.readonly ? 'primary' : 'danger'}
        />
        {!!props.votes && props.votes > 0 && (
          <StyledIonBadge color={props.readonly ? 'primary' : 'danger'}>
            {props.votes > 99 ? `${99}+` : props.votes}
          </StyledIonBadge>
        )}
      </div>
    </VoteButtonContainer>
  );
};

const VoteButton = (props: VoteButtonProps) => {
  return props.iconOnly ? (
    <IconVoteButton {...props} />
  ) : (
    <VoteButtonContainer>
      <div>
        <StyledIonButton
          fill="clear"
          className="vote-button"
          shape={'round'}
          onClick={props.readonly ? undefined : props.onClick}
          disabled={props.readonly}
        >
          <IonRippleEffect />
          <div>
            <IonIcon
              slot="icon-only"
              icon={props.voted ? heart : heartOutline}
              color={props.readonly ? 'primary' : 'danger'}
            />
          </div>
        </StyledIonButton>
        {!!props.votes && props.votes > 0 && (
          <StyledIonBadge color={props.readonly ? 'primary' : 'danger'}>
            {props.votes > 99 ? `${99}+` : props.votes}
          </StyledIonBadge>
        )}
      </div>
    </VoteButtonContainer>
  );
};

export default VoteButton;
