import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Milliseconds } from '../utils/types';
/* eslint-disable @typescript-eslint/no-unused-vars */

type ToastState = {
  toast?: {
    isOpen: boolean;
    duration?: Milliseconds;
    message?: string;
    header?: string;
    type?: 'error';
    style?: React.CSSProperties;
  };
};

const initialToastSlice: ToastState = {
  toast: undefined,
};

const ToastSlice = createSlice({
  name: 'ToastSlice',
  initialState: initialToastSlice,
  reducers: {
    setToast(state: ToastState, action: PayloadAction<ToastState['toast']>) {
      state.toast = action.payload;
    },
  },
});

const ToastReducer = ToastSlice.reducer;

export const { setToast } = ToastSlice.actions;
export default ToastReducer;
