import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable @typescript-eslint/no-unused-vars */

export type BoxDetail = {
  _id: string;
  name: string;
  domain: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: {
    jukeboxrestricted?: boolean;
    jukebox?: boolean;
    nologin?: boolean;
    guest?: boolean;
    shutdown?: boolean;
  };
  socket: {
    host: string;
    path: string;
  };
  email: string;
  player: boolean;
};

type AppState = {
  isAdminMode: boolean;
  isSwitchingBox: boolean;
  boxDetail: BoxDetail | null;
  username?: string;
};

const initialPlayerSlice: AppState = {
  isAdminMode: true,
  isSwitchingBox: false,
  boxDetail: null,
};

const PlayerSlice = createSlice({
  name: 'PlayerSlice',
  initialState: initialPlayerSlice,
  reducers: {
    setIsSwitchingBox(state: AppState, action: PayloadAction<boolean>) {
      localStorage.removeItem('lastStreamId');
      state.isSwitchingBox = action.payload;
    },
    setIsAdminMode(state: AppState, action: PayloadAction<boolean>) {
      state.isAdminMode = action.payload;
    },
    setBoxDetail(state: AppState, action: PayloadAction<BoxDetail>) {
      state.boxDetail = action.payload;
    },
    setUsername(state: AppState, action: PayloadAction<string | undefined>) {
      state.username = action.payload;
    },
    resetAppState(state: AppState) {
      return initialPlayerSlice;
    },
  },
});

const PlayerReducer = PlayerSlice.reducer;

export const { resetAppState, setBoxDetail, setIsAdminMode, setIsSwitchingBox, setUsername } = PlayerSlice.actions;
export default PlayerReducer;
