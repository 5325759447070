import React from 'react';
import { IonContent, IonNavLink } from '@ionic/react';
import {
  StyledPageContent,
  StyledHeaderContainer,
  StyledAlbumTitleContainer,
  StyledLeftHeader,
  StyledIonHeader,
  StyledRightHeader,
} from './AlbumLayout.styled';
import { TrackItemDefaultProps } from '../../../components/TrackItem';
import Typography from '../../../components/Typography';
import IconButton from '../../../components/IconButton';
import { chevronBack, ellipsisVertical } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { secondsToTimeString } from '../../../utils/time';
import PlayButton from '../../../components/PlayButton';
import TrackList from '../../../components/TrackList';

type Album = {
  id: string;
  img: string;
  explicit?: boolean;
  title: string;
  artist: {
    id: number;
    name: string;
  };
  released_at: number;
  duration: number;
  tracks: TrackItemDefaultProps['track'][];
  blacklisted?: boolean;
};

export type AlbumLayoutDefaultProps = {
  loading?: boolean;
  album: Album;
  onClickTrack: (track: TrackItemDefaultProps['track']) => void;
  onPlayClick?: (album: Album) => void;
  onClickTrackVote?: (track: TrackItemDefaultProps['track']) => void;
  onMoreClick?: (album: Album) => void;
  adminMode?: boolean;
};

type AlbumLayoutLoadingProps = {
  loading: true;
  onPlayClick?: (album: Album) => void;
  onMoreClick?: (album: Album) => void;
};

export type AlbumLayoutProps = AlbumLayoutDefaultProps | AlbumLayoutLoadingProps;

const AlbumLayout = (props: AlbumLayoutProps) => {
  const { t } = useTranslation();
  return (
    <>
      <IonContent>
        <StyledPageContent>
          <StyledHeaderContainer>
            <StyledIonHeader>
              <StyledLeftHeader>
                <IonNavLink routerDirection="back">
                  <IconButton icon={chevronBack} size="large" />
                </IonNavLink>
                <StyledAlbumTitleContainer>
                  {props.loading ? (
                    <Typography tag="h2" loading />
                  ) : (
                    <Typography tag="h2">{props.album.title}</Typography>
                  )}
                  {props.loading ? (
                    <Typography tag="h5" variant="secondary" loading />
                  ) : (
                    <Typography tag="h5" variant="secondary">
                      {props.album.artist.name}
                    </Typography>
                  )}
                  {props.loading ? (
                    <Typography tag="h5" variant="secondary" loading />
                  ) : (
                    <Typography tag="h5" variant="secondary">
                      {`${format(props.album.released_at * 1000, 'dd/MM/yyyy')} • ${props.album.tracks.length} ${t(
                        'tracks',
                      )} • ${secondsToTimeString(props.album.duration)}`}
                    </Typography>
                  )}
                </StyledAlbumTitleContainer>
              </StyledLeftHeader>
              <StyledRightHeader>
                {props.onPlayClick && !props.loading && <PlayButton onClick={() => props.onPlayClick?.(props.album)} />}
                {props.onMoreClick && !props.loading && (
                  <IconButton icon={ellipsisVertical} onClick={() => props.onMoreClick?.(props.album)} />
                )}
              </StyledRightHeader>
            </StyledIonHeader>
          </StyledHeaderContainer>
          <IonContent>
            <TrackList
              tracks={
                props.loading
                  ? Array.from({ length: 30 }).map(() => ({ loading: true }))
                  : props.album.tracks.map((tr) => ({
                      track: { ...tr, blacklisted: tr.blacklisted || props.album.blacklisted },
                      onVoteClick: () => {
                        props.onClickTrackVote?.(tr);
                      },
                      onClick: () => {
                        props.onClickTrack(tr);
                      },
                    }))
              }
            />
          </IonContent>
        </StyledPageContent>
      </IonContent>
    </>
  );
};

export default AlbumLayout;
