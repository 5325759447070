import { IonAvatar, IonImg } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonAvatar = styled(IonAvatar)`
  color: var(--text-color-primary);
  aspect-ratio: 1 / 1;
  width: 100%;
  height: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  position: relative;

  & ion-icon {
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

export const StyledIonImg = styled(IonImg)`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
`;
