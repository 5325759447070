import React from 'react';
import { IonSkeletonText, IonThumbnail } from '@ionic/react';
import Explicit from '../Explicit';
import Typography from '../Typography';
import { StyledIonItem, StyledIonImg, StyledIonLabel, StyledAlbumTitle, StyledAlbumArtist } from './AlbumCard.styled';

export type AlbumCardDefaultProps = {
  album: {
    id: string;
    img: string;
    explicit?: boolean;
    title: string;
    blacklisted?: boolean;
    streamable: boolean;
    artist: {
      id: number;
      name: string;
    };
  };
  onClick?: () => void;
  loading?: boolean;
  hideText?: boolean;
};

type AlbumCardLoading = { loading: true; hideText?: boolean };

export type AlbumCardProps = AlbumCardDefaultProps | AlbumCardLoading;

const AlbumCard = (props: AlbumCardProps) => {
  return props.loading ? (
    <StyledIonItem mode="md">
      <IonThumbnail className="album-thumbnail">
        <IonSkeletonText animated={true} style={{ borderRadius: 4 }} />
      </IonThumbnail>
      {!props.hideText && (
        <StyledIonLabel>
          <StyledAlbumTitle>
            <Typography tag="h3" className="ion-text-nowrap" loading style={{ width: 150 }} />
          </StyledAlbumTitle>
          <StyledAlbumArtist>
            <Typography tag="h4" className="ion-text-nowrap" loading style={{ width: 100 }} variant="secondary" />
          </StyledAlbumArtist>
        </StyledIonLabel>
      )}
    </StyledIonItem>
  ) : (
    <StyledIonItem
      button={!!props.onClick}
      onClick={props.onClick}
      mode="md"
      className={`${props.album.blacklisted || !props.album.streamable ? ' is-blacklisted' : ''}`}
    >
      <IonThumbnail className="album-thumbnail">
        <StyledIonImg src={props.album.img} />
      </IonThumbnail>
      {!props.hideText && (
        <StyledIonLabel>
          <StyledAlbumTitle>
            <Typography tag="h3" className="ion-text-nowrap">
              {props.album.title}
            </Typography>
            {props.album.explicit && <Explicit />}
          </StyledAlbumTitle>
          <StyledAlbumArtist>
            <Typography tag="h4" className="ion-text-nowrap" variant="secondary">
              {props.album.artist.name}
            </Typography>
          </StyledAlbumArtist>
        </StyledIonLabel>
      )}
    </StyledIonItem>
  );
};

export default AlbumCard;
