import React, { useRef } from 'react';
import { StyledIonModal, StyledIonContent, StyledIonHeader } from './BottomSheet.styled';
import IconButton from '../../components/IconButton';
import { chevronDown } from 'ionicons/icons';
import { IonModal } from '@ionic/react';

type IonModalProps = React.ComponentProps<typeof IonModal>;

export type BottomSheetProps = {
  header?: React.ReactNode;
  content?: React.ReactNode;
  initialBreakpoint?: number;
  isOpen?: boolean;
  onDidDismiss?: IonModalProps['onDidDismiss'];
};

const BottomSheet = (props: BottomSheetProps) => {
  const modalRef = useRef<HTMLIonModalElement>(null);

  return (
    <StyledIonModal
      isOpen={props.isOpen}
      onDidDismiss={(e) => {
        props.onDidDismiss?.(e);
      }}
      ref={modalRef}
      initialBreakpoint={props.initialBreakpoint || 1}
      breakpoints={[0, props.initialBreakpoint || 1]}
      onIonBreakpointDidChange={(e) => {
        if (modalRef.current && e.detail.breakpoint > 0) {
          modalRef.current.setCurrentBreakpoint(props.initialBreakpoint || 1);
        }
      }}
    >
      <StyledIonHeader className="ion-padding">
        <div style={{ width: '100%' }}>{props.header}</div>
        <div style={{ minWidth: 48 }}>
          <IconButton icon={chevronDown} size="large" onClick={() => modalRef?.current?.dismiss()} />
        </div>
      </StyledIonHeader>
      <StyledIonContent className="ion-padding" scrollY={false}>
        <div className="bottom-sheet-content">{props.content}</div>
      </StyledIonContent>
    </StyledIonModal>
  );
};

export default BottomSheet;
