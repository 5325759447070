import { QobuzTrackDto } from '../services/qobuz.service';

export const trackDtoFromQobuzDto = (qobuzTrack: QobuzTrackDto) => {
  return {
    title: qobuzTrack.title,
    trackId: qobuzTrack.id,
    artist: qobuzTrack.performer?.name,
    genre: qobuzTrack.album.genre.name,
    album: qobuzTrack.album.title,
    img: qobuzTrack.album.image.thumbnail,
    duration: qobuzTrack.duration * 1000,
    isrc: qobuzTrack.isrc,
    downloadable: qobuzTrack.downloadable,
    streamable: qobuzTrack.streamable,
  };
};
