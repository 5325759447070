import React from 'react';
import { IonContent, IonHeader, IonNavLink } from '@ionic/react';
import {
  StyledIonContent,
  StyledPageContent,
  StyledHeaderContainer,
  StyledIndependentPlaylistsTitleContainer,
} from './IndependentPlaylistsLayout.styled';
import Typography from '../../../components/Typography';
import IconButton from '../../../components/IconButton';
import { chevronBack } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import PlaylistItem, { PlaylistItemDefaultProps } from '../../../components/PlaylistItem';
import InfiniteGrid from '../../../components/InfiniteGrid';

export type IndependentPlaylistsLayoutDefaultProps = {
  loading?: boolean;
  soundDesign: {
    id: string;
    name: string;
  };
  playlistsCount: number;
  playlists: PlaylistItemDefaultProps['playlist'][];
  isPlaylistsFetching?: boolean;
  loadDataPlaylists?: () => void;
  onPlaylistClickDrawerComponent?: (props: { playlistId: string }) => JSX.Element;
};

type IndependentPlaylistsLayoutLoadingProps = {
  loading: true;
};

export type IndependentPlaylistsLayoutProps =
  | IndependentPlaylistsLayoutDefaultProps
  | IndependentPlaylistsLayoutLoadingProps;

const IndependentPlaylistsLayout = (props: IndependentPlaylistsLayoutProps) => {
  const { t } = useTranslation();
  const onPlaylistClickDrawerComponent =
    'onPlaylistClickDrawerComponent' in props && props.onPlaylistClickDrawerComponent;
  return (
    <StyledIonContent>
      <StyledPageContent>
        <StyledHeaderContainer>
          <IonHeader>
            <IonNavLink routerDirection="back">
              <IconButton icon={chevronBack} size="large" />
            </IonNavLink>
            <StyledIndependentPlaylistsTitleContainer>
              {props.loading ? (
                <Typography tag="h2" loading />
              ) : (
                <Typography tag="h2">{t('independentPlaylists')}</Typography>
              )}
              {props.loading ? (
                <Typography tag="h5" variant="secondary" loading />
              ) : (
                <Typography tag="h5" variant="secondary">
                  {`${props.playlistsCount} ${t('playlists', { count: props.playlistsCount })} • ${
                    props.soundDesign.name
                  }`}
                </Typography>
              )}
            </StyledIndependentPlaylistsTitleContainer>
          </IonHeader>
        </StyledHeaderContainer>
        <IonContent>
          <InfiniteGrid
            loadingElement={<PlaylistItem loading />}
            loading={props.loading || props.isPlaylistsFetching}
            onScroll={props.loading ? undefined : props.loadDataPlaylists}
          >
            {props.loading
              ? undefined
              : props.playlists.map((playlist, index) =>
                  onPlaylistClickDrawerComponent ? (
                    <IonNavLink
                      style={{ width: '100%' }}
                      key={`playlist-${playlist.id}-${index}`}
                      routerDirection="forward"
                      component={() => onPlaylistClickDrawerComponent({ playlistId: playlist.id })}
                    >
                      <PlaylistItem playlist={playlist} onClick={() => {}} />
                    </IonNavLink>
                  ) : (
                    <PlaylistItem playlist={playlist} onClick={() => {}} />
                  ),
                )}
          </InfiniteGrid>
        </IonContent>
      </StyledPageContent>
    </StyledIonContent>
  );
};

export default IndependentPlaylistsLayout;
