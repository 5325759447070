import { IonSearchbar, IonSkeletonText } from '@ionic/react';
import { search } from 'ionicons/icons';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledIonSearchbar } from './SearchBar.styled';

type IonSearchbarProps = React.ComponentProps<typeof IonSearchbar>;
export type SearchBarProps = IonSearchbarProps & {
  forceOpen?: boolean;
  loading?: boolean;
};

const SearchBar = forwardRef<HTMLIonSearchbarElement, SearchBarProps>(({ ...props }, ref) => {
  const { t } = useTranslation();

  return props.loading ? (
    <IonSkeletonText animated={true} style={{ height: 48, width: props.forceOpen ? '' : 48, marginTop: 0 }} />
  ) : (
    <StyledIonSearchbar
      placeholder={t('search')}
      searchIcon={search}
      className={props.forceOpen ? 'open' : ''}
      {...props}
      ref={ref}
    />
  );
});

export default SearchBar;
