import React from 'react';
import styled from 'styled-components';
import { IonLabel } from '@ionic/react';

const marqueeAnim = (name: string, widths: { scrollWidth: number; clientWidth: number }) => {
  return `
      @keyframes marquee-playerContent-${name}-${Date.now()} {
        0% {
          transform: translate(0, 0);
        }
        10% {
          transform: translate(0, 0);
        }
        90% {
          transform: translate(-${
            widths.scrollWidth - widths.clientWidth > 0 ? widths.scrollWidth - widths.clientWidth : 0
          }px, 0);
        }
        100% {
          transform: translate(-${
            widths.scrollWidth - widths.clientWidth > 0 ? widths.scrollWidth - widths.clientWidth : 0
          }px, 0);
        }
      }
      animation: marquee-playerContent-${name}-${Date.now()} ${widths.scrollWidth * 0.025}s linear infinite alternate;
      &:hover {
        animation-play-state: paused;
      }`;
};

export const StyledPageContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: calc(var(--ion-safe-area-top) - 16px);
  margin-top: 16px;

  &.toasted {
    padding-bottom: calc(50px + var(--padding-bottom));
  }

  & .prog-name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;

    & h6 {
      margin: 0;
    }
  }

  & .music-controls {
    display: flex;
    justify-content: space-between;
    height: 56px;
  }

  & .progress-bar-container {
    position: relative;
    height: 30px;
    margin-top: 48px;

    ion-progress-bar {
      --inner-padding-start: 18px;
      --inner-padding-end: 18px;
      top: 0px;
    }
  }

  & .controls {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    & .cover {
      flex: 1;
      display: flex;

      & .current {
        flex: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        &.spot {
          width: 100%;
          justify-content: center;
          display: flex;
          align-items: center;
          color: var(--text-color-secondary);

          & ion-icon {
            width: 50%;
            height: 50%;
          }
        }
      }
    }
  }
`;

export const StyledButtonContainer = styled.div`
  margin-left: 0px;

  &.active {
    & .button-content {
      color: var(--ion-color-primary);
    }
  }

  & .button-content {
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
    color: var(--text-color-secondary);

    & ion-icon {
      width: 28px;
      height: 28px;
      margin-top: -2px;
    }

    & ion-text {
      font-size: 12px;
      width: 80px;
      white-space: initial;
      text-align: left;
      padding-left: 8px;
      font-weight: 500;
      color: currentColor;
    }
  }
`;

export const StyledCurrentTitle = styled((props) => {
  const { widths, ...rest } = props;
  return <div {...rest} />;
})`
  display: flex;
  align-items: center;
  justify-content: center;

  & .title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--text-color-primary);

    &.overflown div {
      ${(props) => marqueeAnim('title', props.widths)}
    }
  }
`;

export const StyledCurrentArtist = styled((props) => {
  const { widths, ...rest } = props;
  return <div {...rest} />;
})`
  display: flex;
  align-items: center;
  justify-content: center;

  & .artist {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    color: var(--text-color-secondary);

    &.overflown div {
      ${(props) => marqueeAnim('artist', props.widths)}
    }
  }
`;

export const StyledIonLabel = styled(IonLabel)`
  --inner-padding-end: 16px;
  max-width: calc(100% - var(--ion-safe-area-right, 0px) - var(--inner-padding-end));
  margin: 0;
  margin-bottom: 8px;
`;
