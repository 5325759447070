import React from 'react';
import { IonContent } from '@ionic/react';
import InfiniteGrid from '../../../../components/InfiniteGrid';
import PlaylistCard, { PlaylistCardDefaultProps } from '../../../../components/PlaylistCard';
import PlaylistDrawer from '../../../../features/Drawer/PlaylistDrawer';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import { StyledIonNavLink } from '../../../../features/Drawer/Drawer.styled';

const SearchTabPlaylists = (props: {
  loading?: boolean;
  onLoadData?: () => void;
  playlists: PlaylistCardDefaultProps['playlist'][];
}) => {
  const ambianceId = undefined; //todo find ambianceId with a playlistId
  return (
    <IonContent scrollEvents onIonScrollStart={() => Capacitor.getPlatform() !== 'web' && Keyboard.hide()}>
      <InfiniteGrid
        loadingElement={<PlaylistCard loading />}
        loading={props.loading}
        onScroll={props.onLoadData}
        elementWidth={`calc(100% / ${Math.floor(window.innerWidth / (320 / 2))})`}
      >
        {props.playlists.map((playlist, index) => (
          <StyledIonNavLink
            key={`playlist-${playlist.id}-${index}`}
            routerDirection="forward"
            component={() => (
              <PlaylistDrawer
                playlistId={playlist.id}
                ambianceColor={playlist.ambiance?.color}
                ambianceId={ambianceId}
                independentPlaylist={playlist.independent}
              />
            )}
          >
            <PlaylistCard playlist={playlist} onClick={() => {}} />
          </StyledIonNavLink>
        ))}
      </InfiniteGrid>
    </IonContent>
  );
};

export default SearchTabPlaylists;
