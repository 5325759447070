import React from 'react';
import { IonProgressBar } from '@ionic/react';

type IonProgressBarProps = React.ComponentProps<typeof IonProgressBar>;

export type ProgressBarProps = IonProgressBarProps;

export const ProgressBar = (props: ProgressBarProps): JSX.Element => {
  return <IonProgressBar color="primary" style={{ height: 2 }} {...props} />;
};

export default ProgressBar;
