import React from 'react';
import Typography from '../Typography';
import { StyledToast } from './Toast.styled';

export type ToastProps = {
  isOpen: boolean;
  header?: React.ReactNode;
  message?: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties | undefined;
};

const Toast = (props: ToastProps) => {
  const { header, message, onClick, style } = props;

  return (
    <StyledToast
      style={style}
      className={`${onClick ? 'clickable' : ''} ${props.isOpen ? 'open' : ''}`}
      onClick={onClick}
    >
      {header && (
        <Typography className="header" tag="h4">
          {header}
        </Typography>
      )}
      {message && (
        <Typography className="message" tag="h5" variant="secondary">
          {message}
        </Typography>
      )}
    </StyledToast>
  );
};

export default Toast;
