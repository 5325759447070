import { IonItem } from '@ionic/react';
import styled from 'styled-components';

export const ButtonContainer = styled(IonItem)`
  --background: none;
  --border-color: transparent;
  --padding-start: 16px;
  --inner-padding-end: 16px;

  position: relative;
  display: inline-block;
  color: var(--text-color-secondary) !important;

  &::part(detail-icon) {
    display: none;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;

  & h4 {
    margin: 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;
