import useConnectionLostGuard from './useConnectionLostGuard';
import { useDispatch } from 'react-redux';
import { setBackToProgModaleIsOpen } from '../../store/modaleSlice';

const useBackToProgConfirm = () => {
  const dispatch = useDispatch();
  const connectionLostGuard = useConnectionLostGuard();
  const backToProgConfirm = () => {
    connectionLostGuard(() => {
      dispatch(setBackToProgModaleIsOpen(true));
    });
  };
  return backToProgConfirm;
};

export default useBackToProgConfirm;
