import styled from 'styled-components';
import { IonRange } from '@ionic/react';
import Typography from '../../components/Typography';
import IconButton from '../../components/IconButton';

export const StyledVolumeHandlerContainer = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  padding: 24px 0px;

  & .volume-text {
    width: 28px;
    margin-left: -8px;
  }

  & .volume-mute-button {
    margin-left: -12px;
  }
`;

export const StyledIonRange = styled(IonRange)`
  padding-left: 16px;
  padding-right: 16px;
  --bar-background: rgba(var(--text-color-rgb), 0.38);
  --bar-background-active: var(--text-color-secondary);

  &::part(knob) {
    background: white;
    background-image: linear-gradient(to right, var(--text-color-secondary), var(--text-color-secondary));
  }

  &::part(tick) {
    background: transparent;
  }

  &.range-active {
    --bar-background: rgba(var(--ion-color-primary-rgb), 0.38);
    --bar-background-active: var(--ion-color-primary);

    &::part(knob) {
      background: var(--ion-color-primary);
    }
  }
`;

export const StyledTypography = styled(Typography)`
  &.range-active {
    color: var(--ion-color-primary);
  }
`;

export const StyledIconButton = styled(IconButton)`
  &.range-active {
    color: var(--ion-color-primary);
  }
`;
