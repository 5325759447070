import { AlbumLayoutDefaultProps } from '../../layouts/Search/AlbumLayout';
import { ArtistLayoutDefaultProps } from '../../layouts/Search/ArtistLayout';
import {
  AggregatedBlacklistDto,
  AmbianceDtoWithSoundDesignName,
  PlaylistDto,
  PlaylistItemDto,
  SoundDesignDto,
  SpotDto,
} from '../../services/api.service';
import { isMemoizedAlbumBlacklisted, isMemoizedArtistBlacklisted, isMemoizedTrackBlacklisted } from './utils';
import {
  QobuzAlbumDto,
  ArtistDetailedDto,
  QobuzTrackDto,
  ArtistAlbumsDto,
  QobuzTopTrackDto,
  AlbumDetailedDto,
  QobuzArtistAlbumDto,
} from '../../services/qobuz.service';
import { PlaylistItemDefaultProps } from '../../components/PlaylistItem';
import { IndependentPlaylistsCardDefaultProps } from '../../components/IndependentPlaylistsCard';
import { SoundDesignCardDefaultProps } from '../../components/SoundDesignCard';
import { AmbianceCardDefaultProps } from '../../components/AmbianceCard';
import { AlbumCardDefaultProps } from '../../components/AlbumCard';
import { TrackItemDefaultProps } from '../../components/TrackItem';
import { ArtistCardDefaultProps } from '../../components/ArtistCard';
import { SpotCardDefaultProps } from '../../components/SpotCard';

export const buildAlbumCardFromQobuzArtistAlbumDto = (
  albumDto: QobuzArtistAlbumDto,
  blacklists: AggregatedBlacklistDto | undefined,
): AlbumCardDefaultProps['album'] => ({
  artist: { id: albumDto.artist.id, name: albumDto.artist.name.display },
  id: albumDto.id,
  img: albumDto.image.large,
  title: albumDto.title,
  blacklisted: isMemoizedAlbumBlacklisted(blacklists, albumDto),
  explicit: albumDto.parental_warning,
  streamable: albumDto.rights.streamable,
});

export const buildTrackItemFromTrackDto = (
  trackDto: QobuzTrackDto | QobuzTopTrackDto,
  extendedData: { blacklists: AggregatedBlacklistDto | undefined; userVotes: number[] },
): TrackItemDefaultProps['track'] => {
  return {
    artist:
      'performer' in trackDto && trackDto.performer
        ? {
            id: trackDto.performer.id,
            name: trackDto.performer.name,
          }
        : 'artist' in trackDto
        ? {
            id: trackDto.artist.id,
            name: trackDto.artist.name.display,
          }
        : {
            id: trackDto.album.artist.id,
            name: trackDto.album.artist.name,
          },
    id: trackDto.id,
    img: trackDto.album.image.large,
    title: `${trackDto.work ? `${trackDto.work} ` : ''}${trackDto.title}${
      trackDto.version ? ` (${trackDto.version})` : ''
    }`,
    explicit: trackDto.parental_warning,
    voted: extendedData.userVotes.includes(trackDto.id),
    streamable: 'streamable' in trackDto ? trackDto.streamable : trackDto.rights.streamable,
    ...(extendedData.blacklists ? { blacklisted: isMemoizedTrackBlacklisted(extendedData.blacklists, trackDto) } : {}),
  };
};
export const buildArtistCardFromArtistDto = (
  artistDto: { id: number; image: { medium: string } | null; name: string },
  blacklists: AggregatedBlacklistDto | undefined,
): ArtistCardDefaultProps['artist'] => {
  return {
    id: artistDto.id,
    name: artistDto.name,
    img: artistDto.image?.medium,
    ...(blacklists ? { blacklisted: isMemoizedArtistBlacklisted(blacklists, artistDto) } : {}),
  };
};
export const buildAlbumCardItemFromAlbumDto = (
  albumDto: QobuzAlbumDto,
  blacklists: AggregatedBlacklistDto | undefined,
): AlbumCardDefaultProps['album'] => {
  return {
    id: albumDto.id,
    title: albumDto.title,
    img: albumDto.image.large,
    explicit: albumDto.parental_warning,
    artist: {
      id: albumDto.artist.id,
      name: albumDto.artist.name,
    },
    ...(blacklists ? { blacklisted: isMemoizedAlbumBlacklisted(blacklists, albumDto) } : {}),
    streamable: albumDto.streamable,
  };
};
export const buildAlbumDetailedFromAlbumDetailedDto = (
  albumDetailedDto: AlbumDetailedDto,
  blacklists: AggregatedBlacklistDto | undefined,
  userVotes: number[],
): AlbumLayoutDefaultProps['album'] => {
  const albumDetailWithoutTracks = {
    id: albumDetailedDto.id,
    img: albumDetailedDto.image.large,
    explicit: albumDetailedDto.parental_warning,
    title: albumDetailedDto.title,
    artist: {
      id: albumDetailedDto.artist.id,
      name: albumDetailedDto.artist.name,
    },
    released_at: albumDetailedDto.released_at,
    duration: albumDetailedDto.duration,
  };
  return {
    ...albumDetailWithoutTracks,
    blacklisted: isMemoizedAlbumBlacklisted(blacklists, albumDetailedDto),
    tracks: albumDetailedDto.tracks.items
      .map((trackDtoWithoutAlbum) => ({
        ...trackDtoWithoutAlbum,
        album: albumDetailedDto,
      }))
      .map((trackDto) => buildTrackItemFromTrackDto(trackDto, { blacklists, userVotes })),
  };
};

export const buildArtistDetailedFromDto = (
  artistDetailedWithAlbumsDto: ArtistAlbumsDto,
  artistDetailed: ArtistDetailedDto,
  blacklists: AggregatedBlacklistDto | undefined,
  userVotes: number[],
): ArtistLayoutDefaultProps['artist'] | undefined => {
  return {
    id: artistDetailed.id,
    name: artistDetailed.name.display,
    img: artistDetailed.images.portrait
      ? `https://static.qobuz.com/images/artists/covers/medium/${artistDetailed.images.portrait.hash}.${artistDetailed.images.portrait.format}`
      : undefined,
    bio: artistDetailed.biography?.content,
    tracks: artistDetailed.top_tracks.map((topTrackDto) =>
      buildTrackItemFromTrackDto(topTrackDto, { blacklists, userVotes }),
    ),
    albums: artistDetailedWithAlbumsDto.items.map((albumDto) => ({
      artist: { id: albumDto.artist.id, name: albumDto.artist.name.display },
      id: albumDto.id,
      img: albumDto.image.large,
      title: albumDto.title,
      blacklisted: isMemoizedAlbumBlacklisted(blacklists, albumDto),
      streamable: albumDto.rights.streamable,
      explicit: albumDto.parental_warning,
    })),
    similarArtists: artistDetailed.similar_artists.items.map((artistDto) =>
      buildArtistCardFromArtistDto(
        {
          id: artistDto.id,
          name: artistDto.name.display,
          image: artistDto.images.portrait
            ? {
                medium: `https://static.qobuz.com/images/artists/covers/medium/${artistDto.images.portrait.hash}.${artistDto.images.portrait.format}`,
              }
            : null,
        },
        blacklists,
      ),
    ),
  };
};

export const buildPlaylistCardItemFromPlaylistDto = (playlistDto: PlaylistItemDto) => {
  return {
    duration: playlistDto.playlistItem.duration,
    id: playlistDto.playlistItem.id,
    title: playlistDto.playlistItem.name,
    tracks: (playlistDto.playlistItem.musicsImagesSummary || []).map((img) => ({ img })),
    ambiance: playlistDto.playlistItem.ambiance,
    independent: playlistDto.playlistItem.independent,
    shared: playlistDto.playlistItem.shared,
  };
};

export const buildAmbianceCardItemFromAmbianceDto = (
  ambianceDtoWithSoundDesignName: AmbianceDtoWithSoundDesignName,
): AmbianceCardDefaultProps['ambiance'] => {
  return {
    id: ambianceDtoWithSoundDesignName.id,
    name: ambianceDtoWithSoundDesignName.name,
    color: ambianceDtoWithSoundDesignName.color,
    img: ambianceDtoWithSoundDesignName.image,
    soundDesignName: ambianceDtoWithSoundDesignName.soundDesignName,
  };
};

export const buildSpotCardItemFromSpotDto = (spotDto: SpotDto): SpotCardDefaultProps['spot'] => {
  return {
    id: spotDto.id,
    title: spotDto.name,
    duration: spotDto.duration,
    img: spotDto.image,
  };
};

export const buildPlaylistItemFromPlaylistDto = (playlistDto: PlaylistDto): PlaylistItemDefaultProps['playlist'] => {
  return {
    duration: playlistDto.duration / 1000,
    id: playlistDto.id,
    title: playlistDto.name,
    tracks: playlistDto.musicsImagesSummary.map((img) => ({ img: img || '' })),
    ambiance: playlistDto.ambiance,
    independent: playlistDto.independent,
  };
};

export const buildSoundDesignCardFromSoundDesignDto = (
  soundDesignDto: SoundDesignDto & {
    independentPlaylists: PlaylistDto[];
  },
): SoundDesignCardDefaultProps['soundDesign'] & {
  independentPlaylists: IndependentPlaylistsCardDefaultProps['playlists'];
} => {
  return {
    id: soundDesignDto.id,
    name: soundDesignDto.name,
    ambiances: soundDesignDto.ambiances.map((ambiance) =>
      buildAmbianceCardItemFromAmbianceDto({ ...ambiance, soundDesignName: soundDesignDto.name }),
    ),
    ambiancesCount: soundDesignDto.ambiances.length,
    independentPlaylists: soundDesignDto.independentPlaylists.map((playlist) =>
      buildPlaylistItemFromPlaylistDto(playlist),
    ),
  };
};
