import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AlertModal from '../../../components/AlertModal';
import { setInfoSoundLibraryUnavailableModaleIsOpen } from '../../../store/modaleSlice';
import { RootState } from '../../../store/rootReducer';

const InfoSoundLibraryUnavailable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const infoSoundLibraryUnavailableIsOpen = useSelector(
    (state: RootState) => state.ModaleReducer.infoSoundLibraryUnavailableIsOpen,
  );
  return (
    <AlertModal
      isOpen={infoSoundLibraryUnavailableIsOpen}
      onDidDismiss={() => {
        dispatch(setInfoSoundLibraryUnavailableModaleIsOpen(false));
      }}
      header={t('soundLibraryUnvailable')}
      subHeader={t('soundLibraryUnvailableDesc')}
      buttons={[
        {
          text: t('ok'),
          role: 'confirm',
          handler: () => {
            history.push('/app/waitinglist');
          },
        },
      ]}
    />
  );
};

export default InfoSoundLibraryUnavailable;
