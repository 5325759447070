import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { satisfies } from 'semver';

type HardwareMapping = { box: string; player: string };

type FeaturesCompatibilityRanges = {
  playSpotFeature: HardwareMapping;
};

export const useFeatureAvailable = (featureName: keyof FeaturesCompatibilityRanges): boolean => {
  const featuresCompatbilityRanges: FeaturesCompatibilityRanges = {
    playSpotFeature: {
      box: '>=2.5.1',
      player: '>=1.10.1',
    },
  };
  const { driverVersion, hardware } = useSelector((state: RootState) => state.PlayerReducer.params);
  return satisfies(driverVersion, featuresCompatbilityRanges[featureName][hardware]);
};
