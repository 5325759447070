import { useEffect } from 'react';
import { useLazyGetJWTFromSessionQuery } from '../services/auth.service';
import { getSubdomain } from '../utils/platform';
import { setToast } from '../store/toastSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Auth = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [trigger] = useLazyGetJWTFromSessionQuery();

  useEffect(() => {
    trigger({
      boxDomain: getSubdomain(),
      sessionId: new URLSearchParams(window.location.search).get('sessionId') || undefined,
    })
      .unwrap()
      .then(() => {
        document.location.href = '/app';
      })
      .catch(() => {
        dispatch(
          setToast({
            isOpen: true,
            duration: 5000,
            message: t('loginError'),
          }),
        );
        document.location.href = '/login';
      });
  }, [dispatch, t, trigger]);

  return <></>;
};

export default Auth;
