import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../../components/AlertModal';
import { closeArtistBottomSheet, closeConfirmBlacklistArtistModale } from '../../../store/modaleSlice';
import { RootState } from '../../../store/rootReducer';
import { setToast } from '../../../store/toastSlice';
import SocketService from '../../../services/socket';
import { useUpdateBlacklistMutation } from '../../../services/api.service';
import store from '../../../store/store';

const ConfirmBlacklistArtist = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appState = useSelector((state: RootState) => state.AppReducer);

  const confirmBlacklistArtistModale = useSelector(
    (state: RootState) => state.ModaleReducer.confirmBlacklistArtistModale,
  );
  const confirmBlacklistArtistModaleIsOpen = useSelector(
    (state: RootState) => state.ModaleReducer.confirmBlacklistArtistModaleIsOpen,
  );
  const [updateBlacklist] = useUpdateBlacklistMutation();

  return (
    <AlertModal
      isOpen={confirmBlacklistArtistModaleIsOpen}
      onDidDismiss={() => {
        dispatch(closeConfirmBlacklistArtistModale());
        dispatch(closeArtistBottomSheet());
      }}
      header={t('blacklistArtistTitle')}
      subHeader={t('blacklistArtistDesc')}
      buttons={[
        {
          text: t('back'),
          role: 'cancel',
        },
        {
          text: t('confirm'),
          role: 'confirm',
          handler: () => {
            if (!confirmBlacklistArtistModale) {
              return;
            }
            const state = store.getState();
            state.PlayerReducer.waitlist
              .filter((track) => track.artist.id === confirmBlacklistArtistModale.id)
              .forEach((track) => {
                SocketService.removeMusic(track.id);
              });
            const { currentMusic } = state.PlayerReducer;
            if (currentMusic?.artist.id === confirmBlacklistArtistModale.id) {
              SocketService.passMusic();
            }
            updateBlacklist({
              type: 'artist',
              artistId: confirmBlacklistArtistModale.id,
              name: confirmBlacklistArtistModale.name,
              clientId: appState.boxDetail?._id || '',
              img: confirmBlacklistArtistModale.img || '',
            })
              .unwrap()
              .then(() => {
                SocketService.blacklistChanged();
                dispatch(
                  setToast({
                    isOpen: true,
                    message: t('artistBlacklisted'),
                    duration: 5000,
                  }),
                );
              })
              .catch(() => {
                dispatch(
                  setToast({
                    isOpen: true,
                    message: t('artistBlacklistedError'),
                    duration: 5000,
                  }),
                );
              });
          },
        },
      ]}
    />
  );
};

export default ConfirmBlacklistArtist;
