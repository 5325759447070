import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { IonicSlides, IonSpinner } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel, Virtual } from 'swiper';
import useResizeObserver from '@react-hook/resize-observer';

import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/free-mode';
import 'swiper/css/mousewheel';
import '@ionic/react/css/ionic-swiper.css';

import { StyledSwiperWrapper, StyledScrollRow, StyledScrollDummyItem } from './InfiniteVirtualScroll.styled';

type InfiniteVirtualScrollDefaultProps = {
  direction?: 'horizontal' | 'vertical';
  children?: ReactNode[];
  nbItemsPerView?: number;
  spinningElement?: ReactNode;
  nbSpinningElement?: number;
  maxLength?: number;
  loadData?: () => void;
  loading?: boolean;
  fetching?: boolean;
};

export type InfiniteVirtualScrollProps = InfiniteVirtualScrollDefaultProps;

export const scrollRowsBuilder: <T>(props: {
  fetching?: boolean;
  nbItemsPerRow: number;
  itemLoading: React.ReactNode;
  item?: (itemData: T) => JSX.Element;
  itemsData?: T[];
}) => JSX.Element[] = (props) => {
  const rows = props.itemsData
    ? props.itemsData
        .reduce((allRows, currentItem, i) => {
          const currentRowIndex = Math.floor(i / props.nbItemsPerRow);
          allRows[currentRowIndex] = [...(allRows[currentRowIndex] || []), currentItem];
          return allRows;
        }, [] as Array<typeof props.itemsData>)
        .map((items) =>
          [...items, ...(Array.from({ length: props.nbItemsPerRow }).fill(null) as null[])].slice(
            0,
            props.nbItemsPerRow,
          ),
        )
    : [Array.from({ length: props.nbItemsPerRow }).fill(null) as null[]];

  return rows.map((row, rowIndex) => (
    <StyledScrollRow key={`row-${rowIndex}`}>
      {row.map((item, itemIndex) => (
        <React.Fragment key={`row-${rowIndex}-item-${itemIndex}`}>
          {item === null ? props.fetching ? props.itemLoading : <StyledScrollDummyItem /> : props.item?.(item)}
        </React.Fragment>
      ))}
    </StyledScrollRow>
  ));
};

const InfiniteVirtualScroll = (props: InfiniteVirtualScrollProps) => {
  const swiperWrapperRef = useRef<HTMLDivElement | null>(null);
  const firstItemSwiperRef = useRef<HTMLDivElement>(null);
  const [swiperWrapperElRect, setSwiperWrapperElRect] = useState<DOMRect>();
  const [itemSwiperElRect, setItemSwiperElRect] = useState<DOMRect>();
  const getItemsPerView = () => {
    if (props.nbItemsPerView) {
      return props.nbItemsPerView;
    } else if (swiperWrapperElRect && itemSwiperElRect) {
      if (props.direction === 'vertical') {
        return (swiperWrapperElRect.height || 1) / (itemSwiperElRect.height || 1);
      } else {
        return (swiperWrapperElRect.width || 1) / (itemSwiperElRect.width || 1);
      }
    } else {
      return 1;
    }
  };

  const elements = [
    ...(!props.loading && props.children ? props.children : []),
    ...(props.loading || props.fetching
      ? props.spinningElement
        ? Array.from({
            length: Math.ceil(props.nbSpinningElement || getItemsPerView()),
          }).map(() => props.spinningElement)
        : [
            <>
              <div style={{ visibility: 'hidden', flex: 1 }}>
                {props.children && props.children[props.children.length - 1]}
              </div>
              <IonSpinner
                style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
              />
            </>,
          ]
      : []),
  ];
  useEffect(() => {
    const preventSwipeNav = (e: Event) => {
      document.querySelector('html')?.setAttribute('style', 'overscroll-behavior: none');
      e.preventDefault();
      e.stopPropagation();
    };
    const removePreventSwipeNav = (e: Event) => {
      document.querySelector('html')?.removeAttribute('style');
      e.preventDefault();
      e.stopPropagation();
    };
    // on attend le rerender suite au setState de la haute du premier élément
    // on a maintenant la hauteur du swiper, on le stocke dans le state pour rerender
    // <Swiper height={swiperWrapperElRect.height} /> pour le bon fonctionnement vertical

    const swiperWrapperEl = swiperWrapperRef.current;
    if (swiperWrapperEl) {
      swiperWrapperEl.addEventListener('wheel', preventSwipeNav);
      swiperWrapperEl.addEventListener('mouseleave', removePreventSwipeNav);
    }

    return () => {
      swiperWrapperEl?.removeEventListener('wheel', preventSwipeNav);
      swiperWrapperEl?.removeEventListener('mouseleave', removePreventSwipeNav);
    };
  }, [itemSwiperElRect, swiperWrapperRef]);

  useResizeObserver(firstItemSwiperRef, (entry) => {
    if (entry.target.getBoundingClientRect().height > 0) {
      setItemSwiperElRect(entry.target.getBoundingClientRect());
    }
  });
  useResizeObserver(swiperWrapperRef, (entry) => {
    if (entry.target.getBoundingClientRect().height > 0) {
      setSwiperWrapperElRect(entry.target.getBoundingClientRect());
    }
  });

  return (
    <StyledSwiperWrapper ref={swiperWrapperRef}>
      {swiperWrapperElRect && swiperWrapperElRect.height > 0 ? (
        <Swiper
          direction={props.direction}
          modules={[Virtual, FreeMode, Mousewheel, IonicSlides]}
          slidesPerView={getItemsPerView()}
          onReachEnd={() => {
            if (!props.loading && !props.fetching) {
              props.loadData?.();
            }
          }}
          virtual={{ addSlidesAfter: 20, addSlidesBefore: 20 }}
          freeMode={{
            momentum: true,
            momentumRatio: 1,
            momentumVelocityRatio: 1,
          }}
          mousewheel
          onProgress={(swiper, p) => {
            // hack to prevent loop issue https://github.com/nolimits4web/swiper/issues/5880
            if (p >= 1) {
              swiper.isEnd = false;
            }
          }}
          height={swiperWrapperElRect.height}
        >
          {elements.map((item, index) => (
            <SwiperSlide key={`slide-key-${index}`} virtualIndex={index}>
              {item}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        /* on affiche un Swiper temporaire en mode horizontal pour avoir la hauteur d'une ligne */
        <div style={{ visibility: 'hidden', display: 'flex' }} ref={firstItemSwiperRef}>
          <Swiper modules={[IonicSlides]} slidesPerView={getItemsPerView()}>
            {elements.slice(0, 1).map((item, index) => (
              <SwiperSlide key={`slide-key-${index}`} virtualIndex={index}>
                {item}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </StyledSwiperWrapper>
  );
};

export default InfiniteVirtualScroll;
