import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addMilliseconds } from 'date-fns';
import { Seconds } from '../utils/types';
/* eslint-disable @typescript-eslint/no-unused-vars */

export type TrackDto = {
  artist: string;
  downloaded: boolean;
  duration: number;
  genre: string;
  img: string;
  isrc: string;
  audioInfo: {
    replaygain_track_gain: number;
    replaygain_track_peak: number;
  };
  order: number;
  sender: string;
  album?: string;
  title: string;
  trackId: number;
  votes: number;
  streamable?: boolean;
  downloadable?: boolean;
};

export type ParamsDto = {
  currentPlaylistId: string;
  currentPlaylistMode: 'ephemeral' | 'oto' | 'default';
  currentPlaylistTitle: string;
  isJukeboxModeOn: boolean;
  isPlaylistRandom: boolean;
  isQobuzOffline: boolean;
  isSearchAvailable: boolean;
  isTshokoConnected: boolean;
  isVoteAvailable: boolean;
  tshokoStatus: 'start' | 'stop';
  driverVersion: string;
  hardware: 'player' | 'box';
};

export type PastTrackDto = TrackDto & { timestamp: number };
export type CurrentTrackDto = TrackDto & { timer: number };
export type FutureTrackDto = TrackDto;

export type Params = ParamsDto & { loaded: boolean };

export type Track = {
  id: number;
  isrc?: string;
  audioInfo?: {
    replaygain_track_gain: number;
    replaygain_track_peak: number;
  };
  title: string;
  artist: {
    id: number;
    name: string;
  };
  img: string;
  number?: number;
  explicit?: boolean;
  voted?: boolean;
  blacklisted?: boolean;
  votes?: number;
  streamable: boolean;
};

export type PastTrack = Track & { playingDate: string };
export type CurrentTrack = Track & { playingDate: string; duration: number; author?: string };
export type FutureTrack = Track;
export type Spot = {
  id: string;
  title: string;
  duration: Seconds;
  img?: string;
  author?: string;
};
export type PastSpot = Spot & {
  playingDate: string;
};
export type CurrentSpot = Spot & {
  playingDate: string;
};
export type FutureSpot = Spot;

const buildPastTrackFromDto = (trackDto: TrackDto, userVoted: boolean): PastTrack => {
  const track = trackDto as PastTrackDto;
  return {
    id: track.trackId,
    title: track.title,
    artist: {
      id: 0,
      name: track.artist,
    },
    img: track.img,
    explicit: undefined,
    playingDate: new Date(track.timestamp).toISOString(),
    votes: Math.floor(track.votes),
    voted: userVoted,
    streamable: true,
  };
};

const buildCurrentTrackFromDto = (trackDto: TrackDto, userVoted: boolean): CurrentTrack => {
  const track = trackDto as CurrentTrackDto;
  return {
    id: track.trackId,
    title: track.title,
    artist: {
      id: 0,
      name: track.artist,
    },
    img: track.img,
    explicit: undefined,
    playingDate: addMilliseconds(new Date(), -track.timer).toISOString(),
    duration: track.duration,
    votes: Math.floor(track.votes),
    voted: userVoted,
    streamable: true,
  };
};

const buildFutureTrackFromDto = (trackDto: TrackDto, userVoted: boolean): FutureTrack => {
  const track = trackDto;
  return {
    id: track.trackId,
    title: track.title,
    artist: {
      id: 0,
      name: track.artist,
    },
    img: track.img,
    explicit: undefined,
    votes: Math.floor(track.votes),
    voted: userVoted,
    streamable: true,
  };
};
type PlayerState = {
  params: Params;
  currentMusic?: CurrentTrack;
  waitlist: FutureTrack[];
  lastTracks: PastTrack[];
  votesList: number[];
  tracksLoading: boolean;
  offlineMode: boolean;
  volume: number;
  lastVolume: number;
};

const initialPlayerSlice: PlayerState = {
  params: {
    loaded: false,
    currentPlaylistId: '',
    currentPlaylistTitle: '',
    currentPlaylistMode: 'default',
    isJukeboxModeOn: false,
    isPlaylistRandom: false,
    isQobuzOffline: false,
    isSearchAvailable: false,
    isTshokoConnected: false, // Est-ce que la connexion TCP entre le player et le server est ouverte (indépendamment de si ça ping ou non)
    isVoteAvailable: false,
    tshokoStatus: 'stop', // Est-ce que le player est à l'état start ou stop (si stop, alors c'est qu'il est hors horaires)
    driverVersion: '1.0.0',
    hardware: 'box',
  },
  offlineMode: false, // Le server ne reçoit plus de ping de la box
  waitlist: [],
  tracksLoading: true,
  currentMusic: undefined,
  lastTracks: [],
  votesList: [],
  volume: 0,
  lastVolume: 0,
};

const PlayerSlice = createSlice({
  name: 'PlayerSlice',
  initialState: initialPlayerSlice,
  reducers: {
    resetPlayerState(state: PlayerState) {
      return initialPlayerSlice;
    },
    setTracksLoading(state: PlayerState, action: PayloadAction<boolean>) {
      state.tracksLoading = action.payload;
    },
    updateParams(state: PlayerState, action: PayloadAction<ParamsDto>) {
      state.params = { loaded: true, ...action.payload };
    },
    updatePlayerState(
      state: PlayerState,
      action: PayloadAction<{
        tracksLoading: boolean;
        waitlist: FutureTrackDto[];
        lastTracks: PastTrackDto[];
        currentMusic?: CurrentTrackDto;
      }>,
    ) {
      state.tracksLoading = action.payload.tracksLoading;
      state.waitlist = action.payload.waitlist.map((t) =>
        buildFutureTrackFromDto(t, state.votesList.includes(t.trackId)),
      );
      state.lastTracks = action.payload.lastTracks.map((t) =>
        buildPastTrackFromDto(t, state.votesList.includes(t.trackId)),
      );
      state.currentMusic =
        action.payload.currentMusic &&
        buildCurrentTrackFromDto(
          action.payload.currentMusic,
          state.votesList.includes(action.payload.currentMusic.trackId),
        );
    },
    updateVotesList(
      state: PlayerState,
      action: PayloadAction<{
        votesList: number[];
      }>,
    ) {
      state.votesList = action.payload.votesList;
      if (state.currentMusic) {
        state.currentMusic.voted = action.payload.votesList.includes(state.currentMusic.id);
      }
      state.lastTracks = state.lastTracks.map((t) => ({ ...t, voted: action.payload.votesList.includes(t.id) }));
      state.waitlist = state.waitlist.map((t) => ({ ...t, voted: action.payload.votesList.includes(t.id) }));
    },
    setOfflineMode(state: PlayerState, action: PayloadAction<boolean>) {
      state.offlineMode = action.payload;
    },
    addDownloadedMusic(state: PlayerState, action: PayloadAction<number>) {
      state.waitlist = state.waitlist.map((track) =>
        track.id === action.payload ? { ...track, downloaded: true } : track,
      );
    },
    updateVotes(state: PlayerState, action: PayloadAction<number[]>) {
      state.waitlist = state.waitlist.map((track) => ({
        ...track,
        votes: Math.floor(action.payload.filter((id) => id === track.id).length),
      }));
    },
    updateVolume(state: PlayerState, action: PayloadAction<string>) {
      state.volume = (+action.payload.replace('%', '') - 50) * 2;
    },
  },
});

const PlayerReducer = PlayerSlice.reducer;

export const {
  setOfflineMode,
  updateParams,
  addDownloadedMusic,
  setTracksLoading,
  updatePlayerState,
  updateVotes,
  updateVolume,
  updateVotesList,
  resetPlayerState,
} = PlayerSlice.actions;
export default PlayerReducer;
