import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
  padding-top: calc(var(--ion-safe-area-top) - 16px);
  margin-top: 16px;
  padding-inline-start: calc(var(--ion-safe-area-left, 0px) + 16px);
  padding-inline-end: calc(var(--ion-safe-area-right, 0px) + 16px);
  padding-bottom: 16px;

  & h1 {
    margin-top: 8px;
  }

  & h2 {
    margin-top: 8px;
  }

  & ion-button {
    width: 48px;
  }
  & .title-header {
    display: flex;
    justify-content: space-between;

    & .title {
      display: inline-flex;
      width: calc(100% - 48px);
    }
  }
`;
