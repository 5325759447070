import ConfirmBackToProg from './AlertModales/ConfirmBackToProg';
import ConfirmBlacklistAlbum from './AlertModales/ConfirmBlacklistAlbum';
import ConfirmBlacklistArtist from './AlertModales/ConfirmBlacklistArtist';
import ConfirmBlacklistTrack from './AlertModales/ConfirmBlacklistTrack';
import ConfirmPlayAlbum from './AlertModales/ConfirmPlayAlbum';
import ConfirmPlayAmbiance from './AlertModales/ConfirmPlayAmbiance';
import ConfirmPlayPlaylist from './AlertModales/ConfirmPlayPlaylist';
import ConfirmShutdownBox from './AlertModales/ConfirmShutdownBox';
import InfoConnectionLost from './AlertModales/InfoConnectionLost';
import InfoSoundLibraryUnavailable from './AlertModales/InfoSoundLibraryUnavailable';
import ConfirmJukebox from './AlertModales/ConfirmJukebox';
import AlbumBottomSheet from './BottomSheets/AlbumBottomSheet';
import ArtistBottomSheet from './BottomSheets/ArtistBottomSheet';
import BoxSettingsBottomSheet from './BottomSheets/BoxSettingsBottomSheet';
import TrackBottomSheet from './BottomSheets/TrackBottomSheet';
import SpotBottomSheet from './BottomSheets/SpotBottomSheet';
import ConfirmPlaySpot from './AlertModales/ConfirmPlaySpot';

const ModalesContainer = () => (
  <>
    <AlbumBottomSheet />
    <ArtistBottomSheet />
    <BoxSettingsBottomSheet />
    <TrackBottomSheet />
    <SpotBottomSheet />
    <ConfirmPlayAlbum />
    <ConfirmPlayAmbiance />
    <ConfirmPlayPlaylist />
    <ConfirmBlacklistAlbum />
    <ConfirmBlacklistTrack />
    <ConfirmBlacklistArtist />
    <ConfirmBackToProg />
    <ConfirmShutdownBox />
    <InfoConnectionLost />
    <InfoSoundLibraryUnavailable />
    <ConfirmJukebox />
    <ConfirmPlaySpot />
  </>
);

export default ModalesContainer;
