import React from 'react';
import { IonContent, IonIcon } from '@ionic/react';
import List from '../../../../components/List';
import InfiniteVirtualScroll from '../../../../components/InfiniteVirtualScroll';
import PlaylistCard from '../../../../components/PlaylistCard';
import { useTranslation } from 'react-i18next';
import { sharpen } from '../../../../utils/number';
import Typography from '../../../../components/Typography';
import SoundDesignCard from '../../../../components/SoundDesignCard';
import IndependentPlaylistsCard from '../../../../components/IndependentPlaylistsCard';
import { SearchLayoutProps } from '../SearchLayout';
import PlaylistDrawer from '../../../../features/Drawer/PlaylistDrawer';
import SoundDesignDrawer from '../../../../features/Drawer/SoundDesignDrawer';
import IndependentPlaylistsDrawer from '../../../../features/Drawer/IndependentPlaylistsDrawer';
import useKeyboard from '../../../../utils/hooks/useKeyboard';
import { StyledIonNavLink } from '../../../../features/Drawer/Drawer.styled';
import BackToProgButton from '../../../../components/BackToProgButton';
import { chevronForward } from 'ionicons/icons';
import SpotCard, { SpotCardDefaultProps } from '../../../../components/SpotCard';
import { useFeatureAvailable } from '../../../../utils/hooks/useFeatureAvailable';
import { isWhiteLabel } from '../../../../utils/platform';

const SearchTabSoundLibrary = (props: {
  loading?: boolean;
  soundLibrary: SearchLayoutProps['soundLibrary'];
  onClickPlaylists: () => void;
  onClickSharedPlaylists: () => void;
  isPlaylistsFetching?: boolean;
  loadDataPlaylists?: () => void;
  isSharedPlaylistsFetching?: boolean;
  loadDataSharedPlaylists?: () => void;
  onClickSpotsHeader: () => void;
  onClickSpotCard: (spot: SpotCardDefaultProps['spot']) => void;
  isSpotsFetching?: boolean;
  loadDataSpots?: () => void;
  onBackToProgClick?: () => void;
}) => {
  const { t } = useTranslation();
  const { hideKeyboard } = useKeyboard();
  const nbItemsPerViewSoundLibrary = sharpen(window.innerWidth / (320 / 2));
  const { onBackToProgClick } = props;
  const playSpotFeatureAvailable = useFeatureAvailable('playSpotFeature');

  return props.soundLibrary ? (
    <IonContent scrollEvents onIonScrollStart={() => hideKeyboard?.()}>
      <div style={props.loading ? { pointerEvents: 'none' } : undefined}>
        {props.loading ? (
          <div>
            {onBackToProgClick && (
              <List
                noIcon
                header={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography tag="h3" style={{ marginRight: 10 }} loading>
                      {t('defaultProg')}
                    </Typography>
                  </div>
                }
                subHeader={
                  <div style={{ display: 'flex', marginLeft: -16 }}>
                    <BackToProgButton label={t('backToProg')} loading />
                  </div>
                }
              />
            )}
          </div>
        ) : props.soundLibrary.playlists.length > 0 ? (
          <div>
            {onBackToProgClick && (
              <List
                noIcon
                header={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography tag="h3" style={{ marginRight: 10 }}>
                      {t('defaultProg')}
                    </Typography>
                    <IonIcon src={chevronForward} style={{ width: 20, fontSize: 20, opacity: 0.25 }} />
                  </div>
                }
                subHeader={
                  <div style={{ display: 'flex', marginLeft: -16 }}>
                    <BackToProgButton label={t('backToProg')} />
                  </div>
                }
                onHeaderClick={onBackToProgClick}
              />
            )}
          </div>
        ) : null}
        {props.loading ? (
          <div style={{ marginBottom: 12 }}>
            <List
              header={
                <Typography tag="h3" loading>
                  {t('mySpots')}
                </Typography>
              }
            >
              <InfiniteVirtualScroll
                key="spots-loading-elements"
                loading
                nbItemsPerView={nbItemsPerViewSoundLibrary}
                spinningElement={<SpotCard loading />}
              />
            </List>
          </div>
        ) : props.soundLibrary.spots.length > 0 && playSpotFeatureAvailable ? (
          <div style={{ marginBottom: 12 }}>
            <List header={t('mySpots')} onHeaderClick={props.onClickSpotsHeader}>
              <InfiniteVirtualScroll
                loadData={props.loadDataSpots}
                fetching={props.isSpotsFetching}
                nbItemsPerView={nbItemsPerViewSoundLibrary / 1.1}
                spinningElement={<SpotCard loading />}
              >
                {props.soundLibrary.spots.map((spot) => {
                  return (
                    <SpotCard
                      key={`spot-${spot.id}`}
                      loading={false}
                      spot={spot}
                      onClick={() => {
                        props.onClickSpotCard(spot);
                      }}
                    />
                  );
                })}
              </InfiniteVirtualScroll>
            </List>
          </div>
        ) : null}
        {props.loading ? (
          <div style={{ marginBottom: 12 }}>
            <List
              header={
                <Typography tag="h3" loading>
                  {t('myPlaylists')}
                </Typography>
              }
            >
              <InfiniteVirtualScroll
                key="playlists-loading-elements"
                loading
                nbItemsPerView={nbItemsPerViewSoundLibrary}
                spinningElement={<PlaylistCard loading />}
              />
            </List>
          </div>
        ) : props.soundLibrary.playlists.length > 0 ? (
          <div style={{ marginBottom: 12 }}>
            <List header={t('myPlaylists')} onHeaderClick={props.onClickPlaylists}>
              <InfiniteVirtualScroll
                key="playlists-elements"
                loadData={props.loadDataPlaylists}
                fetching={props.isPlaylistsFetching}
                nbItemsPerView={nbItemsPerViewSoundLibrary}
                spinningElement={<PlaylistCard loading />}
              >
                {props.soundLibrary.playlists.map((playlist, i) => {
                  return (
                    <StyledIonNavLink
                      key={`playlist-${playlist.id}-${i}`}
                      routerDirection="forward"
                      component={() => (
                        <PlaylistDrawer
                          playlistId={playlist.id}
                          ambianceColor={playlist.ambiance?.color}
                          independentPlaylist={playlist.independent}
                        />
                      )}
                    >
                      <PlaylistCard playlist={playlist} onClick={() => {}} />
                    </StyledIonNavLink>
                  );
                })}
              </InfiniteVirtualScroll>
            </List>
          </div>
        ) : null}
        {props.loading ? (
          <div style={{ marginBottom: 12 }}>
            <List
              header={
                <Typography tag="h3" loading>
                  {t('sharedPlaylists')}
                </Typography>
              }
            >
              <InfiniteVirtualScroll
                key="playlists-loading-elements"
                loading
                nbItemsPerView={nbItemsPerViewSoundLibrary}
                spinningElement={<PlaylistCard loading />}
              />
            </List>
          </div>
        ) : props.soundLibrary.sharedPlaylists.length > 0 ? (
          <div style={{ marginBottom: 12 }}>
            <List header={t('sharedPlaylists')} onHeaderClick={props.onClickSharedPlaylists}>
              <InfiniteVirtualScroll
                key="playlists-elements"
                loadData={props.loadDataSharedPlaylists}
                fetching={props.isSharedPlaylistsFetching}
                nbItemsPerView={nbItemsPerViewSoundLibrary}
                spinningElement={<PlaylistCard loading />}
              >
                {props.soundLibrary.sharedPlaylists.map((playlist, i) => {
                  return (
                    <StyledIonNavLink
                      key={`shared-playlist-${playlist.id}-${i}`}
                      routerDirection="forward"
                      component={() => (
                        <PlaylistDrawer
                          playlistId={playlist.id}
                          ambianceColor={playlist.ambiance?.color}
                          independentPlaylist={playlist.independent}
                        />
                      )}
                    >
                      <PlaylistCard playlist={playlist} onClick={() => {}} />
                    </StyledIonNavLink>
                  );
                })}
              </InfiniteVirtualScroll>
            </List>
          </div>
        ) : null}
        {props.loading ? (
          <div style={{ marginBottom: 12 }} key="soundDesign-loading">
            <List header={<Typography tag="h3" loading />}>
              <div style={{ display: 'flex', maxWidth: 425 }}>
                <SoundDesignCard loading />
                <IndependentPlaylistsCard loading />
              </div>
            </List>
          </div>
        ) : props.soundLibrary.soundDesigns.length > 0 ? (
          <>
            {props.soundLibrary.soundDesigns.map((sd) =>
              sd.ambiancesCount > 0 || sd.independentPlaylists.length > 0 ? (
                <div style={{ marginBottom: 12 }} key={`soundDesign-${sd.id}`}>
                  <List header={sd.name === 'Tshoko' && isWhiteLabel() ? t('catalog') : sd.name}>
                    <div style={{ display: 'flex', maxWidth: 425 }}>
                      {sd.ambiancesCount > 0 && (
                        <StyledIonNavLink
                          key={`ionnavlink-${sd.id}`}
                          component={() => <SoundDesignDrawer soundDesign={sd} />}
                        >
                          <SoundDesignCard soundDesign={sd} onClick={() => {}} />
                        </StyledIonNavLink>
                      )}
                      {sd.independentPlaylists.length > 0 ? (
                        <StyledIonNavLink
                          key={`ionnavlink-${sd.id}-independent`}
                          routerDirection="forward"
                          component={() => <IndependentPlaylistsDrawer soundDesignId={sd.id} />}
                        >
                          <IndependentPlaylistsCard playlists={sd.independentPlaylists} onClick={() => {}} />
                        </StyledIonNavLink>
                      ) : (
                        <div style={{ flex: 1 }} />
                      )}
                      {sd.ambiancesCount === 0 && <div style={{ flex: 1 }} />}
                    </div>
                  </List>
                </div>
              ) : null,
            )}
          </>
        ) : null}
      </div>
    </IonContent>
  ) : null;
};

export default SearchTabSoundLibrary;
