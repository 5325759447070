import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledPageContent, StyledContentContainer } from './JukeboxOfflineLayout.styled';
import Typography from '../../components/Typography';
import JukeboxOfflineSvg from '../../components/JukeboxOfflineSvg';
import { HeaderProps } from '../../components/Header';

export type JukeboxOfflineLayoutProps = {
  renderHeader: () => ReactElement<HeaderProps>;
};

const JukeboxOfflineLayout = (props: JukeboxOfflineLayoutProps) => {
  const { t } = useTranslation();
  return (
    <StyledPageContent>
      {props.renderHeader()}
      <StyledContentContainer className="ion-padding">
        <div className="content">
          <JukeboxOfflineSvg className="svg-container" />
          <Typography tag="p" variant="secondary">
            {t('jukeboxOfflineDesc')}
          </Typography>
        </div>
      </StyledContentContainer>
    </StyledPageContent>
  );
};

export default JukeboxOfflineLayout;
