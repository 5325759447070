import { isRejectedWithValue, MiddlewareAPI } from '@reduxjs/toolkit';
import { auth } from '../services/auth.service';
import { userIsLogged } from '../utils/security';

// Create the middleware instance and methods

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jwtTokenRefresher = (middleware: MiddlewareAPI) => (next: any) => async (action: any) => {
  if (action && isRejectedWithValue(action)) {
    const { endpointName } = action.meta.arg;
    if (action.payload.originalStatus === 401) {
      if (userIsLogged()) {
        await middleware.dispatch(auth.util.invalidateTags(['refreshJwt']));
      }
    }
    if (
      endpointName === 'refreshJwt' &&
      userIsLogged() &&
      (action.payload.originalStatus === 401 || action.payload.originalStatus === 403)
    ) {
      window.dispatchEvent(new Event('performLogout'));
    }
  }

  return next(action);
};
