import styled from 'styled-components';

export const StyledSwiperWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  & .swiper .swiper-slide {
    align-items: inherit;
    flex-direction: column;
  }
`;

export const StyledScrollRow = styled.div`
  display: flex;
`;

export const StyledScrollDummyItem = styled.div`
  display: flex;
  flex: 1;
`;
