import { useEffect, useCallback, useState } from 'react';
import io from 'socket.io-client';
import { useHistory } from 'react-router-dom';
import App from './App';
import { useGetBlacklistsQuery, useGetBoxWithDomainQuery } from './services/api.service';
import { getSubdomain } from './utils/platform';
import { getJwt, getUniqueUserUUID, getUserDecodedJwt, setJwt } from './utils/security';
import { useDispatch, useSelector } from 'react-redux';
import { getSocket, initSocket, killSocket } from './services/socket';
import { RootState } from './store/rootReducer';
import LoadingScreenLayout from './layouts/LoadingScreenLayout';
import ServerOfflineLayout from './layouts/ServerOfflineLayout';
import { useTranslation } from 'react-i18next';
import { clearCache } from './features/Search/utils';
import { setToast } from './store/toastSlice';
import useInterval from './utils/hooks/useInterval';
import { HeaderWithSwitchBox } from './features/components/HeaderWithSwitchBox';
import { resetAppState, setBoxDetail, setIsAdminMode, setIsSwitchingBox, setUsername } from './store/appSlice';

const AppLoader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const decodedJWT = getUserDecodedJwt();
  const [isSocketError, setIsSocketError] = useState(false);
  const appState = useSelector((state: RootState) => state.AppReducer);
  const { data: blacklists } = useGetBlacklistsQuery(appState.boxDetail?._id || '', { skip: !appState.boxDetail?._id });
  const playerStateParamsLoaded = useSelector((state: RootState) => state.PlayerReducer.params.loaded);
  const {
    data: clientWithDom,
    isError: getBoxDomainError,
    isFetching: getBoxDomainFetching,
    refetch: refetchGetBoxWithDomainQuery,
  } = useGetBoxWithDomainQuery(getSubdomain(), {});

  const boxDetailResult = clientWithDom && clientWithDom.success && clientWithDom.result;

  useEffect(() => {
    const logout = () => {
      dispatch(resetAppState());
      setJwt(undefined);
      history.push('/login');
    };
    window.addEventListener('performLogout', logout);
    return () => {
      window.removeEventListener('performLogout', logout);
    };
  }, [appState, dispatch, history]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const connectToSocket = useCallback((boxId: string, socketConf: any) => {
    if (boxId) {
      const protocol = process.env.REACT_APP_DEVELOPER_MODE
        ? +process.env.REACT_APP_DEVELOPER_MODE === 1
          ? 'ws'
          : 'wss'
        : 'wss';
      const socket = io(`${protocol}://` + socketConf.host, {
        path: `/${socketConf.path}`,
        query: { username: socketConf.name, token: getJwt() },
        reconnection: false,
      });
      initSocket(socket, boxId);
      socket.on('connect_error', (error: Error) => {
        console.error('socket connect_error', error);
        setIsSocketError(true);
      });
      socket.on('connect', () => {
        setIsSocketError(false);
      });
      socket.on('disconnect', (error: Error) => {
        console.error('disconnect', error);
        connectToSocket(boxId, socketConf);
      });
    }
  }, []);

  const initApp = useCallback(() => {
    if (boxDetailResult && boxDetailResult._id !== appState.boxDetail?._id) {
      if (appState.isSwitchingBox) {
        dispatch(
          setToast({
            isOpen: true,
            header: t(''),
            message: t('boxChanged'),
            duration: 5000,
          }),
        );
      }
      dispatch(setIsSwitchingBox(false));
      dispatch(setBoxDetail(boxDetailResult));
      dispatch(setUsername(undefined));
      dispatch(setIsAdminMode(boxDetailResult._id === decodedJWT?.currentBox.id && decodedJWT?.currentBox.admin));
      connectToSocket(boxDetailResult._id, {
        host: boxDetailResult.socket.host,
        path: boxDetailResult.socket.path,
        name: getUniqueUserUUID(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxDetailResult]);

  useEffect(() => {
    clearCache();
  }, [blacklists]);

  useEffect(() => {
    (async () => {
      await initApp();
    })();
    return () => {
      killSocket();
    };
  }, [initApp]);

  useInterval(() => {
    if (getBoxDomainError) {
      refetchGetBoxWithDomainQuery();
      return;
    }
    if (isSocketError || !getSocket().connected) {
      if (appState.boxDetail) {
        connectToSocket(appState.boxDetail._id, {
          host: appState.boxDetail.socket.host,
          path: appState.boxDetail.socket.path,
          name: getUniqueUserUUID(),
        });
      }
      return;
    }
  }, 2000);

  return getBoxDomainError || (clientWithDom && !clientWithDom.success) || isSocketError ? (
    <ServerOfflineLayout
      renderHeader={() => <HeaderWithSwitchBox title={appState.boxDetail?.name || ''} subtitle={t('serverOffline')} />}
    />
  ) : !playerStateParamsLoaded || getBoxDomainFetching ? (
    <LoadingScreenLayout />
  ) : (
    <App />
  );
};

export default AppLoader;
