import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getJwt, setJwt } from '../utils/security';

const injectJwt = (response: unknown) => {
  if (typeof response === 'string') {
    setJwt(response);
    return response;
  } else {
    setJwt(undefined);
  }
};

// Define a service using a base URL and expected endpoints
export const auth = createApi({
  reducerPath: 'auth',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_AUTH_API_HOSTNAME }),
  tagTypes: ['refreshJwt'],
  endpoints: (builder) => ({
    getJWT: builder.mutation<
      string | undefined,
      { username: string; password: string; boxId?: string; boxDomain?: string }
    >({
      transformResponse: injectJwt,
      query: (loginInfos) => ({
        url: `/mobileapp-token`,
        params: {
          ...(loginInfos.boxId
            ? { boxId: loginInfos.boxId }
            : loginInfos.boxDomain
            ? { boxDomain: loginInfos.boxDomain }
            : {}),
        },
        method: 'POST',
        body: { username: loginInfos.username, password: loginInfos.password },
      }),
    }),
    getJWTFromSession: builder.query<string | undefined, { boxId?: string; boxDomain?: string; sessionId?: string }>({
      transformResponse: injectJwt,
      query: (loginInfos) => ({
        url: `/token/mobileapp`,
        ...(!loginInfos.sessionId ? { credentials: 'include' } : {}),
        params: {
          ...(loginInfos.boxId
            ? { boxId: loginInfos.boxId }
            : loginInfos.boxDomain
            ? { boxDomain: loginInfos.boxDomain }
            : {}),
          ...(loginInfos.sessionId ? { sessionId: loginInfos.sessionId } : {}),
        },
      }),
    }),
    refreshJwt: builder.query<string | undefined, { boxId?: string; boxDomain?: string }>({
      transformResponse: injectJwt,
      providesTags: ['refreshJwt'],
      query: (refreshInfos) => ({
        url: '/mobileapp-token/refresh',
        params: {
          ...(refreshInfos?.boxId
            ? { boxId: refreshInfos.boxId }
            : refreshInfos?.boxDomain
            ? { boxDomain: refreshInfos.boxDomain }
            : {}),
        },
        method: 'POST',
        body: refreshInfos,
        headers: getJwt() ? { Authorization: `JWT ${getJwt()}` } : {},
      }),
    }),
  }),
});

export const { useLazyGetJWTFromSessionQuery, useGetJWTMutation, useRefreshJwtQuery, useLazyRefreshJwtQuery } = auth;
