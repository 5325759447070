import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WaitingListLayout from '../layouts/WaitingListLayout';
import { setTrackBottomSheet } from '../store/modaleSlice';
import { RootState } from '../store/rootReducer';
import { HeaderWithSwitchBox } from './components/HeaderWithSwitchBox';
import Drawer from './Drawer/Drawer';
import { useVoteTrackHook } from './hooks/useVoteTrack';

const WaitingList = () => {
  const { t } = useTranslation();
  const appState = useSelector((state: RootState) => state.AppReducer);

  const dispatch = useDispatch();
  const { lastTracks, currentMusic, waitlist, tracksLoading, params } = useSelector(
    (state: RootState) => state.PlayerReducer,
  );
  const voteTrack = useVoteTrackHook();

  return (
    <WaitingListLayout
      admin={appState.isAdminMode}
      renderHeader={() => (
        <HeaderWithSwitchBox
          loading={tracksLoading}
          title={appState.isAdminMode ? appState.boxDetail?.name : undefined}
          subtitle={t('waitingList')}
        />
      )}
      loading={tracksLoading}
      pastItems={lastTracks}
      currentItem={currentMusic}
      futureItems={waitlist}
      onPastTrackClick={(pastTrack) => dispatch(setTrackBottomSheet({ ...pastTrack, fromWaitlist: true }))}
      onCurrentTrackClick={(currentTrack) => dispatch(setTrackBottomSheet({ ...currentTrack, fromWaitlist: true }))}
      onFutureTrackClick={(futureTrack) => dispatch(setTrackBottomSheet({ ...futureTrack, fromWaitlist: true }))}
      onVoteClick={
        params.isJukeboxModeOn
          ? (track) => {
              voteTrack(track);
            }
          : undefined
      }
    />
  );
};

export default () => {
  const root = useCallback(() => <WaitingList />, []);
  return <Drawer drawerRoot={root} />;
};
