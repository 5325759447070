import React from 'react';
import { IonSkeletonText } from '@ionic/react';
import Typography from '../Typography';
import {
  StyledIonItem,
  StyledIonImg,
  StyledIonLabel,
  StyledAmbianceTitle,
  StyledAmbianceVerticalBorders,
  StyledAmbianceHorizontalBorders,
  StyledIonThumbnail,
} from './AmbianceCard.styled';

export type AmbianceCardDefaultProps = {
  ambiance: {
    id: string;
    img: string;
    name: string;
    color: string;
    soundDesignName: string;
  };
  onClick?: () => void;
  loading?: boolean;
  hideText?: boolean;
  hideSoundDesignName?: boolean;
};

type AmbianceCardLoading = { loading: true; hideText?: boolean };

export type AmbianceCardProps = AmbianceCardDefaultProps | AmbianceCardLoading;

export const AmbianceThumbnail = (props: Pick<AmbianceCardDefaultProps, 'ambiance'>) => (
  <StyledIonThumbnail>
    <StyledIonImg src={props.ambiance.img} />
    {props.ambiance.soundDesignName.toLowerCase() !== 'tshoko' && (
      <>
        <StyledAmbianceVerticalBorders style={{ color: props.ambiance.color }} />
        <StyledAmbianceHorizontalBorders style={{ color: props.ambiance.color }} />
      </>
    )}
  </StyledIonThumbnail>
);

const AmbianceCard = (props: AmbianceCardProps) => {
  return props.loading ? (
    <StyledIonItem mode="md">
      <StyledIonThumbnail>
        <IonSkeletonText animated={true} style={{ borderRadius: 4 }} />
      </StyledIonThumbnail>
      {!props.hideText && (
        <StyledIonLabel>
          <StyledAmbianceTitle>
            <Typography tag="h3" className="ion-text-nowrap" loading style={{ width: 150 }} />
          </StyledAmbianceTitle>
        </StyledIonLabel>
      )}
    </StyledIonItem>
  ) : (
    <StyledIonItem button={!!props.onClick} onClick={props.onClick} mode="md">
      <AmbianceThumbnail ambiance={props.ambiance} />
      {!props.hideText && (
        <StyledIonLabel>
          <StyledAmbianceTitle>
            <Typography tag="h3" className="ion-text-nowrap">
              {props.hideSoundDesignName
                ? props.ambiance.name
                : `${props.ambiance.name} • ${props.ambiance.soundDesignName}`}
            </Typography>
          </StyledAmbianceTitle>
        </StyledIonLabel>
      )}
    </StyledIonItem>
  );
};

export default AmbianceCard;
