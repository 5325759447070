import React from 'react';
import Typography from '../Typography';
import { StyledIonItem, StyledIonLabel, StyledArtistName } from './ArtistCard.styled';
import Avatar from '../Avatar';

export type ArtistCardDefaultProps = {
  artist: {
    id: number;
    name: string;
    img?: string;
    blacklisted?: boolean;
  };
  onClick?: () => void;
  loading?: boolean;
  hideText?: boolean;
};

type ArtistCardLoading = { loading: true; hideText?: boolean };

export type ArtistCardProps = ArtistCardDefaultProps | ArtistCardLoading;

const ArtistCard = (props: ArtistCardProps) => {
  return props.loading ? (
    <StyledIonItem mode="md">
      <Avatar loading />
      {!props.hideText && (
        <StyledIonLabel>
          <StyledArtistName>
            <Typography tag="h3" className="ion-text-nowrap" loading />
          </StyledArtistName>
        </StyledIonLabel>
      )}
    </StyledIonItem>
  ) : (
    <StyledIonItem
      button={!!props.onClick}
      onClick={props.onClick}
      mode="md"
      className={`${props.artist.blacklisted ? ' is-blacklisted' : ''}`}
    >
      <Avatar imgSrc={props.artist.img} />
      {!props.hideText && (
        <StyledIonLabel>
          <StyledArtistName>
            <Typography tag="h3" className="ion-text-nowrap">
              {props.artist.name}
            </Typography>
          </StyledArtistName>
        </StyledIonLabel>
      )}
    </StyledIonItem>
  );
};

export default ArtistCard;
