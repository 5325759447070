import styled from 'styled-components';

const floatingAnim = (name: string, x: number, y: number) => {
  return `
      @keyframes floating-${name} {
        0% {
          transform: translate(${x}px, ${y - 5}px);
        }
        100% {
          transform: translate(${x}px, ${y + 5}px);
        }
      }
      transform: translate(${x}px, ${y - 5}px);
      animation: floating-${name} 1.2s linear infinite alternate;`;
};

export const StyledSvgContainer = styled.div`
  color: var(--ion-color-secondary);

  & svg {
    height: 100%;
    width: 100%;
  }

  & #cloud1 {
    ${floatingAnim('cloud1', 173.443, 198.504)}
    animation-delay: 0.2s;
  }
  & #cloud2 {
    ${floatingAnim('cloud2', 195.074, 93.219)}
    animation-delay: 0.4s;
  }
  & #cloud3 {
    ${floatingAnim('cloud3', 26.705, 126.908)}
    animation-delay: 0.5s;
  }

  & #World_Map__Monochromatic1 {
    transform: translate(0px, 14.136px);
    @keyframes globe1 {
      0% {
        transform: translate(0px, 14.136px);
      }
      100% {
        transform: translate(320px, 14.136px);
      }
    }
    animation: globe1 10s linear infinite;
  }

  & #World_Map__Monochromatic2 {
    transform: translate(-320px, 14.136px);
    @keyframes globe2 {
      0% {
        transform: translate(-320px, 14.136px);
      }
      100% {
        transform: translate(0px, 14.136px);
      }
    }
    animation: globe2 10s linear infinite;
  }
`;
