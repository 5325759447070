import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const ItemContainer = styled.div`
  width: calc(100%);
  box-sizing: border-box;
  display: flex;
`;
