import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Toast from '../components/Toast';
import { KeyboardContext } from '../contexts/KeyboardContext';
import { RootState } from '../store/rootReducer';
import { setToast } from '../store/toastSlice';
import { useAppPages } from './hooks/useAppPages';
import { isPlatform } from '@ionic/react';

const ToastContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPageHasPlayerBar, setCurrentPageHasPlayerBar] = useState(false);
  const isTshokoConnected = useSelector((state: RootState) => state.PlayerReducer.params.isTshokoConnected);
  const playerOfflineMode = useSelector((state: RootState) => state.PlayerReducer.offlineMode);
  const toast = useSelector((state: RootState) => state.ToastReducer.toast);
  const { keyboardState } = useContext(KeyboardContext);
  const appPages = useAppPages();

  useEffect(() => {
    if (toast?.duration) {
      setTimeout(() => {
        dispatch(setToast(undefined));
      }, toast.duration);
    }
  }, [toast?.duration, dispatch]);

  useEffect(() => {
    const [, ...adminPagesWithPlayerBar] = appPages.playingAdmin;
    const pagesWithPlayerBar = [...adminPagesWithPlayerBar, ...appPages.playingUser];
    setCurrentPageHasPlayerBar(
      pagesWithPlayerBar.map((page) => page.href).some((href) => location.pathname.includes(href)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (isTshokoConnected && playerOfflineMode) {
      dispatch(
        setToast({
          isOpen: true,
          header: t('connectionLost'),
          message: t('waitingForConnection'),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerOfflineMode, isTshokoConnected]);

  return (
    <>
      <Toast
        isOpen={!!toast?.isOpen}
        header={toast?.header}
        message={toast?.message}
        style={{
          transform: `translate(0px, calc(${
            keyboardState?.state === 'open' || keyboardState?.state === 'opening'
              ? `-${(isPlatform('ios') && keyboardState?.keyboardHeight) || 0}px + var(--ion-safe-area-bottom, 0px)`
              : `-51px`
          } -  ${currentPageHasPlayerBar ? 72 : 0}px))`,
          backgroundColor: toast?.type === 'error' ? 'var(--ion-color-error)' : 'var(--ion-color-primary)',
          color: toast?.type === 'error' ? 'var(--ion-color-secondary-contrast)' : 'rgba(0, 0, 0, 0.87)',
          ...toast?.style,
        }}
      />
    </>
  );
};

export default ToastContainer;
