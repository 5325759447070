import { IonInput, IonLabel, IonSkeletonText } from '@ionic/react';
import React, { ReactNode } from 'react';
import { StyledIonItem, StyledIonInput, StyledInputContainer } from './TextField.styled';

type IonInputProps = React.ComponentProps<typeof IonInput>;
export type InputProps = IonInputProps & {
  label?: ReactNode;
  startIcon?: ReactNode;
  loading?: boolean;
};

const TextField = React.forwardRef<HTMLIonInputElement, InputProps>(({ ...props }, ref) => {
  return (
    <StyledInputContainer>
      <StyledIonItem mode="md">
        {props.loading ? (
          <>
            {props.label && (
              <IonLabel mode="md" position="stacked">
                <IonSkeletonText animated={true} style={{ height: 15, width: 150, marginTop: 0 }} />
              </IonLabel>
            )}
            <IonSkeletonText
              animated={true}
              style={{ height: 36, marginTop: props.label ? 8 : 14, marginBottom: 0, paddingTop: 8 }}
            />
          </>
        ) : (
          <>
            {props.label && (
              <IonLabel mode="md" position="stacked">
                {props.label}
              </IonLabel>
            )}
            <StyledIonInput className={'styledIonInput'} mode="md" {...props} ref={ref} label={undefined}>
              {props.startIcon && <span className="start-icon">{props.startIcon}</span>}
            </StyledIonInput>
          </>
        )}
      </StyledIonItem>
    </StyledInputContainer>
  );
});

export default TextField;
