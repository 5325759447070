import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../../components/AlertModal';
import { closeConfirmBlacklistTrackModale, closeTrackBottomSheet } from '../../../store/modaleSlice';
import { RootState } from '../../../store/rootReducer';
import { setToast } from '../../../store/toastSlice';
import SocketService from '../../../services/socket';
import { useUpdateBlacklistMutation } from '../../../services/api.service';
import store from '../../../store/store';

const ConfirmBlacklistTrack = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appState = useSelector((state: RootState) => state.AppReducer);

  const confirmBlacklistTrackModale = useSelector(
    (state: RootState) => state.ModaleReducer.confirmBlacklistTrackModale,
  );
  const confirmBlacklistTrackModaleIsOpen = useSelector(
    (state: RootState) => state.ModaleReducer.confirmBlacklistTrackModaleIsOpen,
  );
  const [updateBlacklist] = useUpdateBlacklistMutation();

  return (
    <AlertModal
      isOpen={confirmBlacklistTrackModaleIsOpen}
      onDidDismiss={() => {
        dispatch(closeConfirmBlacklistTrackModale());
        dispatch(closeTrackBottomSheet());
      }}
      header={t('blacklistTrackTitle')}
      subHeader={t('blacklistTrackDesc')}
      buttons={[
        {
          text: t('back'),
          role: 'cancel',
        },
        {
          text: t('confirm'),
          role: 'confirm',
          handler: () => {
            if (!confirmBlacklistTrackModale) {
              return;
            }
            SocketService.removeMusic(confirmBlacklistTrackModale.id);
            const state = store.getState();
            const { currentMusic } = state.PlayerReducer;
            if (currentMusic?.id === confirmBlacklistTrackModale.id) {
              SocketService.passMusic();
            }
            updateBlacklist({
              type: 'track',
              clientId: appState.boxDetail?._id || '',
              img: confirmBlacklistTrackModale.img,
              trackId: confirmBlacklistTrackModale.id,
              title: confirmBlacklistTrackModale.title,
              artist: confirmBlacklistTrackModale.artist.name,
            })
              .unwrap()
              .then(() => {
                SocketService.blacklistChanged();
                dispatch(
                  setToast({
                    isOpen: true,
                    message: t('musicBlacklisted'),
                    duration: 5000,
                  }),
                );
              })
              .catch(() => {
                dispatch(
                  setToast({
                    isOpen: true,
                    message: t('musicBlacklistedError'),
                    duration: 5000,
                  }),
                );
              });
          },
        },
      ]}
    />
  );
};

export default ConfirmBlacklistTrack;
