import { IonItem, IonLabel } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonItem = styled(IonItem)`
  --border-color: transparent;
  --background: none;
  --padding-start: 0;
  --inner-padding-end: 0;
  flex: 1;

  &::part(native) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 16px;
  }
`;

export const StyledInnerCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 4%;
  overflow: hidden;

  &:not(.loading) {
    background: rgba(var(--ion-color-dark-contrast-rgb), 1);
  }

  & ion-skeleton-text {
    height: 100%;
    width: 100%;
    border-radius: 0;
    margin: 0;
  }
`;

export const StyledPlaylistsContainer = styled.div`
  display: flex;
  height: 50%;
  & .playlist-thumbnail {
    &:not(.loading) {
      padding-left: inherit;
      width: 100%;
    }
  }
`;

export const StyledIonLabel = styled(IonLabel)`
  display: flex !important;
  flex: 1;
  width: 100%;
  padding-bottom: 0px;
  margin-bottom: 0px;
`;

export const StyledTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 7%;
  padding-left: 7%;

  &::before {
    content: '';
    position: absolute;
    top: 15%;
    left: 0;
    width: 2%;
    height: 70%;
    background: var(--ion-color-primary);
  }
`;

export const StyledIndependentPlaylistsTitle = styled.div`
  display: flex;
  align-items: center;
  & ion-text {
    overflow: hidden;
    & h3 {
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;

      & ion-skeleton-text {
        margin: 0;
        height: 19px !important;
      }
    }
  }
  & ion-icon {
    margin-top: 0;
  }
`;
