import { IonAlert } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonAlert = styled(IonAlert)`
  & ion-backdrop {
    background-color: var(--ion-backdrop-color, #000);
  }

  & .alert-title {
    &::first-letter {
      text-transform: capitalize;
    }
  }
  & .alert-title,
  & .alert-sub-title {
    &::first-letter {
      text-transform: capitalize;
    }
  }
`;
