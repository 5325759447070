import React from 'react';
import { IonContent } from '@ionic/react';
import InfiniteGrid from '../../../../components/InfiniteGrid';
import TrackItem, { TrackItemDefaultProps } from '../../../../components/TrackItem';
import useKeyboard from '../../../../utils/hooks/useKeyboard';

const SearchTabTracks = (props: {
  loading?: boolean;
  onLoadData?: () => void;
  tracks: TrackItemDefaultProps['track'][];
  onClickTrack: (track: TrackItemDefaultProps['track']) => void;
  onClickTrackVote?: (track: TrackItemDefaultProps['track']) => void;
  adminMode?: boolean;
}) => {
  const { hideKeyboard } = useKeyboard();
  return (
    <IonContent scrollEvents onIonScrollStart={() => hideKeyboard?.()}>
      <InfiniteGrid loadingElement={<TrackItem loading />} loading={props.loading} onScroll={props.onLoadData}>
        {props.tracks.map((track, index) => (
          <TrackItem
            key={`track-${index}`}
            track={track}
            onVoteClick={
              props.onClickTrackVote
                ? () => {
                    props.onClickTrackVote?.(track);
                  }
                : undefined
            }
            onClick={() => props.onClickTrack(track)}
          />
        ))}
      </InfiniteGrid>
    </IonContent>
  );
};

export default SearchTabTracks;
