import React from 'react';
import { IonLabel, IonThumbnail, IonIcon } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';
import { StyledIonItem, StyledPlaylistTitle, StyledPlaylistSubTitle } from './PlaylistItem.styled';
import Typography from '../Typography';
import { secondsToTimeString } from '../../utils/time';
import { PlaylistThumbnail } from '../PlaylistCard';

export type PlaylistItemDefaultProps = {
  playlist: {
    id: string;
    title: string;
    duration: number;
    tracks: {
      img: string;
    }[];
    ambiance?: {
      color: string;
    };
    independent?: boolean;
  };
  onClick?: () => void;
  isDownloaded?: boolean;
  loading?: boolean;
  isPlaying?: boolean;
  playingDate?: Date;
};

type PlaylistItemLoading = { loading: true };

export type PlaylistItemProps = PlaylistItemDefaultProps | PlaylistItemLoading;

const PlaylistItem = (props: PlaylistItemProps) => {
  return (
    <>
      {props.loading ? (
        <StyledIonItem className="playlist-item loading" mode="md">
          <IonThumbnail slot="start">
            <PlaylistThumbnail loading />
          </IonThumbnail>
          <IonLabel>
            <StyledPlaylistTitle>
              <Typography tag="h3" className="ion-text-nowrap" loading style={{ width: 150 }} />
            </StyledPlaylistTitle>
            <StyledPlaylistSubTitle>
              <Typography tag="h4" className="ion-text-nowrap" loading style={{ width: 100 }} />
            </StyledPlaylistSubTitle>
          </IonLabel>
          <div slot="end" style={{ width: 8 }} />
        </StyledIonItem>
      ) : (
        <StyledIonItem
          className={`playlist-item ${props.isPlaying ? 'is-playing' : ''}`}
          button={!!props.onClick}
          onClick={props.onClick}
          mode="md"
          style={props.playingDate ? { opacity: 0.5 } : {}}
        >
          <IonThumbnail slot="start">
            <PlaylistThumbnail
              tracks={props.playlist.tracks.slice(0, 4).map((t) => t.img)}
              color={props.playlist.ambiance?.color}
              independent={props.playlist.independent}
            />
          </IonThumbnail>
          <IonLabel>
            <StyledPlaylistTitle>
              <Typography tag="h3" className="ion-text-nowrap">
                {props.playlist.title}
              </Typography>
            </StyledPlaylistTitle>
            <StyledPlaylistSubTitle>
              <Typography tag="h4" className="ion-text-nowrap">
                {secondsToTimeString(props.playlist.duration)}
              </Typography>
            </StyledPlaylistSubTitle>
          </IonLabel>
          {!!props.onClick ? <IonIcon icon={chevronForward} slot="end" /> : <div slot="end" style={{ width: 8 }} />}
        </StyledIonItem>
      )}
    </>
  );
};

export default PlaylistItem;
