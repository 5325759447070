import { IonItem } from '@ionic/react';
import styled from 'styled-components';

export const StyledHeaderBoxSettings = styled.div`
  display: flex;

  & .info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .username,
    & .box-name {
      margin: 0;
      text-transform: capitalize;

      &.box-name {
        font-weight: 500;
      }
    }
  }

  & ion-avatar {
    width: 80px;
    height: 80px;

    margin-right: 12px;
  }
`;

export const StyledIonItem = styled(IonItem)`
  --background: none;
  --border-color: transparent;
  color: var(--text-color-secondary);

  & .menu-item {
    display: flex;
    width: 100%;
    height: 48px;
    justify-content: center;
    align-items: center;

    & ion-icon,
    ion-spinner {
      margin-inline-end: 12px;
      margin-top: 12px;
      margin-bottom: 12px;
      font-size: 24px;
    }
    & ion-label {
      width: 212px;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
`;
