import { IonLabel, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { useDispatch } from 'react-redux';
import { setPlayerBarIsOpen } from '../../store/modaleSlice';
import React, { ReactNode, useRef } from 'react';
import { StyledTabs } from './Tabs.styled';

type IonTabsProps = React.ComponentProps<typeof IonTabs>;
export type TabsProps = IonTabsProps & {
  tabs: { id: string; label: ReactNode; href: string; icon: ReactNode }[];
};

const Tabs = (props: TabsProps) => {
  const { children, ...rest } = props;
  const dispatch = useDispatch();
  const ionTabBarRef = useRef<HTMLIonTabBarElement>(null);

  return (
    <StyledTabs>
      <IonTabs
        {...rest}
        onIonTabsWillChange={() => {
          dispatch(setPlayerBarIsOpen(false));
        }}
      >
        {children as React.ReactNode}
        <IonTabBar slot="bottom" ref={ionTabBarRef}>
          {props.tabs.map((tab, index) => (
            <IonTabButton key={`tab-button-${index}`} tab={tab.id} href={tab.href} mode="md">
              {tab.icon}
              <IonLabel style={{ textTransform: 'capitalize' }}>{tab.label}</IonLabel>
            </IonTabButton>
          ))}
        </IonTabBar>
      </IonTabs>
    </StyledTabs>
  );
};

export default Tabs;
