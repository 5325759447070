import { useTrackBottomSheetLayoutProps } from '../../../layouts/TrackBottomSheetLayout';
import SocketService from '../../../services/socket';
import { useTranslation } from 'react-i18next';
import { getAllSearchedTracksSelector } from '../../Search/selector';
import { useVoteTrackHook } from '../../hooks/useVoteTrack';
import store from '../../../store/store';
import { setToast } from '../../../store/toastSlice';
import { useDispatch, useSelector } from 'react-redux';
import BottomSheet from '../../../components/BottomSheet';
import { useLazyTracksSearchQuery } from '../../../services/qobuz.service';
import { RootState } from '../../../store/rootReducer';
import { closeTrackBottomSheet, setConfirmBlacklistTrackModale } from '../../../store/modaleSlice';
import { setArtistToPush } from '../../../store/drawerSlice';
import useConnectionLostGuard from '../../hooks/useConnectionLostGuard';

const TrackBottomSheet = () => {
  const appState = useSelector((state: RootState) => state.AppReducer);
  const playerState = useSelector((state: RootState) => state.PlayerReducer);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const TrackBottomSheetLayoutPropsBuilder = useTrackBottomSheetLayoutProps();
  const [triggerTracksSearch] = useLazyTracksSearchQuery();
  const connectionLostGuard = useConnectionLostGuard();
  const voteTrack = useVoteTrackHook();
  const trackBottomSheetIsOpen = useSelector((state: RootState) => state.ModaleReducer.trackBottomSheetIsOpen);
  const trackBottomSheet = useSelector((state: RootState) => state.ModaleReducer.trackBottomSheet);
  const trackArtistId = trackBottomSheet?.artist.id;

  return trackBottomSheet ? (
    <BottomSheet
      {...{
        isOpen: trackBottomSheetIsOpen,
        onDidDismiss: () => dispatch(closeTrackBottomSheet()),
      }}
      {...TrackBottomSheetLayoutPropsBuilder({
        track: trackBottomSheet,
        actions: {
          onSeeArtistButton: !appState.boxDetail?.options.jukeboxrestricted
            ? async () => {
                const artistId =
                  trackArtistId ||
                  (await triggerTracksSearch({
                    limit: 1,
                    offset: 0,
                    query: trackBottomSheet.isrc || `${trackBottomSheet.id}`,
                  })
                    .unwrap()
                    .then((res) => res.tracks.items[0].album.artist.id));
                if (artistId) {
                  dispatch(setArtistToPush({ artistId }));
                  dispatch(closeTrackBottomSheet());
                }
              }
            : undefined,
          vote:
            trackBottomSheet.id && !appState.isAdminMode
              ? () => {
                  connectionLostGuard(() => {
                    dispatch(closeTrackBottomSheet());
                    voteTrack(trackBottomSheet);
                  });
                }
              : undefined,
          removeTrackFromWaitlistButton:
            trackBottomSheet.id && appState.isAdminMode && trackBottomSheet.fromWaitlist
              ? () => {
                  connectionLostGuard(async () => {
                    dispatch(closeTrackBottomSheet());
                    SocketService.removeMusic(trackBottomSheet.id);
                    dispatch(
                      setToast({
                        isOpen: true,
                        header: t('musicRemovedTitle'),
                        message: t('musicRemovedContent'),
                        duration: 5000,
                      }),
                    );
                  });
                }
              : undefined,
          playNow:
            trackBottomSheet.id && appState.isAdminMode
              ? () => {
                  connectionLostGuard(async () => {
                    const state = store.getState();
                    const allCombinedTracks = getAllSearchedTracksSelector(state);
                    const { waitlist, params } = state.PlayerReducer;
                    if (params.tshokoStatus === 'stop') {
                      SocketService.playNow();
                    }
                    dispatch(closeTrackBottomSheet());
                    if (!waitlist.map((waitlistTrack) => waitlistTrack.id).includes(trackBottomSheet.id)) {
                      const foundTrack =
                        allCombinedTracks.find((qobuzTrack) => qobuzTrack.id === trackBottomSheet.id) ||
                        (await triggerTracksSearch({
                          limit: 1,
                          offset: 0,
                          query: trackBottomSheet.isrc || `${trackBottomSheet.id}`,
                        })
                          .unwrap()
                          .then((res) => res.tracks.items[0]));
                      if (!foundTrack) {
                        console.warn('track not found', trackBottomSheet);
                        return;
                      }
                      SocketService.playNewMusic(foundTrack);
                    } else {
                      SocketService.playThisMusic(trackBottomSheet.id);
                    }
                    dispatch(closeTrackBottomSheet());
                  });
                }
              : undefined,
          appendToPriorityQueue:
            appState.isAdminMode && !playerState.params.isJukeboxModeOn
              ? async () => {
                  connectionLostGuard(async () => {
                    const state = store.getState();
                    const allCombinedTracks = getAllSearchedTracksSelector(state);
                    dispatch(closeTrackBottomSheet());
                    const foundTrack =
                      allCombinedTracks.find((qobuzTrack) => qobuzTrack.id === trackBottomSheet.id) ||
                      (await triggerTracksSearch({
                        limit: 1,
                        offset: 0,
                        query: trackBottomSheet.isrc || `${trackBottomSheet.id}`,
                      })
                        .unwrap()
                        .then((res) => res.tracks.items[0]));
                    if (!foundTrack) {
                      console.warn('track not found', trackBottomSheet);
                      return;
                    }
                    SocketService.appendToPriorityQueue(foundTrack);
                    dispatch(
                      setToast({
                        isOpen: true,
                        message: t('musicAdded'),
                        duration: 5000,
                      }),
                    );
                    dispatch(closeTrackBottomSheet());
                  });
                }
              : undefined,
          prependToPriorityQueue:
            appState.isAdminMode && !playerState.params.isJukeboxModeOn
              ? () => {
                  return connectionLostGuard(async () => {
                    const state = store.getState();
                    const allCombinedTracks = getAllSearchedTracksSelector(state);
                    dispatch(closeTrackBottomSheet());
                    const foundTrack =
                      allCombinedTracks.find((qobuzTrack) => qobuzTrack.id === trackBottomSheet.id) ||
                      (await triggerTracksSearch({
                        limit: 1,
                        offset: 0,
                        query: trackBottomSheet.isrc || `${trackBottomSheet.id}`,
                      })
                        .unwrap()
                        .then((res) => res.tracks.items[0]));
                    if (!foundTrack) {
                      console.warn('track not found', trackBottomSheet);
                      return;
                    }
                    SocketService.prependToPriorityQueue(foundTrack);
                    dispatch(
                      setToast({
                        isOpen: true,
                        message: t('musicAdded'),
                        duration: 5000,
                      }),
                    );
                    dispatch(closeTrackBottomSheet());
                  });
                }
              : undefined,
          blacklistTitle: appState.isAdminMode
            ? () => {
                dispatch(setConfirmBlacklistTrackModale(trackBottomSheet));
              }
            : undefined,
          addToPlaylist: !!appState.isAdminMode,
        },
      })}
    />
  ) : null;
};

export default TrackBottomSheet;
