import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { ItemContainer, StyledContainer } from './InfiniteGrid.styled';

type InfiniteGridProps = {
  children?: ReactNode[];
  loadingElement?: ReactNode;
  onScroll?: (e?: Event) => void;
  loading?: boolean;
  elementWidth?: string;
};

const InfiniteGrid = (props: InfiniteGridProps) => {
  const [firstItemRect, setFirstItemRect] = useState<DOMRect>();
  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const firstItemRef = useRef<HTMLDivElement>(null);

  if (!props.loading) {
    infiniteScrollRef.current?.complete();
  }

  useEffect(() => {
    if (
      props.onScroll &&
      !props.loading &&
      props.children &&
      !!firstItemRect?.height &&
      props.children.length <= Math.ceil(window.innerHeight / firstItemRect.height)
    ) {
      props.onScroll();
    }
  }, [props, firstItemRect]);

  const childrenPerLineCount =
    containerRef.current && containerRef.current?.getBoundingClientRect().width > 0 && !!firstItemRect?.width
      ? Math.ceil(containerRef.current?.getBoundingClientRect().width / firstItemRect.width)
      : 0;
  const lineCount = !!firstItemRect?.height ? Math.floor(window.innerHeight / firstItemRect.height) : 0;
  const nbLoadingElements =
    (lineCount * childrenPerLineCount) / (props.children && props.children?.length > 0 ? lineCount : 1);
  const lastRowEmptyElementsCount =
    props.loading &&
    childrenPerLineCount - ((props.children?.length || 0) % (childrenPerLineCount || 1)) < childrenPerLineCount
      ? childrenPerLineCount - ((props.children?.length || 0) % (childrenPerLineCount || 1))
      : 0;
  const fillScreenRowEmptyElementsCount =
    props.loading && (props.children?.length || 0) < lineCount * childrenPerLineCount
      ? lineCount * childrenPerLineCount -
        ((props.children?.length || 0) + lastRowEmptyElementsCount + nbLoadingElements)
      : 0;

  useEffect(() => {
    if (!firstItemRect?.height) {
      setFirstItemRect(firstItemRef.current?.getBoundingClientRect());
    }
  }, [firstItemRect]);

  return (
    <StyledContainer ref={containerRef}>
      {(props.children && props.children?.length > 0
        ? props.children
        : Array.from({ length: nbLoadingElements || 1 }).map(() => props.loadingElement)
      ).map((c, i) => (
        <ItemContainer
          style={{ width: props.elementWidth }}
          {...(i === 0 ? { ref: firstItemRef } : {})}
          key={`ItemContainer-${i}`}
        >
          {c}
        </ItemContainer>
      ))}
      {Array.from({ length: lastRowEmptyElementsCount + fillScreenRowEmptyElementsCount }).map((c, i) => (
        <ItemContainer style={{ width: props.elementWidth }} key={`ItemContainer-empty-${i}`}>
          {props.loadingElement}
        </ItemContainer>
      ))}
      <IonInfiniteScroll
        threshold="20%"
        ref={infiniteScrollRef}
        onIonInfinite={props.loading ? undefined : props.onScroll}
      >
        {(props.onScroll || props.loading) && (
          <>
            {nbLoadingElements > 0 && props.loadingElement ? (
              <StyledContainer>
                {Array.from({ length: nbLoadingElements }).map((c, i) => (
                  <ItemContainer style={{ width: props.elementWidth }} key={`ItemContainer-${i}`}>
                    {props.loadingElement}
                  </ItemContainer>
                ))}
              </StyledContainer>
            ) : (
              <IonInfiniteScrollContent loadingSpinner="lines" />
            )}
          </>
        )}
      </IonInfiniteScroll>
    </StyledContainer>
  );
};

export default InfiniteGrid;
