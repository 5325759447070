import React from 'react';
import { Keyboard } from '@capacitor/keyboard';
import { IonContent } from '@ionic/react';
import InfiniteGrid from '../../../../components/InfiniteGrid';
import AlbumCard, { AlbumCardDefaultProps } from '../../../../components/AlbumCard';
import AlbumDrawer from '../../../../features/Drawer/AlbumDrawer';
import { Capacitor } from '@capacitor/core';
import { StyledIonNavLink } from '../../../../features/Drawer/Drawer.styled';

const SearchTabAlbums = (props: {
  loading?: boolean;
  onLoadData?: () => void;
  albums: AlbumCardDefaultProps['album'][];
}) => {
  return (
    <IonContent scrollEvents onIonScrollStart={() => Capacitor.getPlatform() !== 'web' && Keyboard.hide()}>
      <InfiniteGrid
        loadingElement={<AlbumCard loading />}
        loading={props.loading}
        onScroll={props.onLoadData}
        elementWidth={`calc(100% / ${Math.floor(window.innerWidth / (320 / 2))})`}
      >
        {props.albums.map((album, index) => (
          <StyledIonNavLink
            key={`album-${album.id}-${index}`}
            routerDirection="forward"
            component={() => <AlbumDrawer albumId={album.id} />}
          >
            <AlbumCard album={album} onClick={() => {}} />
          </StyledIonNavLink>
        ))}
      </InfiniteGrid>
    </IonContent>
  );
};

export default SearchTabAlbums;
