import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledPageContent,
  StyledContentContainer,
  StyledSection,
  StyledSectionContent,
  StyledSectionText,
} from './NoProgLayout.styled';
import { HeaderProps } from '../../components/Header';
import Typography from '../../components/Typography';
import AmbianceCard, { AmbianceCardDefaultProps } from '../../components/AmbianceCard';
import PlaylistCard, { PlaylistCardDefaultProps } from '../../components/PlaylistCard';
import { formatDistance } from 'date-fns';
import { fr } from 'date-fns/locale';
import Button from '../../components/Button';

export type NoProgLayoutProps = { renderHeader: ReactElement<HeaderProps> } & (
  | {
      nextMusics: PlaylistCardDefaultProps['playlist'] | AmbianceCardDefaultProps['ambiance'];
      nextOpeningDate: Date;
      status: 'ready';
      onClickPlayNow: (nextMusics: PlaylistCardDefaultProps['playlist'] | AmbianceCardDefaultProps['ambiance']) => void;
    }
  | {
      status: 'loading';
    }
  | {
      status: 'empty';
    }
);

const NoProgLayout = (props: NoProgLayoutProps) => {
  const { t } = useTranslation();

  if (props.status === 'loading')
    return (
      <StyledPageContent>
        {props.renderHeader}
        <StyledContentContainer className="ion-padding">
          <div className="content">
            <Typography tag="p" variant="secondary">
              {t('noProgDescText1')}
            </Typography>
            <StyledSection>
              <StyledSectionText>
                <Typography tag="p" variant="primary">
                  {t('nextMusics')}
                </Typography>
                <Typography tag="p" variant="secondary" loading lines={1} />
              </StyledSectionText>
              <StyledSectionContent>
                <AmbianceCard loading />
              </StyledSectionContent>
            </StyledSection>
            <Button loading>{t('playNow')}</Button>
            <Typography tag="p" variant="secondary">
              {t('noProgDescText2')}
            </Typography>
          </div>
        </StyledContentContainer>
      </StyledPageContent>
    );

  if (props.status === 'empty')
    return (
      <StyledPageContent>
        {props.renderHeader}
        <StyledContentContainer className="ion-padding">
          <div className="content">
            <Typography tag="p" variant="secondary">
              {t('noProgProgEmptyMessage')}
            </Typography>
          </div>
        </StyledContentContainer>
      </StyledPageContent>
    );

  return (
    <StyledPageContent>
      {props.renderHeader}
      <StyledContentContainer className="ion-padding">
        <div className="content">
          <Typography tag="p" variant="secondary">
            {t('noProgDescText1')}
          </Typography>
          <StyledSection>
            <StyledSectionText>
              <Typography tag="p" variant="primary">
                {t('nextMusics')}
              </Typography>
              <Typography tag="p" variant="secondary">
                {formatDistance(props.nextOpeningDate, Date.now(), {
                  addSuffix: true,
                  includeSeconds: false,
                  locale: fr,
                })}
              </Typography>
            </StyledSectionText>
            <StyledSectionContent>
              {'tracks' in props.nextMusics ? (
                <PlaylistCard playlist={props.nextMusics} />
              ) : (
                <AmbianceCard ambiance={props.nextMusics} />
              )}
            </StyledSectionContent>
          </StyledSection>
          <Button onClick={() => props.onClickPlayNow(props.nextMusics)}>{t('playNow')}</Button>
          <Typography tag="p" variant="secondary">
            {t('noProgDescText2')}
          </Typography>
        </div>
      </StyledContentContainer>
    </StyledPageContent>
  );
};

export default NoProgLayout;
