import { IonRippleEffect } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonContainer, ButtonContent, TextContainer } from './BackToProgButton.styled';
import BackToProgIcon from '../Icons/BackToProgIcon';
import Typography from '../Typography';

type BackToProgButtonProps = {
  label?: string;
  onClick?: () => void;
  loading?: boolean;
};

const BackToProgButton = (props: BackToProgButtonProps) => {
  const { t } = useTranslation();
  return (
    <ButtonContainer button={!!props.onClick} detailIcon={undefined} onClick={props.onClick}>
      {props.onClick && <IonRippleEffect />}
      <ButtonContent>
        <Typography tag="h4" variant="secondary" loading={props.loading}>
          {props.loading ? (
            props.label || t('backToProg')
          ) : (
            <TextContainer>
              {props.label || t('backToProg')}
              {!props.loading && <BackToProgIcon />}
            </TextContainer>
          )}
        </Typography>
      </ButtonContent>
    </ButtonContainer>
  );
};

export default BackToProgButton;
