import { useDispatch } from 'react-redux';
import { setInfoConnectionLostModaleIsOpen } from '../../store/modaleSlice';
import store from '../../store/store';

const useConnectionLostGuard = () => {
  const dispatch = useDispatch();
  const connectionLostGuard = (callBack?: () => void) => {
    const playerState = store.getState().PlayerReducer;
    if (playerState.params.isTshokoConnected && playerState.offlineMode) {
      dispatch(setInfoConnectionLostModaleIsOpen(true));
    } else {
      callBack?.();
    }
  };
  return connectionLostGuard;
};

export default useConnectionLostGuard;
