import styled from 'styled-components';
import ProgressBar from '../ProgressBar';

export const StyledProgressBar = styled(ProgressBar)`
  --inner-padding-end: 16px;
  position: absolute;
  width: calc(100% - var(--ion-safe-area-right, 0px) - var(--inner-padding-end) - var(--inner-padding-start));
  left: var(--inner-padding-start);
  bottom: 8px;

  &::part(track) {
    background: var(--text-color-secondary);
  }
`;

export const PlayerTime = styled.div`
  color: var(--text-color-secondary);
  line-height: 15px;
  text-align: center;

  &.current {
    color: var(--ion-color-primary);
  }
`;

export const PlayerTimeContainer = styled.div`
  position: absolute;
  top: 8px;
  display: flex;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  justify-content: space-between;
`;
