import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
  padding-top: calc(var(--ion-safe-area-top) - 16px);
  margin-top: 16px;
  padding-inline-start: calc(var(--ion-safe-area-left, 0px) + 16px);
  padding-inline-end: calc(var(--ion-safe-area-right, 0px) + 16px);
  padding-bottom: 0px;

  & h1 {
    margin-top: 8px;
  }

  & h2 {
    margin-top: 8px;
  }

  & ion-button {
    width: 48px;
  }
  & .title-header {
    display: flex;
    justify-content: space-between;

    & .title {
      display: inline-flex;
      width: calc(100% - 48px);
    }
  }
`;

export const StyledPageContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & ion-content {
    --padding-bottom: 72px;
  }
`;

export const SearchBarContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & .header-logo {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    transition: all 0.2s;
  }

  & ion-list-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    & .logo {
      height: 56px;
      max-width: 210px;
      margin-right: 8px;
    }

    & ion-label {
      margin-top: 8px;
    }
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 72px;

  & .page-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    & .title {
      text-align: center;
    }

    & .subtitle {
      text-align: center;
      margin: 0;
    }

    & desc {
      text-align: center;
    }
  }
`;
