import { useDispatch, useSelector } from 'react-redux';
import BottomSheet from '../../../components/BottomSheet';
import { useAlbumBottomSheetLayoutProps } from '../../../layouts/AlbumBottomSheetLayout';
import useConnectionLostGuard from '../../hooks/useConnectionLostGuard';
import { RootState } from '../../../store/rootReducer';
import {
  closeAlbumBottomSheet,
  setConfirmBlacklistAlbumModale,
  setConfirmPlayAlbumModale,
} from '../../../store/modaleSlice';
import ArtistDrawer from '../../Drawer/ArtistDrawer';

const AlbumBottomSheet = () => {
  const appState = useSelector((state: RootState) => state.AppReducer);

  const dispatch = useDispatch();
  const AlbumBottomSheetLayoutPropsBuilder = useAlbumBottomSheetLayoutProps();
  const connectionLostGuard = useConnectionLostGuard();
  const albumBottomSheetIsOpen = useSelector((state: RootState) => state.ModaleReducer.albumBottomSheetIsOpen);
  const albumBottomSheet = useSelector((state: RootState) => state.ModaleReducer.albumBottomSheet);

  return albumBottomSheet ? (
    <BottomSheet
      {...{
        isOpen: albumBottomSheetIsOpen,
        onDidDismiss: () => dispatch(closeAlbumBottomSheet()),
      }}
      {...AlbumBottomSheetLayoutPropsBuilder({
        album: albumBottomSheet,
        actions: {
          onSeeArtistButton: {
            artistId: albumBottomSheet.artist.id,
            callback: () => {
              dispatch(closeAlbumBottomSheet());
            },
            artistDrawerComponent: (props) => <ArtistDrawer artistId={props.artistId} />,
          },
          playLater: appState.isAdminMode
            ? () => {
                connectionLostGuard(() => {
                  dispatch(closeAlbumBottomSheet());
                  dispatch(setConfirmPlayAlbumModale({ trackIds: albumBottomSheet.tracks.map((tr) => tr.id) }));
                });
              }
            : undefined,
          blacklistAlbum: appState.isAdminMode
            ? () => {
                dispatch(closeAlbumBottomSheet());
                dispatch(setConfirmBlacklistAlbumModale(albumBottomSheet));
              }
            : undefined,
        },
      })}
    />
  ) : null;
};

export default AlbumBottomSheet;
