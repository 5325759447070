import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable @typescript-eslint/no-unused-vars */

type DrawerState = {
  artistDrawerToPush?: { artistId: number };
};

const initialDrawerSlice: DrawerState = {
  artistDrawerToPush: undefined,
};

const DrawerSlice = createSlice({
  name: 'DrawerSlice',
  initialState: initialDrawerSlice,
  reducers: {
    setArtistToPush(state: DrawerState, action: PayloadAction<{ artistId: number } | undefined>) {
      state.artistDrawerToPush = action.payload;
    },
  },
});

const DrawerReducer = DrawerSlice.reducer;

export const { setArtistToPush } = DrawerSlice.actions;
export default DrawerReducer;
