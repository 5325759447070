export const isHexColor = (color: string) => /^#([0-9A-F]{6})$/i.test(color);

type ColorFormat = 'rgb' | 'rgba' | 'hsl' | 'hsla';
type ColorValues = [number, number, number] | [number, number, number, number];
export type ColorObject = {
  type: ColorFormat;
  values: ColorValues;
};

export const hexToRgb = (color: string) => {
  color = color.substring(1);
  const re = new RegExp('.{1,'.concat(`${color.length >= 6 ? 2 : 1}`, '}'), 'g');
  let colors = color.match(re);

  if (colors && colors[0].length === 1) {
    colors = colors.map((n) => {
      return n + n;
    }) as RegExpMatchArray;
  }

  return colors
    ? 'rgb'.concat(colors.length === 4 ? 'a' : '', '(').concat(
        colors
          .map((n, index) => {
            return index < 3 ? parseInt(n, 16) : Math.round((parseInt(n, 16) / 255) * 1000) / 1000;
          })
          .join(', '),
        ')',
      )
    : '';
};

export const decomposeColor = (color: string | ColorObject): ColorObject => {
  // Idempotent
  if (typeof color !== 'string') {
    return color;
  }

  if (color.charAt(0) === '#') {
    return decomposeColor(hexToRgb(color));
  }

  const marker = color.indexOf('(');
  const type = color.substring(0, marker);

  if (['rgb', 'rgba', 'hsl', 'hsla'].indexOf(type) === -1) {
    throw new Error();
  }

  let values: ColorValues = color.substring(marker + 1, color.length - 1).split(',') as unknown as ColorValues;
  values = values.map((value) => parseFloat(`${value}`)) as unknown as ColorValues;
  return {
    type: type as ColorFormat,
    values: values as ColorValues,
  };
};

export const intToHex = (int: number) => {
  const hex = int.toString(16);
  return hex.length === 1 ? '0'.concat(hex) : hex;
};

export const rgbToHex = (color: string | ColorObject) => {
  // Idempotent
  if (typeof color === 'string' && color.indexOf('#') === 0) {
    return color;
  }

  const dc = decomposeColor(color),
    values = dc.values;

  return '#'.concat(
    values
      .map((n) => {
        return intToHex(n);
      })
      .join(''),
  );
};
