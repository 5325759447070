import { useEffect } from 'react';

const useInterval = (callback: () => void, delay: number | null) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(callback, delay);
      return () => clearInterval(id);
    }
  }, [delay, callback]);
};

export default useInterval;
