import { IonItem } from '@ionic/react';
import styled from 'styled-components';

export const ButtonContainer = styled(IonItem)`
  --background: none;
  --border-color: transparent;
  --padding-start: 0px;
  --inner-padding-end: 0px;

  display: inline-block;
  color: var(--text-color-secondary) !important;

  &::part(detail-icon) {
    display: none;
  }
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;

  & .button-content {
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  & h5 {
    margin: 0;
  }
`;

export const StyledButtonChange = styled.div`
  & .swap {
    display: flex;
    align-items: center;
  }

  & .icon-swap {
    display: flex;
    margin-right: 4px;
  }
`;
