import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PAGINATION_SIZE } from '../features/Search/selector';
import { Without } from '../utils/types';

type PaginatedItem<Type> = {
  limit: number;
  offset: number;
  total: number;
  items: Type[];
};

type MostPopularItem =
  | { type: 'albums'; content: QobuzAlbumDto }
  | { type: 'artists'; content: QobuzArtistDto }
  | { type: 'tracks'; content: QobuzTrackDto };

export type QobuzArtistDto = {
  picture: string | null;
  image: {
    small: string;
    medium: string;
    large: string;
  } | null;
  name: string;
  slug: string;
  albums_count: number;
  id: number;
};
export type QobuzTopTrackDto = {
  id: number;
  audio_info: {
    replaygain_track_peak: number;
    replaygain_track_gain: number;
  };
  composer: {
    name: string;
    id: number;
  };
  version?: null | string;
  work?: null | string;
  artist: { id: number; name: { display: string } };
  album: {
    id: string;
    image: {
      small: string;
      thumbnail: string;
      large: string;
    };
    genre: {
      id: number;
      name: string;
      path: number[];
    };
    title: string;
    version: string;
  };
  isrc: string;
  title: string;
  duration: number;
  parental_warning: boolean;
  rights: {
    streamable: boolean;
    hires_streamable: boolean;
    hires_purchasable: boolean;
    purchasable: boolean;
    downloadable: boolean;
    previewable: boolean;
    sampleable: boolean;
  };
};

export type QobuzTrackDto = {
  audio_info: {
    replaygain_track_peak: number;
    replaygain_track_gain: number;
  };
  performer?: {
    name: string;
    id: number;
  };
  composer: {
    name: string;
    id: number;
  };
  version?: null | string;
  work?: null | string;
  album: QobuzAlbumDto;
  isrc: string;
  title: string;
  duration: number;
  parental_warning: boolean;
  id: number;
  release_date_original: string;
  release_date_download: string;
  release_date_stream: string;
  streamable: boolean;
  streamable_at: number;
  previewable: boolean;
  sampleable: boolean;
  downloadable: boolean;
  displayable: boolean;
};

export type AlbumDetailedDto = QobuzAlbumDto & {
  tracks: { items: Without<QobuzTrackDto, 'album'>[] };
};

export type QobuzAlbumDto = {
  image: {
    small: string;
    thumbnail: string;
    large: string;
  };
  artist: {
    name: string;
    id: number;
    albums_count: number;
  };
  released_at: number;
  title: string;
  qobuz_id: number;
  duration: number;
  parental_warning: boolean;
  tracks_count: number;
  genre: {
    path: number[];
    color: string;
    name: string;
    id: number;
    slug: string;
  };
  id: string;
  release_date_original: string;
  release_date_download: string;
  release_date_stream: string;
  streamable: boolean;
  streamable_at: number;
  previewable: boolean;
  sampleable: boolean;
  downloadable: boolean;
  displayable: boolean;
};

export type QobuzArtistAlbumDto = {
  id: string;
  artist: { id: number; name: { display: string } };
  duration: number;
  genre: {
    id: number;
    name: string;
    path: number[];
  };
  image: {
    small: string;
    thumbnail: string;
    large: string;
  };
  parental_warning: boolean;
  title: string;
  tracks_count: number;
  version: string;
  rights: {
    purchasable: boolean;
    streamable: boolean;
    downloadable: boolean;
    hires_streamable: boolean;
    hires_purchasable: boolean;
  };
};

export type ArtistAlbumsDto = {
  has_more: boolean;
  items: QobuzArtistAlbumDto[];
};

export type ArtistDetailedDto = {
  id: number;
  images: { portrait: { hash: string; format: string } | null };
  name: { display: string };
  biography: {
    content: string;
    language: string;
  };
  top_tracks: QobuzTopTrackDto[];
  similar_artists: {
    items: {
      id: number;
      name: {
        display: string;
      };
      images: {
        portrait: {
          hash: string;
          format: string;
        } | null;
      };
    }[];
  };
};

export type GlobalSearchResult = {
  query: string;
  albums: PaginatedItem<QobuzAlbumDto>;
  tracks: PaginatedItem<QobuzTrackDto>;
  artists: PaginatedItem<QobuzArtistDto>;
  most_popular: PaginatedItem<MostPopularItem>;
};

export type AlbumsSearchResult = {
  query: string;
  albums: PaginatedItem<QobuzAlbumDto>;
};

export type ArtistsSearchResult = {
  query: string;
  artists: PaginatedItem<QobuzArtistDto>;
};

export type TracksSearchResult = {
  query: string;
  tracks: PaginatedItem<QobuzTrackDto>;
};

export const qobuz = createApi({
  reducerPath: 'qobuz',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_QOBUZ_API_BASE,
    prepareHeaders: (headers) => {
      headers.set('X-App-Id', `950096963`);
      headers.set(
        'X-User-Auth-Token',
        `I1vREF2ff5U2e8rkOGHJUPQ67hKt01Wgvj2uNjYX8KLWez-19No_yZxlG5W7bpIMiY-9tw8-82LWC_m17y-hGg`,
      );
      return headers;
    },
  }),
  keepUnusedDataFor: 60 * 60, // 1 hour cache
  endpoints: (builder) => ({
    globalSearch: builder.query<GlobalSearchResult, { query: string }>({
      query: (params) => ({ url: '/catalog/search', params: { ...params, limit: PAGINATION_SIZE, offset: 0 } }),
    }),
    artistsSearch: builder.query<ArtistsSearchResult, { query: string; limit: number; offset: number }>({
      query: (params) => ({ url: '/artist/search', params: { ...params } }),
    }),
    tracksSearch: builder.query<TracksSearchResult, { query: string; limit: number; offset: number }>({
      query: (params) => ({ url: '/track/search', params: { ...params } }),
    }),
    albumsSearch: builder.query<AlbumsSearchResult, { query: string; limit: number; offset: number }>({
      query: (params) => ({ url: '/album/search', params: { ...params } }),
    }),
    getAlbum: builder.query<AlbumDetailedDto, { album_id: string }>({
      query: (params) => ({ url: '/album/get', params: params }),
    }),
    artistWithAlbums: builder.query<ArtistAlbumsDto, { artist_id: string; limit: number; offset: number }>({
      query: (params) => ({
        url: 'artist/getReleasesGrid',
        params: {
          ...params,
          release_type: 'album',
          sort: 'release_date',
        },
      }),
    }),
    artistDetailed: builder.query<ArtistDetailedDto, { artist_id: string; limit: number; offset: number }>({
      query: (params) => ({
        url: 'artist/page',
        params: {
          ...params,
          sort: 'release_date',
        },
      }),
    }),
    getTrack: builder.query<QobuzTrackDto, { track_id: string }>({
      query: (params) => ({ url: '/track/get', params }),
    }),
  }),
});

export const {
  useGlobalSearchQuery,
  useGetAlbumQuery,
  useLazyArtistWithAlbumsQuery,
  useLazyArtistDetailedQuery,
  useLazyGlobalSearchQuery,
  useLazyAlbumsSearchQuery,
  useLazyArtistsSearchQuery,
  useLazyTracksSearchQuery,
  useGetTrackQuery,
} = qobuz;
