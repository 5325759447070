import styled from 'styled-components';
import { IonItem, IonLabel, IonText } from '@ionic/react';

export const StyledIonItem = styled(IonItem)`
  &::part(native) {
    background: rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    font-size: 13px;
    padding-left: 7px;
  }
`;

export const StyledIonLabel = styled(IonLabel)`
  padding-left: 15px;
  margin: 5px;
`;

export const StyledIonText = styled(IonText)`
  font-weight: 600;
  font-size: 12px;

  display: block;
  width: 100%;
  max-width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.h4 {
    color: var(--text-color-secondary);
    font-weight: 500;
    font-size: 10px;
  }
`;
