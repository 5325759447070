import React from 'react';
import { IonContent, IonNavLink } from '@ionic/react';
import {
  StyledIonContent,
  StyledPageContent,
  StyledHeaderContainer,
  StyledAmbianceTitleContainer,
  StyledLeftHeader,
  StyledIonHeader,
} from './AmbianceLayout.styled';
import Typography from '../../../components/Typography';
import IconButton from '../../../components/IconButton';
import { chevronBack } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import PlaylistItem, { PlaylistItemDefaultProps } from '../../../components/PlaylistItem';
import InfiniteGrid from '../../../components/InfiniteGrid';
import PlayButton from '../../../components/PlayButton';

export type AmbianceLayoutDefaultProps = {
  loading?: boolean;
  ambiance: {
    id: string;
    img: string;
    name: string;
    color: string;
    soundDesignName: string;
  };
  playlistsCount: number;
  playlists: PlaylistItemDefaultProps['playlist'][];
  isPlaylistsFetching?: boolean;
  loadDataPlaylists?: () => void;
  onPlayClick?: () => void;
  onPlaylistClickDrawerComponent?: (props: { playlistId: string }) => JSX.Element;
};

type AmbianceLayoutLoadingProps = {
  loading: true;
  ambiance?: {
    color?: string;
  };
};

export type AmbianceLayoutProps = AmbianceLayoutDefaultProps | AmbianceLayoutLoadingProps;

const AmbianceLayout = (props: AmbianceLayoutProps) => {
  const { t } = useTranslation();
  const onPlaylistClickDrawerComponent =
    'onPlaylistClickDrawerComponent' in props && props.onPlaylistClickDrawerComponent;
  return (
    <StyledIonContent bgcolor={props.ambiance?.color}>
      <StyledPageContent>
        <StyledHeaderContainer>
          <StyledIonHeader>
            <StyledLeftHeader>
              <IonNavLink routerDirection="back">
                <IconButton icon={chevronBack} size="large" />
              </IonNavLink>
              <StyledAmbianceTitleContainer>
                {props.loading ? (
                  <Typography tag="h2" loading />
                ) : (
                  <Typography tag="h2">{props.ambiance.name}</Typography>
                )}
                {props.loading ? (
                  <Typography tag="h5" variant="secondary" loading />
                ) : (
                  <Typography tag="h5" variant="secondary">
                    {`${props.playlistsCount} ${t('playlists', { count: props.playlistsCount })} • ${
                      props.ambiance.soundDesignName
                    }`}
                  </Typography>
                )}
              </StyledAmbianceTitleContainer>
            </StyledLeftHeader>
            <IonNavLink routerDirection="root">
              <PlayButton onClick={props.loading ? undefined : props.onPlayClick} loading={props.loading} />
            </IonNavLink>
          </StyledIonHeader>
        </StyledHeaderContainer>
        <IonContent>
          <InfiniteGrid
            loadingElement={<PlaylistItem loading />}
            loading={props.loading || props.isPlaylistsFetching}
            onScroll={props.loading ? undefined : props.loadDataPlaylists}
          >
            {props.loading
              ? undefined
              : props.playlists.map((playlist, index) =>
                  onPlaylistClickDrawerComponent ? (
                    <IonNavLink
                      style={{ width: '100%' }}
                      key={`playlist-${playlist.id}-${index}`}
                      routerDirection="forward"
                      component={() => onPlaylistClickDrawerComponent({ playlistId: playlist.id })}
                    >
                      <PlaylistItem playlist={playlist} onClick={() => {}} />
                    </IonNavLink>
                  ) : (
                    <PlaylistItem playlist={playlist} onClick={() => {}} />
                  ),
                )}
          </InfiniteGrid>
        </IonContent>
      </StyledPageContent>
    </StyledIonContent>
  );
};

export default AmbianceLayout;
