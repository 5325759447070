import React from 'react';
import { IonLabel, IonThumbnail, IonIcon, IonSkeletonText } from '@ionic/react';
import { ellipsisVertical } from 'ionicons/icons';
import {
  StyledIonItem,
  StyledSpotInfoContainer,
  StyledSpotTitleContainer,
  StyledSpotTitle,
  StyledEqualizerBars,
  StyledTimeContainer,
  StyledSpotSubTitle,
  StyledSpotLabelontainer,
} from './SpotListItem.styled';
import { fr } from 'date-fns/locale';
import { addSeconds, format, formatDistanceToNow } from 'date-fns';
import Typography from '../Typography';
import { Spot } from '../../store/playerSlice';
import SpotIcon from '../Icons/SpotIcon';
import { spotImageSourceToCdnUrl } from '../../utils/file';

export type SpotListItemDefaultProps = {
  spot: Spot;
  onClick?: () => void;
  loading?: boolean;
  isPlaying?: boolean;
  playingDate?: Date;
};

type SpotListItemLoading = { loading: true };

export type SpotListItemProps = SpotListItemDefaultProps | SpotListItemLoading;

const EqualizerBars = () => (
  <StyledEqualizerBars>
    <div className="equalizer-bar" />
    <div className="equalizer-bar" />
    <div className="equalizer-bar" />
  </StyledEqualizerBars>
);

const SpotListItem = (props: SpotListItemProps) => {
  return (
    <>
      {props.loading ? (
        <StyledIonItem className="spot-item loading" mode="md">
          <IonThumbnail className="spot-thumbnail" slot="start">
            <IonSkeletonText animated={true} style={{ borderRadius: 4 }} />
          </IonThumbnail>
          <IonLabel>
            <StyledSpotTitle>
              <Typography tag="h3" className="ion-text-nowrap" loading style={{ width: 150 }} />
            </StyledSpotTitle>
            <StyledSpotSubTitle>
              <Typography tag="h4" className="ion-text-nowrap" loading style={{ width: 100 }} />
            </StyledSpotSubTitle>
          </IonLabel>
          <div slot="end" style={{ width: 8 }} />
        </StyledIonItem>
      ) : (
        <StyledIonItem
          className={`spot-item${props.isPlaying ? ' is-playing' : ''}${props.playingDate ? ' is-past' : ''}`}
          button={!!props.onClick}
          onClick={props.onClick}
          mode="md"
        >
          <IonThumbnail className="spot-thumbnail" slot="start">
            {props.spot.img ? (
              <img src={spotImageSourceToCdnUrl(props.spot.img)} style={{ borderRadius: 4 }} />
            ) : (
              <SpotIcon />
            )}
            {props.isPlaying && <EqualizerBars />}
          </IonThumbnail>
          <IonLabel>
            <StyledSpotInfoContainer>
              <StyledSpotLabelontainer>
                <StyledSpotTitleContainer>
                  <StyledSpotTitle>
                    <Typography tag="h3" className="ion-text-nowrap">
                      {props.spot.title}
                    </Typography>
                  </StyledSpotTitle>
                  <StyledSpotSubTitle>
                    {
                      <Typography tag="h4" className="ion-text-nowrap">
                        {format(addSeconds(new Date(0), props.spot.duration / 1000 || 30), 'mm:ss')}
                      </Typography>
                    }
                    {props.playingDate && (
                      <StyledTimeContainer>
                        <Typography className="ion-text-nowrap">
                          {formatDistanceToNow(props.playingDate, {
                            addSuffix: true,
                            includeSeconds: false,
                            locale: fr,
                          })}
                        </Typography>
                      </StyledTimeContainer>
                    )}
                  </StyledSpotSubTitle>
                </StyledSpotTitleContainer>
              </StyledSpotLabelontainer>
            </StyledSpotInfoContainer>
          </IonLabel>
          {!!props.onClick ? <IonIcon icon={ellipsisVertical} slot="end" /> : <div slot="end" style={{ width: 8 }} />}
        </StyledIonItem>
      )}
    </>
  );
};

export default SpotListItem;
