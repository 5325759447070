import styled from 'styled-components';
import { IonItem } from '@ionic/react';

export const StyledIonItem = styled(IonItem)`
  &.track-item {
    --background: none;
    --inner-border-width: 0;
    --border-color: transparent;
    scroll-snap-stop: normal;
    scroll-snap-align: start;
    flex: 1;

    &.loading {
      & ion-label {
        & ion-skeleton-text {
          height: 13px !important;
        }
      }
    }

    &.is-playing {
      &:before {
        pointer-events: none;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        background: rgba(var(--ion-color-primary-rgb), 0.12);
      }

      & .track-thumbnail {
        position: relative;
        &:before {
          pointer-events: none;
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
          border-radius: 4px;
          background: rgba(var(--ion-color-dark-contrast-rgb), 0.54);
        }
      }
    }

    &.is-past {
      opacity: 0.5;
    }
    &.is-blacklisted {
      filter: grayscale(1);
      opacity: 0.5;
    }

    & .track-thumbnail img {
      border-radius: 4px;
    }
  }
  & .sc-ion-label-md-s {
    margin-bottom: 0;
    padding-bottom: 11px;
    border-bottom: 1px solid var(--text-color-secondary);

    & h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }
    & h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      color: var(--text-color-secondary);
    }
  }
`;

export const StyledTrackSubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & ion-text {
    overflow: hidden;
  }
`;

export const StyledTimeContainer = styled.span`
  display: flex;
  align-items: center;
  font-size: 10px !important;
`;

export const StyledTrackNumber = styled.div`
  display: inline-flex;
  align-items: center;
  text-align: right;
  margin-right: 10px;

  & h3 {
    width: 32px;
    color: var(--text-color-secondary);
  }
`;

export const DownloadIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledTrackInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTrackBlacklistedTag = styled.div`
  background-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.12);
  color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
  font-size: 10px;
  border-radius: 4px;
  height: 14px;
  padding: 0px 4px;,
`;

export const StyledTrackLabelContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const StyledTrackTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledTrackTitle = styled.div`
  display: flex;
  align-items: center;
  & ion-text {
    overflow: hidden;
  }
`;

export const StyledEqualizerBars = styled.div`
  @keyframes equalize {
    0% {
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: translate3d(0, 80%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes equalize-2 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: translate3d(0, 80%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  font-size: 0;
  width: 18px;
  height: 18px;
  overflow: hidden;
  margin: 20px;
  position: absolute;
  top: 0;
  z-index: 3;

  .equalizer-bar {
    display: inline-block;
    width: 4px;
    height: 100%;
    background: var(--ion-text-color);
    margin-right: 2px;

    &:nth-child(1) {
      height: 50%;
      animation: equalize 1200ms ease infinite;
    }

    &:nth-child(2) {
      animation: equalize 1000ms linear infinite;
    }

    &:nth-child(3) {
      height: 70%;
      animation: equalize 1000ms 400ms ease infinite;
    }
  }
`;
