import React from 'react';
import styled from 'styled-components';
import Spinner from '../../components/Spinner';

const StyledSpinnerContainer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LargeSpinner = styled(Spinner)`
  height: 5rem;
  width: 5rem;
`;

const LoadingScreenLayout = () => {
  return (
    <StyledSpinnerContainer>
      <LargeSpinner />
    </StyledSpinnerContainer>
  );
};

export default LoadingScreenLayout;
