import NoProgLayout from '../layouts/NoProgLayout';
import JukeboxOffline from '../layouts/JukeboxOfflineLayout';
import { useGetBoxLiveQuery } from '../services/api.service';
import { addDays, addHours, addMinutes, setMinutes } from 'date-fns';
import { setHours } from 'date-fns/esm';
import SocketService from '../services/socket';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HeaderWithSwitchBox } from './components/HeaderWithSwitchBox';
import { RootState } from '../store/rootReducer';

const NoProg = () => {
  const { t } = useTranslation();
  const appState = useSelector((state: RootState) => state.AppReducer);
  const boxId = appState.boxDetail?._id;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { isFetching, data } = useGetBoxLiveQuery(boxId!, { skip: !boxId });

  const nextSlot = data?.nextSlot;

  if (!appState.isAdminMode)
    return <JukeboxOffline renderHeader={() => <HeaderWithSwitchBox subtitle={t('jukeboxOffline')} />} />;

  if (isFetching || !boxId)
    return (
      <NoProgLayout
        status="loading"
        renderHeader={<HeaderWithSwitchBox loading title={appState.boxDetail?.name || ''} />}
      />
    );

  if (!nextSlot) {
    return (
      <NoProgLayout status="empty" renderHeader={<HeaderWithSwitchBox title={appState.boxDetail?.name || ''} />} />
    );
  }

  return (
    <NoProgLayout
      status="ready"
      renderHeader={<HeaderWithSwitchBox title={appState.boxDetail?.name || ''} subtitle={t('noProg')} />}
      nextMusics={
        'playlist' in nextSlot
          ? {
              color: '#fff',
              duration: nextSlot.playlist.duration,
              id: nextSlot.playlist.id,
              tracks: nextSlot.playlist.musicsImagesSummary.map((i) => ({ img: i })),
              title: nextSlot.playlist.name,
              independent: nextSlot.playlist.independent,
            }
          : {
              color: nextSlot.ambiance?.color,
              id: nextSlot.ambiance.id,
              img: nextSlot.ambiance.image,
              name: nextSlot.ambiance.name,
              soundDesignName: '',
            }
      }
      nextOpeningDate={(() => {
        const today = new Date();
        const slotDayUSformat = (nextSlot.day + 1) % 7;
        const slotDayToDate = addDays(
          new Date(),
          slotDayUSformat < today.getDay() ? slotDayUSformat - today.getDay() + 7 : slotDayUSformat - today.getDay(),
        );
        const nextOpeningDate = addMinutes(
          addHours(setMinutes(setHours(slotDayToDate, 0), 0), Math.floor(nextSlot.start)),
          (nextSlot.start - Math.floor(nextSlot.start)) * 60,
        );
        return nextOpeningDate;
      })()}
      onClickPlayNow={() => {
        SocketService.playNow();
      }}
    />
  );
};

export default NoProg;
