import styled from 'styled-components';

export const StyledSeeMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const StyledSeeMoreContent = styled.div`
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
  padding-right: 5px;

  &.ellipsis {
    &::after {
      content: '...';
      position: absolute;
      bottom: 0;
      right: 5px;
      text-overflow: ellipsis;
    }
  }
`;

export const StyledButtonSeeMore = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  margin-right: -16px;

  & ion-icon {
    font-size: 32px;
    margin-left: 8px;
  }
`;
