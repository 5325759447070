import { IonHeader } from '@ionic/react';
import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
  padding-top: calc(var(--ion-safe-area-top) - 16px);
  margin-top: 16px;
  padding-inline-start: calc(var(--ion-safe-area-left, 0px) + 16px);
  padding-inline-end: calc(var(--ion-safe-area-right, 0px) + 16px);
  padding-bottom: 0px;
`;

export const StyledIonHeader = styled(IonHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledLeftHeader = styled.div`
  display: flex;
  margin-left: 4px;
`;

export const StyledRightHeader = styled.div`
  display: flex;
  align-items: center;
  margin-right: -12px;
  height: 48px;
`;

export const StyledArtistTitleContainer = styled.div`
  padding: 0px 16px;

  & .artistcard-container {
    width: 50%;
    margin: auto;

    & .item::part(native) {
      padding: 0;
    }
  }
  & .artist-name {
    margin-top: 0;
    text-align: center;

    & ion-skeleton-text {
      margin: auto;
    }
  }
`;

export const StyledPageContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & .artist-bio {
    padding: 0 16px;

    & p {
      margin-top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
    & br {
      display: none;
    }
  }
`;
