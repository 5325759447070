import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../../components/AlertModal';
import { setConfirmJukeboxModaleIsOpen } from '../../../store/modaleSlice';
import { RootState } from '../../../store/rootReducer';
import SocketService from '../../../services/socket';

const ConfirmJukebox = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const confirmJukeboxModaleIsOpen = useSelector((state: RootState) => state.ModaleReducer.confirmJukeboxModaleIsOpen);
  const playerParams = useSelector((state: RootState) => state.PlayerReducer.params);
  return (
    <AlertModal
      isOpen={confirmJukeboxModaleIsOpen}
      onDidDismiss={() => {
        dispatch(setConfirmJukeboxModaleIsOpen(false));
      }}
      header={t(playerParams.isJukeboxModeOn ? 'disableJukebox' : 'enableJukebox')}
      subHeader={t(playerParams.isJukeboxModeOn ? 'disableJukeboxDesc' : 'enableJukeboxDesc')}
      buttons={[
        {
          text: t('back'),
          role: 'cancel',
        },
        {
          text: t(playerParams.isJukeboxModeOn ? 'disable' : 'enable'),
          role: 'confirm',
          handler: () => {
            SocketService.setJukeboxModeOn(!playerParams.isJukeboxModeOn);
          },
        },
      ]}
    />
  );
};

export default ConfirmJukebox;
