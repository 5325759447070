import styled from 'styled-components';
import { IonItem, IonLabel } from '@ionic/react';
import { useContext } from 'react';
import { KeyboardContext } from '../../contexts/KeyboardContext';
import { isPlatform } from '@ionic/react';

const marqueeAnim = (name: string, widths: { scrollWidth: number; clientWidth: number }) => {
  return `
      @keyframes marquee-playerBar-${name}-${Date.now()} {
        0% {
          transform: translate(0, 0);
        }
        10% {
          transform: translate(0, 0);
        }
        90% {
          transform: translate(-${
            widths.scrollWidth - widths.clientWidth > 0 ? widths.scrollWidth - widths.clientWidth : 0
          }px, 0);
        }
        100% {
          transform: translate(-${
            widths.scrollWidth - widths.clientWidth > 0 ? widths.scrollWidth - widths.clientWidth : 0
          }px, 0);
        }
      }
      animation: marquee-playerBar-${name}-${Date.now()} ${widths.scrollWidth * 0.025}s linear infinite alternate;
      &:hover {
        animation-play-state: paused;
      }`;
};

export const StyledIonItem = styled(IonItem)`
  &.player-item {
    --inner-padding-end: 0;
    --background: rgba(var(--ion-color-dark-contrast-rgb), 0.54);
    --background-focused: rgba(var(--ion-color-dark-contrast-rgb), 0.54);
    --border-color: transparent;
    backdrop-filter: blur(5px);

    &.is-playing {
      &:before {
        pointer-events: none;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        background: rgba(var(--ion-color-primary-rgb), 0.12);
      }

      & .player-thumbnail {
        position: relative;
        &:before {
          pointer-events: none;
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
          border-radius: 4px;
          background: rgba(var(--ion-color-dark-contrast-rgb), 0.54);
        }
      }
    }

    & .player-thumbnail {
      border-radius: 4px;
      background: var(--text-color-secondary);

      img {
        border-radius: 4px;
      }

      & .spot {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        ion-icon {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
`;

export const StyledCurrentTitle = styled.div`
  display: flex;
  align-items: center;
  & .title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    margin-bottom: 0;

    &.overflown div {
      ${(props: { widths: { scrollWidth: number; clientWidth: number } }) => marqueeAnim('title', props.widths)}
    }
  }
`;

export const StyledCurrentArtist = styled.div`
  display: flex;
  align-items: center;
  & .artist {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    color: var(--text-color-secondary);

    &.overflown div {
      ${(props: { widths: { scrollWidth: number; clientWidth: number } }) => marqueeAnim('artist', props.widths)}
    }
  }
`;

export const StyledPlayerBarContainer = styled(({ ...props }) => {
  const { keyboardState } = useContext(KeyboardContext);

  return (
    <div
      {...props}
      style={{
        transform: `translate(0px, calc(${
          keyboardState?.state === 'open' || keyboardState?.state === 'opening'
            ? `-${(isPlatform('ios') && keyboardState?.keyboardHeight) || 0}px + var(--ion-safe-area-bottom, 0px)`
            : `-51px`
        }))`,
      }}
    />
  );
})`
  position: fixed;
  bottom: calc(0px + var(--ion-safe-area-bottom, 0px));
  cursor: pointer;
  z-index: 1;
  width: 100%;
  transition: transform 0.25s;
`;

export const StyledIonLabel = styled(IonLabel)`
  --inner-padding-end: 16px;
  max-width: calc(100% - var(--ion-safe-area-right, 0px) - var(--inner-padding-end));
  margin: 0;
  margin-bottom: 8px;
`;
