import React, { useEffect } from 'react';
import { Route, Switch, RouteComponentProps, useHistory } from 'react-router-dom';
import LoginLayout from '../layouts/LoginLayout';
import { IonPage } from '@ionic/react';
import { useGetJWTMutation } from '../services/auth.service';
import { useTranslation } from 'react-i18next';
import { userIsLogged } from '../utils/security';
import { getSubdomain, isAdminApp } from '../utils/platform';
import { useGetBoxWithDomainQuery } from '../services/api.service';

const Login = ({ match }: RouteComponentProps<Record<string, string>>) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [loginAction, { isLoading: loginLoading, isSuccess: loginSuccess, error: loginError }] = useGetJWTMutation();
  const { data: clientWithDom } = useGetBoxWithDomainQuery(getSubdomain(), {});

  useEffect(() => {
    if (loginSuccess || userIsLogged()) {
      history.push('/app');
      return;
    }
  }, [history, loginSuccess]);

  return (
    <IonPage>
      <Switch>
        <Route
          exact
          path={`${match.path}`}
          component={() => (
            <LoginLayout
              onClick={(username, password) =>
                loginAction({
                  username,
                  password,
                  boxId: isAdminApp()
                    ? localStorage.getItem('lastStreamId') || undefined
                    : (clientWithDom?.success && clientWithDom.result._id) || undefined,
                })
              }
              error={loginError ? t('loginError') : undefined}
              spinning={loginLoading}
            />
          )}
        />
      </Switch>
    </IonPage>
  );
};

export default Login;
