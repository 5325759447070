import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../../components/AlertModal';
import { setBoxSettingsBottomSheetIsOpen, setShutdownBoxModaleIsOpen } from '../../../store/modaleSlice';
import { RootState } from '../../../store/rootReducer';
import SocketService from '../../../services/socket';

const ConfirmBackToProg = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const confirmShutdownBoxModaleIsOpen = useSelector(
    (state: RootState) => state.ModaleReducer.confirmShutdownBoxModaleIsOpen,
  );
  return (
    <AlertModal
      isOpen={confirmShutdownBoxModaleIsOpen}
      onDidDismiss={() => {
        dispatch(setShutdownBoxModaleIsOpen(false));
      }}
      header={t('shutdownBoxAlertTitle')}
      subHeader={t('shutdownBoxAlertDesc')}
      buttons={[
        {
          text: t('cancel'),
          role: 'cancel',
        },
        {
          text: t('shutdown'),
          role: 'confirm',
          handler: () => {
            SocketService.shutdown();
            dispatch(setBoxSettingsBottomSheetIsOpen(false));
          },
        },
      ]}
    />
  );
};

export default ConfirmBackToProg;
