import React, { useEffect, useState } from 'react';
import { AlbumCardDefaultProps } from '../../components/AlbumCard';
import ArtistAlbumsLayout from '../../layouts/Search/ArtistAlbumsLayout';
import { useGetBlacklistsQuery } from '../../services/api.service';
import { useLazyArtistWithAlbumsQuery } from '../../services/qobuz.service';
import { PAGINATION_SIZE } from '../Search/selector';
import { buildAlbumCardFromQobuzArtistAlbumDto } from '../Search/types';
import AlbumDrawer from './AlbumDrawer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

type ArtistAlbumsDrawerType = {
  artistId: number;
  artistName: string;
};

const ArtistAlbumsDrawer = (props: ArtistAlbumsDrawerType) => {
  const appState = useSelector((state: RootState) => state.AppReducer);

  const [fetchArtistWithAlbums, { data, isFetching, isLoading }] = useLazyArtistWithAlbumsQuery();
  const { data: aggregatedBlacklists } = useGetBlacklistsQuery(appState.boxDetail?._id || '');
  const initialArtistAlbumsState = {
    artistId: '',
    albums: [] as AlbumCardDefaultProps['album'][],
  };
  const [artistAlbumsState, setArtistAlbumsState] = useState(initialArtistAlbumsState);

  useEffect(() => {
    fetchArtistWithAlbums(
      {
        artist_id: `${props.artistId}`,
        limit: PAGINATION_SIZE,
        offset: 0,
      },
      true,
    )
      .unwrap()
      .then((artistDetailedWithAlbumsDto) => {
        setArtistAlbumsState(() => ({
          artistId: `${props.artistId}`,
          albums: artistDetailedWithAlbumsDto.items.map((albumDto) =>
            buildAlbumCardFromQobuzArtistAlbumDto(albumDto, aggregatedBlacklists),
          ),
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.artistId]);

  useEffect(() => {
    if (data) {
      setArtistAlbumsState((prevArtistAlbumsState) => ({
        ...prevArtistAlbumsState,
        albums: [
          ...prevArtistAlbumsState.albums,
          ...data.items.map((albumDto) => buildAlbumCardFromQobuzArtistAlbumDto(albumDto, aggregatedBlacklists)),
        ],
      }));
    }
  }, [aggregatedBlacklists, data]);

  return data && !isLoading ? (
    <ArtistAlbumsLayout
      artist={{ id: props.artistId, name: props.artistName }}
      albums={artistAlbumsState.albums}
      isAlbumsFetching={isFetching}
      onAlbumClickDrawerComponent={(p) => <AlbumDrawer albumId={p.albumId} />}
      loadDataAlbums={
        data.has_more
          ? () => {
              if (!isFetching) {
                fetchArtistWithAlbums(
                  {
                    artist_id: `${props.artistId}`,
                    limit: PAGINATION_SIZE,
                    offset: artistAlbumsState.albums.length,
                  },
                  true,
                );
              }
            }
          : undefined
      }
    />
  ) : (
    <ArtistAlbumsLayout loading />
  );
};

export default ArtistAlbumsDrawer;
