import React, { useEffect } from 'react';
import ArtistLayout, { ArtistLayoutProps } from '../../layouts/Search/ArtistLayout';
import { useGetBlacklistsQuery } from '../../services/api.service';
import { useLazyArtistDetailedQuery, useLazyArtistWithAlbumsQuery } from '../../services/qobuz.service';
import { buildArtistDetailedFromDto } from '../Search/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { useVoteTrackHook } from '../hooks/useVoteTrack';
import { setArtistBottomSheet, setTrackBottomSheet } from '../../store/modaleSlice';
import AlbumDrawer from './AlbumDrawer';
import ArtistAlbumsDrawer from './ArtistAlbumsDrawer';
import ArtistTracksDrawer from './ArtistTracksDrawer';

const MemoArtistLayout = React.memo(
  (p: ArtistLayoutProps) => <ArtistLayout {...p} />,
  (pp, np) => {
    if (!pp.loading && !np.loading) {
      return pp.artist.id !== np.artist.id;
    }
    if (!pp.loading && np.loading) {
      return true;
    }
    return false;
  },
);

type ArtistDrawerProps = { artistId: number };

const ArtistDrawer = (props: ArtistDrawerProps) => {
  const appState = useSelector((state: RootState) => state.AppReducer);

  const dispatch = useDispatch();
  const [fetchArtistWithAlbums, { data: artistWithAlbumsData, isFetching: artistWithAlbumsIsFetching }] =
    useLazyArtistWithAlbumsQuery();
  const [fetchDetailedArtist, { data: detailedArtist, isFetching: detailedArtistFetching }] =
    useLazyArtistDetailedQuery();
  const { data: aggregatedBlacklists } = useGetBlacklistsQuery(appState.boxDetail?._id || '');
  const votesList = useSelector((state: RootState) => state.PlayerReducer.votesList);
  const voteTrack = useVoteTrackHook();
  const isJukeboxModeOn = useSelector((state: RootState) => state.PlayerReducer.params.isJukeboxModeOn);

  const isFetching = artistWithAlbumsIsFetching || detailedArtistFetching;

  useEffect(() => {
    fetchArtistWithAlbums({ artist_id: `${props.artistId}`, limit: 5, offset: 0 });
    fetchDetailedArtist({ artist_id: `${props.artistId}`, limit: 30, offset: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.artistId]);

  const artistDetailed =
    artistWithAlbumsData && detailedArtist
      ? buildArtistDetailedFromDto(artistWithAlbumsData, detailedArtist, aggregatedBlacklists, votesList)
      : undefined;

  return artistDetailed && !isFetching ? (
    <MemoArtistLayout
      adminMode={appState.isAdminMode}
      artist={artistDetailed}
      isAlbumsFetching={false}
      loadDataAlbums={undefined}
      onClickTrack={(track) => {
        dispatch(setTrackBottomSheet(track));
      }}
      onClickMore={(artist) => dispatch(setArtistBottomSheet(artist))}
      onClickTrackVote={
        isJukeboxModeOn
          ? (track) => {
              voteTrack(track);
            }
          : undefined
      }
      onArtistClickDrawerComponent={(p) => <ArtistDrawer artistId={p.artistId} />}
      onAlbumClickDrawerComponent={(p) => <AlbumDrawer albumId={p.albumId} />}
      onArtistAlbumsClickDrawerComponent={(p) => (
        <ArtistAlbumsDrawer artistId={p.artistId} artistName={artistDetailed.name} />
      )}
      onArtistTracksClickDrawerComponent={(p) => <ArtistTracksDrawer artistId={p.artistId} />}
    />
  ) : (
    <MemoArtistLayout loading />
  );
};

export default ArtistDrawer;
