import React, { useRef } from 'react';
import { useLazyGetAllUserPlaylistsQuery, usePatchTrackToPlaylistMutation } from '../../services/api.service';
import { useTranslation } from 'react-i18next';
import { IonIcon, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { StyledIonItem } from '../../layouts/TrackBottomSheetLayout/TrackBottomSheetLayout.styled';
import { add } from 'ionicons/icons';
import { Track } from '../../store/playerSlice';
import { useGetTrackQuery } from '../../services/qobuz.service';
import { setToast } from '../../store/toastSlice';
import { useDispatch } from 'react-redux';
import { closeTrackBottomSheet } from '../../store/modaleSlice';
import { trackDtoFromQobuzDto } from '../../utils/adapters';

export type AddToPlaylistProps = {
  track: Track;
};

const AddToPlaylist = (props: AddToPlaylistProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const playlistSelectRef = useRef<HTMLIonSelectElement>(null);
  const [triggerAllUserPlaylist, { data: playlistsData, isError: playlistError }] = useLazyGetAllUserPlaylistsQuery();
  const { data: trackData, isError: trackNotFound } = useGetTrackQuery({ track_id: props.track.id.toString() });
  const [triggerPatchPlaylist, { isError: patchTrackToPlaylistError }] = usePatchTrackToPlaylistMutation();

  if (trackNotFound || playlistError || patchTrackToPlaylistError) {
    dispatch(closeTrackBottomSheet());
    dispatch(
      setToast({
        isOpen: true,
        message: t('errorOccuredTryAgain'),
        type: 'error',
        duration: 5000,
      }),
    );
    return <></>;
  }

  return (
    <>
      <StyledIonItem
        button
        mode="md"
        onClick={async () => {
          await triggerAllUserPlaylist();
          playlistSelectRef.current?.open();
        }}
      >
        <div className="menu-item">
          <div className="item-icon">
            <IonIcon icon={add} />
          </div>
          <IonLabel>{t('addToPlaylist')}</IonLabel>
        </div>
      </StyledIonItem>
      <IonSelect
        style={{ display: 'none' }}
        label={t('addToPlaylist')}
        mode="ios"
        ref={playlistSelectRef}
        okText={t('add')}
        cancelText={t('cancel')}
        onIonChange={async (e) => {
          if (!trackData) return;

          triggerPatchPlaylist({
            playlistIds: [e.detail.value],
            tracks: [trackDtoFromQobuzDto(trackData)],
          });

          dispatch(closeTrackBottomSheet());
          dispatch(
            setToast({
              isOpen: true,
              header: t('musicAdded'),
              message: t('musicAddedContent'),
              duration: 5000,
            }),
          );
        }}
      >
        {playlistsData?.map((playlist) => (
          <IonSelectOption value={playlist.id} key={`select-item-playlist-${playlist.id}`}>
            {playlist.name}
          </IonSelectOption>
        ))}
      </IonSelect>
    </>
  );
};

export default AddToPlaylist;
