import React from 'react';
import { IonSkeletonText } from '@ionic/react';
import Typography from '../Typography';
import {
  StyledIonItem,
  StyledIonImg,
  StyledIonLabel,
  StyledPlaylistTitle,
  StyledPlaylistDuration,
  StyledIonThumbnail,
} from './PlaylistCard.styled';
import { secondsToTimeString } from '../../utils/time';
import { Milliseconds } from '../../utils/types';

export type PlaylistCardDefaultProps = {
  playlist: {
    id: string;
    title: string;
    duration?: Milliseconds;
    tracks: {
      img?: string;
    }[];
    ambiance?: {
      color: string;
    };
    independent?: boolean;
  };
  onClick?: () => void;
  loading?: boolean;
  hideText?: boolean;
};

type PlaylistCardLoading = { loading: true; hideText?: boolean };

export type PlaylistCardProps = PlaylistCardDefaultProps | PlaylistCardLoading;

export const PlaylistThumbnail = (
  props:
    | { loading?: boolean; color?: string; independent?: boolean; tracks: (string | undefined)[] }
    | { loading: true },
) => {
  return props.loading ? (
    <StyledIonThumbnail className="playlist-thumbnail loading">
      <IonSkeletonText animated={true} />
    </StyledIonThumbnail>
  ) : (
    <StyledIonThumbnail
      className="playlist-thumbnail"
      style={{ color: props.color || (props.independent ? 'var(--ion-color-primary)' : 'var(--text-color-primary)') }}
    >
      <>
        {props.tracks[0] && <StyledIonImg src={props.tracks[0]} />}
        {props.tracks[1] && <StyledIonImg src={props.tracks[1]} />}
        {props.tracks[2] && <StyledIonImg src={props.tracks[2]} />}
        {props.tracks[3] && <StyledIonImg src={props.tracks[3]} />}
      </>
    </StyledIonThumbnail>
  );
};

const PlaylistCard = (props: PlaylistCardProps) => {
  return props.loading ? (
    <StyledIonItem mode="md">
      <PlaylistThumbnail loading />
      {!props.hideText && (
        <StyledIonLabel>
          <StyledPlaylistTitle>
            <Typography tag="h3" className="ion-text-nowrap" loading style={{ width: 150 }} />
          </StyledPlaylistTitle>
          <StyledPlaylistDuration>
            <Typography tag="h4" className="ion-text-nowrap" loading style={{ width: 100 }} variant="secondary" />
          </StyledPlaylistDuration>
        </StyledIonLabel>
      )}
    </StyledIonItem>
  ) : (
    <StyledIonItem button={!!props.onClick} onClick={props.onClick} mode="md">
      <PlaylistThumbnail
        tracks={props.playlist.tracks.slice(0, 4).map((t) => t.img)}
        color={props.playlist.ambiance?.color}
        independent={props.playlist.independent}
      />
      {!props.hideText && (
        <StyledIonLabel>
          <StyledPlaylistTitle>
            <Typography tag="h3" className="ion-text-nowrap">
              {props.playlist.title}
            </Typography>
          </StyledPlaylistTitle>
          <StyledPlaylistDuration>
            {props.playlist.duration && (
              <Typography tag="h4" className="ion-text-nowrap" variant="secondary">
                {secondsToTimeString(props.playlist.duration / 1000)}
              </Typography>
            )}
          </StyledPlaylistDuration>
        </StyledIonLabel>
      )}
    </StyledIonItem>
  );
};

export default PlaylistCard;
