import { volumeHigh, volumeLow, volumeMedium, volumeMute } from 'ionicons/icons';
import Typography from '../../components/Typography';
import {
  StyledIconButton,
  StyledIonRange,
  StyledTypography,
  StyledVolumeHandlerContainer,
} from './VolumeHandlerLayout.styled';
import { useEffect, useState } from 'react';

export type VolumeHandlerLayoutProps = {
  volumeValue: number;
  title?: string;
  onChange: (volumeValue: number) => void;
  onMuteChange: (action: string) => void;
};

const VolumeHandlerLayout = (props: VolumeHandlerLayoutProps) => {
  const [displayedVolumeValue, setDisplayedVolumeValue] = useState(props.volumeValue > 0 ? props.volumeValue : 0);
  const [volumeChangeActive, setVolumeChangeActive] = useState(false);

  useEffect(() => {
    setDisplayedVolumeValue(props.volumeValue);
  }, [props.volumeValue]);

  return (
    <>
      {props.title ? <Typography tag="h4">{props.title}</Typography> : null}
      <StyledVolumeHandlerContainer>
        <StyledIconButton
          className={`volume-mute-button ${volumeChangeActive ? 'range-active' : ''}`}
          icon={
            !displayedVolumeValue
              ? volumeMute
              : displayedVolumeValue < 33
              ? volumeLow
              : displayedVolumeValue < 66
              ? volumeMedium
              : volumeHigh
          }
          onClick={() => (props.volumeValue > 0 ? props.onMuteChange('mute') : props.onMuteChange('unmute'))}
        />
        <StyledTypography
          variant="secondary"
          className={`volume-text ${volumeChangeActive ? 'range-active' : ''}`}
          style={{ textAlign: displayedVolumeValue !== 100 ? 'center' : 'right' }}
        >
          {displayedVolumeValue}
        </StyledTypography>
        <StyledIonRange
          aria-label="volume"
          mode="md"
          ticks={true}
          snaps={true}
          min={0}
          max={100}
          step={5}
          value={displayedVolumeValue}
          onIonKnobMoveStart={() => setVolumeChangeActive(true)}
          onIonKnobMoveEnd={(e) => {
            props.onChange(e.target.value as number);
            setVolumeChangeActive(false);
          }}
          className={`${volumeChangeActive ? 'range-active' : ''}`}
          onIonInput={(e) => setDisplayedVolumeValue(e.target.value as number)}
        />
      </StyledVolumeHandlerContainer>
    </>
  );
};

export default VolumeHandlerLayout;
