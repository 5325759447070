import React, { ReactElement, useEffect } from 'react';
import List from '../../components/List';
import TrackItem from '../../components/TrackItem';
import SpotListItem from '../../components/SpotListItem/SpotListItem';
import { StyledIonContent } from './WaitingListLayout.styled';
import {
  Track,
  CurrentTrack,
  FutureTrack,
  PastTrack,
  PastSpot,
  CurrentSpot,
  FutureSpot,
} from '../../store/playerSlice';
import { HeaderProps } from '../../components/Header';
import { isSpot } from '../../utils/typeGuards';

export type WaitingListLayoutProps = {
  renderHeader: () => ReactElement<HeaderProps>;
  pastItems: (PastTrack | PastSpot)[];
  currentItem: (CurrentTrack | CurrentSpot) | undefined;
  futureItems: (FutureTrack | FutureSpot)[];
  loading?: boolean;
  admin?: boolean;
  onPastTrackClick?: (pastTrack: PastTrack) => void;
  onCurrentTrackClick?: (currentTrack: CurrentTrack) => void;
  onFutureTrackClick?: (futureTrack: FutureTrack) => void;
  onVoteClick?: (track: Track) => void;
};

const WaitingListLayout = (props: WaitingListLayoutProps) => {
  const ionContentRef = React.useRef<HTMLIonContentElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (ionContentRef.current && props.pastItems.length) {
        ionContentRef.current.scrollToPoint(0, 71 * props.pastItems.length, 0);
      }
    });
  }, [props.pastItems]);
  return (
    <>
      {props.renderHeader()}
      <StyledIonContent ref={ionContentRef}>
        <List>
          {props.loading ? (
            Array.from({ length: 11 }).map((v, i) => <TrackItem key={`loading-track-${i}`} loading />)
          ) : (
            <>
              {props.pastItems.map((pastItem, i) =>
                isSpot(pastItem) ? (
                  <SpotListItem
                    key={`past-spot-${pastItem.id}-${i}`}
                    playingDate={new Date(pastItem.playingDate)}
                    spot={pastItem}
                  />
                ) : (
                  <TrackItem
                    key={`past-track-${pastItem.id}-${i}`}
                    playingDate={new Date(pastItem.playingDate)}
                    track={pastItem}
                    onClick={() => props.onPastTrackClick?.(pastItem)}
                    onVoteClick={props.onVoteClick ? () => props.onVoteClick?.(pastItem) : undefined}
                  />
                ),
              )}
              {props.currentItem && (
                <>
                  {isSpot(props.currentItem) ? (
                    <SpotListItem
                      key={`current-spot-${props.currentItem.id}`}
                      isPlaying
                      playingDate={new Date(props.currentItem.playingDate)}
                      spot={props.currentItem}
                    />
                  ) : (
                    <TrackItem
                      key={`current-track-${props.currentItem.id}`}
                      voteReadonly
                      isPlaying
                      track={props.currentItem}
                      onClick={() =>
                        props.currentItem ? props.onCurrentTrackClick?.(props.currentItem as CurrentTrack) : {}
                      }
                    />
                  )}
                </>
              )}
              {props.futureItems.map((futureItem, i) =>
                isSpot(futureItem) ? (
                  <SpotListItem key={`future-spot-${futureItem.id}-${i}`} spot={futureItem} />
                ) : (
                  <TrackItem
                    key={`future-track-${futureItem.id}-${i}`}
                    onVoteClick={props.onVoteClick ? () => props.onVoteClick?.(futureItem) : undefined}
                    track={futureItem}
                    onClick={() => props.onFutureTrackClick?.(futureItem)}
                  />
                ),
              )}
            </>
          )}
        </List>
      </StyledIonContent>
    </>
  );
};

export default WaitingListLayout;
