import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledContentContainer, StyledPageContent } from './AdminOfflineLayout.styled';
import Typography from '../../components/Typography';
import BoxOffline from '../../components/BoxOfflineSvg';
import { HeaderProps } from '../../components/Header';

export type AdminOfflineLayoutProps = {
  renderHeader: () => ReactElement<HeaderProps>;
};

const AdminOfflineLayout = (props: AdminOfflineLayoutProps) => {
  const { t } = useTranslation();
  return (
    <StyledPageContent>
      {props.renderHeader()}
      <StyledContentContainer className="ion-padding">
        <div className="content">
          <BoxOffline className="svg-container" />
          <Typography tag="p" variant="secondary">
            {t('boxOfflineDesc')}
          </Typography>
        </div>
      </StyledContentContainer>
    </StyledPageContent>
  );
};

export default AdminOfflineLayout;
