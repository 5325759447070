import { IonButton } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonButton = styled(IonButton)`
  &.button {
    --padding-start: 0;
    --padding-end: 0;
    --color: var(--text-color-secondary);
    border-radius: 50%;
    width: ${(props) => (props.size == 'small' ? '32px' : '48px')};
    height: ${(props) => (props.size == 'small' ? '32px' : '48px')};
    font-size: ${(props) => (props.size == 'large' ? '40px' : '24px')};
  }

  & div {
    width: ${(props) => (props.size == 'large' ? '40px' : '24px')};
    height: ${(props) => (props.size == 'large' ? '40px' : '24px')};
    pointer-events: none;
  }
`;
