import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Spot, Track } from './playerSlice';
import { Milliseconds } from '../utils/types';

type Album = {
  id: string;
  img: string;
  explicit?: boolean;
  title: string;
  artist: {
    id: number;
    name: string;
  };
  released_at: number;
  duration: number;
  tracks: Track[];
  blacklisted?: boolean;
};
type Artist = { id: number; name: string; img?: string; seeArtistPage?: boolean };

type BottomSheetState = {
  trackBottomSheet?: Track & { fromWaitlist?: boolean };
  trackBottomSheetIsOpen: boolean;
  artistBottomSheet?: Artist;
  artistBottomSheetIsOpen: boolean;
  albumBottomSheet?: Album;
  albumBottomSheetIsOpen: boolean;
  boxSettingsBottomSheetIsOpen: boolean;
  spotBottomSheet?: Spot;
  spotBottomSheetIsOpen: boolean;
  playerModaleIsOpen: boolean;
  confirmPlayAlbumModale?: { trackIds: number[] };
  confirmPlayAlbumModaleIsOpen: boolean;
  confirmPlayAmbianceModale?: { ambianceId: string };
  confirmPlayAmbianceModaleIsOpen: boolean;
  confirmPlayPlaylistModale?: { playlistId: string };
  confirmPlayPlaylistModaleIsOpen: boolean;
  confirmBlacklistAlbumModale?: Album;
  confirmBlacklistAlbumModaleIsOpen: boolean;
  confirmBlacklistArtistModale?: Artist;
  confirmBlacklistArtistModaleIsOpen: boolean;
  confirmBlacklistTrackModale?: Track;
  confirmBlacklistTrackModaleIsOpen: boolean;
  confirmBackToProgModaleIsOpen: boolean;
  confirmShutdownBoxModaleIsOpen: boolean;
  confirmPlaySpotModale?: { spotId: string; title: string; duration: Milliseconds };
  confirmPlaySpotModaleIsOpen: boolean;
  infoSoundLibraryUnavailableIsOpen: boolean;
  infoConnectionLostIsOpen: boolean;
  confirmJukeboxModaleIsOpen: boolean;
};
const initialModaleSlice: BottomSheetState = {
  trackBottomSheetIsOpen: false,
  artistBottomSheetIsOpen: false,
  albumBottomSheetIsOpen: false,
  boxSettingsBottomSheetIsOpen: false,
  playerModaleIsOpen: false,
  spotBottomSheetIsOpen: false,
  confirmPlayAlbumModaleIsOpen: false,
  confirmPlayAmbianceModaleIsOpen: false,
  confirmPlayPlaylistModaleIsOpen: false,
  confirmBlacklistAlbumModaleIsOpen: false,
  confirmBlacklistArtistModaleIsOpen: false,
  confirmBlacklistTrackModaleIsOpen: false,
  confirmBackToProgModaleIsOpen: false,
  confirmShutdownBoxModaleIsOpen: false,
  confirmPlaySpotModaleIsOpen: false,
  infoSoundLibraryUnavailableIsOpen: false,
  infoConnectionLostIsOpen: false,
  confirmJukeboxModaleIsOpen: false,
};

const ModaleSlice = createSlice({
  name: 'ModaleSlice',
  initialState: initialModaleSlice,
  reducers: {
    setTrackBottomSheet(
      state: BottomSheetState,
      action: PayloadAction<(Track & { fromWaitlist?: boolean }) | undefined>,
    ) {
      state.trackBottomSheet = action.payload;
      state.trackBottomSheetIsOpen = true;
    },
    closeTrackBottomSheet(state: BottomSheetState) {
      state.trackBottomSheetIsOpen = false;
    },
    setAlbumBottomSheet(state: BottomSheetState, action: PayloadAction<Album | undefined>) {
      state.albumBottomSheet = action.payload;
      state.albumBottomSheetIsOpen = true;
    },
    closeAlbumBottomSheet(state: BottomSheetState) {
      state.albumBottomSheetIsOpen = false;
    },
    setArtistBottomSheet(state: BottomSheetState, action: PayloadAction<Artist | undefined>) {
      state.artistBottomSheet = action.payload;
      state.artistBottomSheetIsOpen = true;
    },
    closeArtistBottomSheet(state: BottomSheetState) {
      state.artistBottomSheetIsOpen = false;
    },
    setBoxSettingsBottomSheetIsOpen(state: BottomSheetState, action: PayloadAction<boolean>) {
      state.boxSettingsBottomSheetIsOpen = action.payload;
    },
    setPlayerBarIsOpen(state: BottomSheetState, action: PayloadAction<boolean>) {
      state.playerModaleIsOpen = action.payload;
    },
    setConfirmPlayAlbumModale(state: BottomSheetState, action: PayloadAction<{ trackIds: number[] }>) {
      state.confirmPlayAlbumModale = { trackIds: action.payload.trackIds };
      state.confirmPlayAlbumModaleIsOpen = true;
    },
    closeConfirmPlayAlbumModale(state: BottomSheetState) {
      state.confirmPlayAlbumModaleIsOpen = false;
    },
    setConfirmPlayAmbianceModale(state: BottomSheetState, action: PayloadAction<{ ambianceId: string }>) {
      state.confirmPlayAmbianceModale = { ambianceId: action.payload.ambianceId };
      state.confirmPlayAmbianceModaleIsOpen = true;
    },
    closeConfirmPlayAmbianceModale(state: BottomSheetState) {
      state.confirmPlayAmbianceModaleIsOpen = false;
    },
    setConfirmPlayPlaylistModale(state: BottomSheetState, action: PayloadAction<{ playlistId: string }>) {
      state.confirmPlayPlaylistModale = { playlistId: action.payload.playlistId };
      state.confirmPlayPlaylistModaleIsOpen = true;
    },
    closeConfirmPlayPlaylistModale(state: BottomSheetState) {
      state.confirmPlayPlaylistModaleIsOpen = false;
    },
    setConfirmBlacklistAlbumModale(state: BottomSheetState, action: PayloadAction<Album>) {
      state.confirmBlacklistAlbumModale = action.payload;
      state.confirmBlacklistAlbumModaleIsOpen = true;
    },
    closeConfirmBlacklistAlbumModale(state: BottomSheetState) {
      state.confirmBlacklistAlbumModaleIsOpen = false;
    },
    setConfirmBlacklistArtistModale(state: BottomSheetState, action: PayloadAction<Artist>) {
      state.confirmBlacklistArtistModale = action.payload;
      state.confirmBlacklistArtistModaleIsOpen = true;
    },
    closeConfirmBlacklistArtistModale(state: BottomSheetState) {
      state.confirmBlacklistArtistModaleIsOpen = false;
    },
    setConfirmBlacklistTrackModale(state: BottomSheetState, action: PayloadAction<Track>) {
      state.confirmBlacklistTrackModale = action.payload;
      state.confirmBlacklistTrackModaleIsOpen = true;
    },
    closeConfirmBlacklistTrackModale(state: BottomSheetState) {
      state.confirmBlacklistTrackModaleIsOpen = false;
    },
    setBackToProgModaleIsOpen(state: BottomSheetState, action: PayloadAction<boolean>) {
      state.confirmBackToProgModaleIsOpen = action.payload;
    },
    setShutdownBoxModaleIsOpen(state: BottomSheetState, action: PayloadAction<boolean>) {
      state.confirmShutdownBoxModaleIsOpen = action.payload;
    },
    setInfoSoundLibraryUnavailableModaleIsOpen(state: BottomSheetState, action: PayloadAction<boolean>) {
      state.infoSoundLibraryUnavailableIsOpen = action.payload;
    },
    setInfoConnectionLostModaleIsOpen(state: BottomSheetState, action: PayloadAction<boolean>) {
      state.infoConnectionLostIsOpen = action.payload;
    },
    setConfirmJukeboxModaleIsOpen(state: BottomSheetState, action: PayloadAction<boolean>) {
      state.confirmJukeboxModaleIsOpen = action.payload;
    },
    setSpotBottomSheet(state: BottomSheetState, action: PayloadAction<Spot | undefined>) {
      state.spotBottomSheet = action.payload;
      state.spotBottomSheetIsOpen = true;
    },
    closeSpotBottomSheet(state: BottomSheetState) {
      state.spotBottomSheetIsOpen = false;
    },
    setConfirmPlaySpotModale(
      state: BottomSheetState,
      action: PayloadAction<{ spotId: string; title: string; duration: Milliseconds }>,
    ) {
      state.confirmPlaySpotModale = {
        spotId: action.payload.spotId,
        title: action.payload.title,
        duration: action.payload.duration,
      };
      state.confirmPlaySpotModaleIsOpen = true;
    },
    closeConfirmPlaySpotModale(state: BottomSheetState) {
      state.confirmPlaySpotModaleIsOpen = false;
    },
  },
});

const ModaleReducer = ModaleSlice.reducer;

export const {
  setTrackBottomSheet,
  closeTrackBottomSheet,
  closeAlbumBottomSheet,
  closeArtistBottomSheet,
  setAlbumBottomSheet,
  setArtistBottomSheet,
  setBoxSettingsBottomSheetIsOpen,
  setPlayerBarIsOpen,
  setConfirmPlayAlbumModale,
  closeConfirmPlayAlbumModale,
  closeConfirmPlayAmbianceModale,
  closeConfirmPlayPlaylistModale,
  setConfirmPlayAmbianceModale,
  setConfirmPlayPlaylistModale,
  closeConfirmBlacklistAlbumModale,
  setConfirmBlacklistAlbumModale,
  setConfirmBlacklistArtistModale,
  closeConfirmBlacklistArtistModale,
  closeConfirmBlacklistTrackModale,
  setConfirmBlacklistTrackModale,
  setBackToProgModaleIsOpen,
  setShutdownBoxModaleIsOpen,
  setInfoSoundLibraryUnavailableModaleIsOpen,
  setInfoConnectionLostModaleIsOpen,
  setConfirmJukeboxModaleIsOpen,
  setSpotBottomSheet,
  closeSpotBottomSheet,
  setConfirmPlaySpotModale,
  closeConfirmPlaySpotModale,
} = ModaleSlice.actions;
export default ModaleReducer;
