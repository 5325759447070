import styled from 'styled-components';

export const StyledPageContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: calc(var(--ion-safe-area-top) - 16px);
  margin-top: 16px;

  & .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  & p {
    padding: 4px;
    margin: 4;
    text-align: center;
    font-weight: 600;
  }
`;

export const StyledSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledSectionContent = styled.div`
  display: flex;
  flex: 0;
`;

export const StyledSectionText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & p {
    padding: 0px;
    margin: 0px;
  }
`;
