import React, { ReactElement } from 'react';
import { HeaderProps } from '../../components/Header';
import PlayerContentLayout, { PlayerContentLayoutProps } from '../PlayerContentLayout';
import { StyledIonModal } from './PlayerModalLayout.styled';

export type PlayerModalLayoutProps = {
  isOpen?: boolean;
  onDidDismiss?: () => void;
  modal: React.RefObject<HTMLIonModalElement>;
  renderHeader: () => ReactElement<HeaderProps>;
} & PlayerContentLayoutProps;

const PlayerModalLayout = (props: PlayerModalLayoutProps) => {
  const { onDidDismiss, isOpen, ...playerContentProps } = props;

  return (
    <StyledIonModal
      onDidDismiss={onDidDismiss}
      ref={props.modal}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      isOpen={isOpen}
    >
      <PlayerContentLayout {...playerContentProps} renderHeader={props.renderHeader} />
    </StyledIonModal>
  );
};

export default PlayerModalLayout;
