import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
  padding-top: calc(var(--ion-safe-area-top) - 16px);
  margin-top: 16px;
  padding-inline-start: calc(var(--ion-safe-area-left, 0px) + 16px);
  padding-inline-end: calc(var(--ion-safe-area-right, 0px) + 16px);
  padding-bottom: 0px;

  & ion-header {
    display: flex;
    flex-direction: row;

    & ion-button {
      margin-left: 4px;
      margin-right: 20px;
    }
  }
`;

export const StyledArtistTitleContainer = styled.div`
  & h2 {
    margin-bottom: 8px;
  }
  & h5 {
    margin-top: 0px;
    margin-bottom: 8px;
  }
`;

export const StyledPageContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
