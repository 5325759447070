import { IonContent } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonContent = styled(IonContent)`
  &::part(scroll) {
    scroll-snap-type: y proximity;
  }
  & ion-list {
    margin-bottom: calc(72px + var(--ion-safe-area-bottom));
  }
`;
