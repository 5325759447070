import styled from 'styled-components';

const floatingAnim = (name: string, x: number, y: number, rotate?: number) => {
  return `
      @keyframes floating-${name} {
        0% {
          transform: translate(${x}px, ${y - 5}px) ${rotate ? `rotate(${rotate})` : ''};
        }
        100% {
          transform: translate(${x}px, ${y + 5}px) ${rotate ? `rotate(${rotate}deg)` : ''};
        }
      }
      transform: translate(${x}px, ${y - 5}px) ${rotate ? `rotate(${rotate}deg)` : ''};
      animation: floating-${name} 1.2s linear infinite alternate;`;
};

export const StyledSvgContainer = styled.div`
  color: var(--ion-color-secondary);

  & svg {
    height: 100%;
    width: 100%;
  }

  & #music1 {
    ${floatingAnim('music1', 49.047, 108.336)}
    animation-delay: 0.2s;
    animation-duration: 1s;
  }
  & #music2 {
    ${floatingAnim('music2', 30.182, 182.34)}
    animation-delay: 0.4s;
    animation-duration: 1.4s;
  }
  & #player {
    ${floatingAnim('player', 189.327, 165.833)}
    animation-duration: 1s;
  }
  & #note1 {
    ${floatingAnim('note1', 160.599, 43.253, 22)}
    animation-delay: 0.2s;
    animation-duration: 1.2s;
  }
  & #note2 {
    ${floatingAnim('note2', 1895.341, 1081.235)}
    animation-delay: 0.4s;
    animation-duration: 1.4s;
  }
  & #note3 {
    ${floatingAnim('note3', 329.75, 91.475, 43)}
    animation-delay: 0.6s;
    animation-duration: 1.6s;
  }
  & #note4 {
    ${floatingAnim('note4', 2020.341, 1201.235)}
    animation-delay: 0.7s;
    animation-duration: 1.7s;
  }
  & #jukebox1 {
    ${floatingAnim('jukebox1', 685.533, 611.766)}
  }
  & #jukebox2 {
    ${floatingAnim('jukebox2', -77.99, 40.419)}
  }
  & #jukebox3 {
    ${floatingAnim('jukebox3', 685.533, 611.766)}
  }
  & #button {
    ${floatingAnim('button', 228.998, 121.952)}
  }
`;
