import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../../components/AlertModal';
import { setBackToProgModaleIsOpen } from '../../../store/modaleSlice';
import { RootState } from '../../../store/rootReducer';
import { setToast } from '../../../store/toastSlice';
import SocketService from '../../../services/socket';
import { useHistory } from 'react-router-dom';

const ConfirmBackToProg = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const confirmBackToProgModaleIsOpen = useSelector(
    (state: RootState) => state.ModaleReducer.confirmBackToProgModaleIsOpen,
  );
  const history = useHistory();
  return (
    <AlertModal
      isOpen={confirmBackToProgModaleIsOpen}
      onDidDismiss={() => {
        dispatch(setBackToProgModaleIsOpen(false));
      }}
      header={t('backToProgAlertTitle')}
      subHeader={t('backToProgAlertDesc')}
      buttons={[
        {
          text: t('back'),
          role: 'cancel',
        },
        {
          text: t('confirm'),
          role: 'confirm',
          handler: () => {
            SocketService.removeCurrentEphemeral();
            history.push('/app/waitinglist');
            dispatch(
              setToast({
                isOpen: true,
                header: t('backToProg'),
                duration: 5000,
              }),
            );
          },
        },
      ]}
    />
  );
};

export default ConfirmBackToProg;
