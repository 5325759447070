import { combineReducers } from '@reduxjs/toolkit';
import { api, legacyApi } from '../services/api.service';
import { auth } from '../services/auth.service';
import { qobuz } from '../services/qobuz.service';
import { cdn } from '../services/cdn.service';
import PlayerReducer from './playerSlice';
import ToastReducer from './toastSlice';
import ModaleReducer from './modaleSlice';
import DrawerReducer from './drawerSlice';
import SearchReducer from './searchSlice';
import AppReducer from './appSlice';

const combinedReducers = combineReducers({
  [api.reducerPath]: api.reducer,
  [legacyApi.reducerPath]: legacyApi.reducer,
  [auth.reducerPath]: auth.reducer,
  [qobuz.reducerPath]: qobuz.reducer,
  [cdn.reducerPath]: cdn.reducer,
  PlayerReducer,
  ToastReducer,
  ModaleReducer,
  SearchReducer,
  DrawerReducer,
  AppReducer,
});

export type RootState = ReturnType<typeof combinedReducers>;

export default combinedReducers;
