import { Capacitor } from '@capacitor/core';

export const isJukeboxApp = () => !!+(process.env.REACT_APP_JUKEBOX_MODE || 0);
export const isAdminApp = () => !isJukeboxApp();

export const isWhiteLabel = () => Capacitor.getPlatform() === 'web' && !window.location.hostname.includes('tshoko.fr');

export const getSubdomain = () => {
  return window.location.hostname.includes('tshoko') && window.location.hostname.split('.').length === 3
    ? window.location.hostname.split('.')[0]
    : window.location.hostname;
};
