import styled from 'styled-components';

export const StyledPageContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: calc(var(--ion-safe-area-top) - 16px);
  margin-top: 16px;

  & .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  & .svg-container {
    display: flex;
    flex: 1;
    justify-content: center;
    width: inherit;

    & svg {
      width: inherit;
      object-fit: contain;
      contain: inline-size;
    }
  }

  & p {
    padding: 16px;
    text-align: center;
    font-weight: 600;
  }
`;
