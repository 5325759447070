import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../../components/AlertModal';
import { setInfoConnectionLostModaleIsOpen } from '../../../store/modaleSlice';
import { RootState } from '../../../store/rootReducer';

const InfoConnectionLost = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const infoConnectionLostIsOpen = useSelector((state: RootState) => state.ModaleReducer.infoConnectionLostIsOpen);
  return (
    <AlertModal
      isOpen={infoConnectionLostIsOpen}
      onDidDismiss={() => {
        dispatch(setInfoConnectionLostModaleIsOpen(false));
      }}
      header={t('boxLostConnection')}
      subHeader={t('downloadedMusicsPlaying')}
      buttons={['ok']}
    />
  );
};

export default InfoConnectionLost;
