import SocketService from '../../services/socket';
import { Track } from '../../store/playerSlice';
import { getAllSearchedTracksSelector } from '../Search/selector';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import store from '../../store/store';
import { useDispatch } from 'react-redux';
import { setToast } from '../../store/toastSlice';
import { useLazyTracksSearchQuery } from '../../services/qobuz.service';

export const useVoteTrackHook = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [triggerTracksSearch] = useLazyTracksSearchQuery();

  return async (track: Track) => {
    const { votesList, waitlist } = store.getState().PlayerReducer;
    if (votesList.includes(track.id)) {
      SocketService.voteDown(track.id);
    } else {
      const allTracks = getAllSearchedTracksSelector(store.getState());
      if (!waitlist.map((wt) => wt.id).includes(track.id)) {
        const QobuzTrack =
          allTracks.find((qt) => qt.id === track.id) ||
          (await triggerTracksSearch({
            limit: 1,
            offset: 0,
            query: track.isrc || `${track.id}`,
          })
            .unwrap()
            .then((res) => res.tracks.items[0]));
        if (QobuzTrack) {
          dispatch(
            setToast({
              isOpen: true,
              header: t('musicAdded').toString(),
              duration: 5000,
            }),
          );
          SocketService.proposeMusic(QobuzTrack);
        }
      }
      SocketService.voteUp(track.id);
      history.push('/app/waitinglist');
    }
  };
};
