import { IonIcon } from '@ionic/react';
import { information, alarm, radio, search } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import BoxOfflineIcon from '../../components/Icons/BoxOfflineIcon';
import JukeboxOfflineIcon from '../../components/Icons/JukeboxOfflineIcon';
import WaitingListIcon from '../../components/Icons/WaitingListIcon';
import NoProg from '../NoProg';
import Offline from '../Offline';
import Player from '../Player';
import Search from '../Search/Search';
import WaitingList from '../WaitingList';
import JukeboxOfflineLayout from '../../layouts/JukeboxOfflineLayout';
import InfoLayout from '../../layouts/InfoLayout/InfoLayout';
import { useGetSettingsQuery } from '../../services/cdn.service';
import { HeaderWithSwitchBox } from '../components/HeaderWithSwitchBox';
import RestrictedSearch from '../Search/RestrictedSearch';
import { RootState } from '../../store/rootReducer';
import { useSelector } from 'react-redux';
import { useGetBoxLiveQuery } from '../../services/api.service';

export type AppStateLabel =
  | 'playingAdmin'
  | 'playingUser'
  | 'noProg'
  | 'boxUnavailable'
  | 'jukeboxUnavailable'
  | 'playingUserLimitedJukebox';

export type AppStates = Record<
  AppStateLabel,
  {
    id: string;
    label: string;
    component: JSX.Element;
    href: string;
    icon: JSX.Element;
  }[]
>;

export const useAppPages = (): AppStates => {
  const { t } = useTranslation();
  const boxId = useSelector((state: RootState) => state.AppReducer.boxDetail?._id);
  const { data: settings } = useGetSettingsQuery();
  const { data: programmation } = useGetBoxLiveQuery(boxId, { skip: !boxId });

  const adminPage = {
    id: 'admin',
    label: t('administration'),
    href: '/app/admin',
    component: <Player fromAdminPage />,
    icon: <IonIcon icon={radio} />,
  };
  const waitlistPage = {
    id: 'waitinglist',
    label: t('waitingList'),
    href: '/app/waitinglist',
    icon: <WaitingListIcon />,
    component: <WaitingList />,
  };
  const searchAdminPage = {
    id: 'search',
    label: t('search'),
    href: '/app/search',
    icon: <IonIcon src={search} />,
    component: <Search adminMode />,
  };
  const searchUserPage = {
    id: 'search',
    label: t('search'),
    href: '/app/search',
    icon: <IonIcon src={search} />,
    component: <Search adminMode={false} />,
  };
  const searchRestrictedPage = {
    id: 'search',
    label: t('search'),
    href: '/app/search',
    icon: <IonIcon src={search} />,
    component: <RestrictedSearch />,
  };
  const infoPage = {
    id: 'info',
    label: t('info'),
    href: '/app/info',
    icon: <IonIcon icon={information} />,
    component: (
      <InfoLayout
        buttonHref={settings?.button?.href || 'https://tshoko.fr'}
        buttonText={settings?.button?.label || t('knowMore')}
        img="./theme/mobileapp-about-background.png"
      />
    ),
  };
  const noProgPage = {
    id: 'noProg',
    label: t('noProg'),
    href: '/app/noProg',
    icon: <IonIcon icon={alarm} />,
    component: <NoProg />,
  };
  const noBoxPage = {
    id: 'noBox',
    label: t('boxOffline'),
    href: '/app/offline',
    component: <Offline />,
    icon: <BoxOfflineIcon />,
  };
  const noJukeboxPage = {
    id: 'noJukebox',
    label: t('jukeboxUnavailable'),
    href: '/app/noJukebox',
    component: <JukeboxOfflineLayout renderHeader={() => <HeaderWithSwitchBox subtitle={t('jukeboxOffline')} />} />,
    icon: <JukeboxOfflineIcon />,
  };

  return {
    playingAdmin: [adminPage, waitlistPage, searchAdminPage, infoPage],
    playingUser: [waitlistPage, searchUserPage, infoPage],
    playingUserLimitedJukebox: [waitlistPage, searchRestrictedPage, infoPage],
    noProg: [noProgPage, ...(programmation?.nextSlot ? [searchAdminPage] : []), infoPage],
    boxUnavailable: [noBoxPage, infoPage],
    jukeboxUnavailable: [noJukeboxPage, infoPage],
  };
};
