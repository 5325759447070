export const secondsToTimeString = (seconds: number): string => {
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const leftOverMinutes = minutes % 60;
  const hoursString = hours > 1 ? `${Math.trunc(hours)}h` : '';
  const minutesString =
    leftOverMinutes >= 10 ? Math.trunc(leftOverMinutes) : `${hours > 1 ? 0 : ''}${Math.trunc(leftOverMinutes)}`;
  const minutesUnitString = hours > 1 ? '' : ' min';
  return `${hoursString}${minutesString}${minutesUnitString}`;
};
