import { IonHeader } from '@ionic/react';
import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
  position: relative;
  padding-top: calc(var(--ion-safe-area-top) - 16px);
  margin-top: 16px;
  padding-inline-start: calc(var(--ion-safe-area-left, 0px) + 16px);
  padding-inline-end: calc(var(--ion-safe-area-right, 0px) + 16px);
  padding-bottom: 0px;

  & h1 {
    margin-top: 8px;
  }

  & h2 {
    display: inline-flex;
    margin: 6px 0px 6px 0px;
  }

  & ion-button {
    width: 48px;
  }
  & .title-header {
    display: flex;
    justify-content: space-between;

    & .title {
      display: inline-flex;
      width: calc(100% - 48px);
    }
  }
`;

export const StyledIonHeader = styled(IonHeader)`
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .header-logo {
    display: flex;
    align-items: center;
  }

  & ion-list-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    & .logo {
      max-width: 210px;
      margin-right: 8px;
    }

    & ion-label {
      margin-top: 8px;
    }
  }
`;
