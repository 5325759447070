import { Milliseconds } from '../../utils/types';
import SpotIcon from '../Icons/SpotIcon';
import { StyledIonItem, StyledIonLabel, StyledIonText } from './SpotCard.styled';
import Typography from '../Typography';
import { IonSkeletonText } from '@ionic/react';
import { addSeconds, format } from 'date-fns';

export type SpotCardDefaultProps = {
  spot: {
    id: string;
    title: string;
    duration: Milliseconds;
    img?: string;
    author?: string;
  };
  onClick: () => void;
  loading: boolean;
};

type SpotCardLoading = { loading: true };

export type SpotCardProps = SpotCardDefaultProps | SpotCardLoading;

const SpotCard = (props: SpotCardProps) => {
  return props.loading ? (
    <div style={{ margin: '16px 0px 16px 16px' }}>
      <StyledIonItem lines="none">
        <IonSkeletonText animated={true} />
      </StyledIonItem>
    </div>
  ) : (
    <div style={{ margin: '16px 0px 16px 16px' }}>
      <StyledIonItem lines="none" onClick={props.onClick}>
        <SpotIcon />
        <StyledIonLabel>
          <Typography tag="p" className="ion-text-nowrap">
            <StyledIonText>{props.spot.title}</StyledIonText>
          </Typography>
          <Typography className="ion-text-nowrap">
            <StyledIonText className="h4">
              {format(addSeconds(new Date(0), props.spot.duration / 1000 || 30), 'mm:ss')}
            </StyledIonText>
          </Typography>
        </StyledIonLabel>
      </StyledIonItem>
    </div>
  );
};

export default SpotCard;
