import React, { createContext, useEffect, useState } from 'react';
import { Keyboard, KeyboardInfo, KeyboardResize } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';

type KeyboardState =
  | {
      state: 'opening' | 'open' | 'closing' | 'closed';
      keyboardHeight: number;
    }
  | undefined;

type KeyboardContextProps = {
  keyboardState: KeyboardState;
};

export const KeyboardContext = createContext<KeyboardContextProps>({
  keyboardState: undefined,
});

export const KeyboardContextProvider = (props: { children?: React.ReactNode }) => {
  const [keyboardState, setKeyboardState] = useState<KeyboardState>();

  useEffect(() => {
    (async () => {
      if (Capacitor.getPlatform() !== 'web') {
        const { mode: resizeMode } = await Keyboard.getResizeMode();
        if (resizeMode !== 'none') {
          Keyboard.setResizeMode({ mode: 'none' as KeyboardResize });
        }
      }
    })();
    const didShow = (info: KeyboardInfo) => {
      if (typeof window === 'undefined') {
        return;
      }
      const { keyboardHeight } = info;
      setKeyboardState({
        state: 'open',
        keyboardHeight,
      });
    };
    const willShow = (info: KeyboardInfo) => {
      if (typeof window === 'undefined') {
        return;
      }
      const { keyboardHeight } = info;
      setKeyboardState({
        state: 'opening',
        keyboardHeight,
      });
    };
    const willHide = () => {
      if (typeof window === 'undefined') {
        return;
      }
      setKeyboardState((oldKeyboardState) =>
        oldKeyboardState
          ? {
              ...oldKeyboardState,
              state: 'closing',
            }
          : undefined,
      );
    };
    const didHide = () => {
      setKeyboardState((oldKeyboardState) =>
        oldKeyboardState
          ? {
              ...oldKeyboardState,
              state: 'closed',
            }
          : undefined,
      );
    };
    if (Capacitor.getPlatform() !== 'web') {
      Keyboard.addListener('keyboardDidShow', didShow);
      Keyboard.addListener('keyboardDidHide', didHide);
      Keyboard.addListener('keyboardWillShow', willShow);
      Keyboard.addListener('keyboardWillHide', willHide);
    }
    return () => {
      if (Capacitor.getPlatform() !== 'web') {
        Keyboard.removeAllListeners();
      }
    };
  }, []);

  return <KeyboardContext.Provider value={{ keyboardState: keyboardState }}>{props.children}</KeyboardContext.Provider>;
};
