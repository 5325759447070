import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HTTP from 'i18next-http-backend';

import { initReactI18next } from 'react-i18next';
i18n
  .use(HTTP)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  });

fetch('/theme/mobileapp-settings.json')
  .then((response) => response.json())
  .then((json) => {
    i18n.changeLanguage(json.language);
    document.documentElement.lang = i18n.language;
  });

export default i18n;
