import { AggregatedBlacklistDto } from '../../services/api.service';
import { QobuzArtistAlbumDto, QobuzAlbumDto, QobuzTopTrackDto, QobuzTrackDto } from '../../services/qobuz.service';

let memoizedBlacklistedTracks: Record<number, boolean> = {};
let memoizedBlacklistedArtists: Record<number, boolean> = {};
let memoizedBlacklistedAlbums: Record<string, boolean> = {};

const isTrackBlacklisted = (aggregatedBlacklist: AggregatedBlacklistDto, track: QobuzTrackDto | QobuzTopTrackDto) => {
  return aggregatedBlacklist.whitelistedTracks.includes(track.id)
    ? false
    : aggregatedBlacklist.tracks.includes(track.id) ||
        (aggregatedBlacklist.explicit && track.parental_warning) ||
        aggregatedBlacklist.genres.includes(track.album.genre.id) ||
        aggregatedBlacklist.keywords.some((k) =>
          `${track.album.title}${
            'performer' in track && track.performer
              ? track.performer.name
              : 'artist' in track.album
              ? track.album.artist.name
              : 'artist' in track
              ? track.artist.name
              : ''
          }${track.title}`
            .toLowerCase()
            .includes(k.toLowerCase()),
        ) ||
        aggregatedBlacklist.albums.includes(track.album.id) ||
        aggregatedBlacklist.artists.includes(
          'artist' in track.album ? track.album.artist.id : 'artist' in track ? track.artist.id : 0,
        );
};
const isAlbumBlacklisted = (
  aggregatedBlacklist: AggregatedBlacklistDto,
  album: QobuzAlbumDto | QobuzArtistAlbumDto,
) => {
  return aggregatedBlacklist.whitelistedAlbums.includes(album.id)
    ? false
    : aggregatedBlacklist.albums.includes(album.id) ||
        (aggregatedBlacklist.explicit && album.parental_warning) ||
        aggregatedBlacklist.genres.includes(album.genre.id) ||
        aggregatedBlacklist.artists.includes(album.artist.id) ||
        aggregatedBlacklist.keywords.some((k) =>
          `${album.title}${typeof album.artist.name === 'string' ? album.artist.name : album.artist.name.display}`
            .toLowerCase()
            .includes(k),
        );
};
const isArtistBlacklisted = (aggregatedBlacklist: AggregatedBlacklistDto, artist: { id: number; name: string }) => {
  return aggregatedBlacklist.whitelistedArtists.includes(artist.id)
    ? false
    : aggregatedBlacklist.artists.includes(artist.id) ||
        aggregatedBlacklist.keywords.some((k) => artist.name.toLowerCase().includes(k));
};

export const isMemoizedTrackBlacklisted = (
  aggregatedBlacklist: AggregatedBlacklistDto | undefined,
  track: QobuzTrackDto | QobuzTopTrackDto,
) => {
  if (!aggregatedBlacklist) {
    return false;
  }
  if (memoizedBlacklistedTracks[track.id]) {
    return memoizedBlacklistedTracks[track.id];
  } else {
    const isBlacklisted = isTrackBlacklisted(aggregatedBlacklist, track);
    memoizedBlacklistedTracks[track.id] = isBlacklisted;
    return isBlacklisted;
  }
};
export const isMemoizedAlbumBlacklisted = (
  aggregatedBlacklist: AggregatedBlacklistDto | undefined,
  album: QobuzAlbumDto | QobuzArtistAlbumDto,
) => {
  if (!aggregatedBlacklist) {
    return false;
  }
  if (memoizedBlacklistedAlbums[album.id]) {
    return memoizedBlacklistedAlbums[album.id];
  } else {
    const isBlacklisted = isAlbumBlacklisted(aggregatedBlacklist, album);
    memoizedBlacklistedAlbums[album.id] = isBlacklisted;
    return isBlacklisted;
  }
};
export const isMemoizedArtistBlacklisted = (
  aggregatedBlacklist: AggregatedBlacklistDto | undefined,
  artist: { id: number; name: string },
) => {
  if (!aggregatedBlacklist) {
    return false;
  }
  if (memoizedBlacklistedArtists[artist.id]) {
    return memoizedBlacklistedArtists[artist.id];
  } else {
    const isBlacklisted = isArtistBlacklisted(aggregatedBlacklist, artist);
    memoizedBlacklistedArtists[artist.id] = isBlacklisted;
    return isBlacklisted;
  }
};

export const clearCache = () => {
  memoizedBlacklistedAlbums = {};
  memoizedBlacklistedArtists = {};
  memoizedBlacklistedTracks = {};
};
