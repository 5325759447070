import { NavComponent } from '@ionic/core';
import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setArtistToPush } from '../../store/drawerSlice';
import { RootState } from '../../store/rootReducer';
import { iosTransitionAnimation } from '../../utils/ios.transition';
import ArtistDrawer from './ArtistDrawer';
import { StyledIonNav } from './Drawer.styled';

type DrawerProps = {
  drawerRoot: NavComponent;
};

const Drawer = (props: DrawerProps) => {
  const dispatch = useDispatch();
  const ionNavRef = useRef<HTMLIonNavElement>(null);
  const artistDrawerToPush = useSelector((state: RootState) => state.DrawerReducer.artistDrawerToPush);

  useEffect(() => {
    if (ionNavRef.current && artistDrawerToPush?.artistId) {
      ionNavRef.current.push(() => <ArtistDrawer artistId={artistDrawerToPush.artistId} />);
      dispatch(setArtistToPush(undefined));
    }
  }, [artistDrawerToPush, dispatch]);

  return (
    <StyledIonNav
      ref={ionNavRef}
      root={props.drawerRoot}
      onIonNavDidChange={(e) => {
        // hack to fix issue https://github.com/ionic-team/ionic-framework/issues/25753
        // remove dangling page
        let childNodesToRemove: ChildNode[] = [];
        const rootActive = !(e.target.childNodes[0] as HTMLDivElement).attributes
          .getNamedItem('class')
          ?.value.includes('ion-page-hidden');
        e.target.childNodes.forEach((childNode, key) => {
          if (key === 0) return;
          const isOldDrawerPage =
            rootActive ||
            (childNode as HTMLDivElement).attributes.getNamedItem('style')?.value.includes('display: none');
          if (isOldDrawerPage) {
            childNodesToRemove = [...childNodesToRemove, childNode];
          }
        });
        childNodesToRemove.forEach((childNode) => childNode.remove());
      }}
      animation={iosTransitionAnimation}
    />
  );
};

export default Drawer;
