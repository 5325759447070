import { IonItem, IonImg, IonLabel, IonThumbnail } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonItem = styled(IonItem)`
  --border-color: transparent;
  --background: none;
  --padding-start: 0;
  --inner-padding-end: 0;
  flex: 1;

  &::part(native) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 16px;
  }
`;

export const StyledIonThumbnail = styled(IonThumbnail)`
  height: auto;
  aspect-ratio: 1 / 1;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  position: relative;
  border-radius: 4%;
  overflow: hidden;
  background: rgba(var(--ion-color-dark-contrast-rgb), 1);
`;

export const StyledIonImg = styled(IonImg)`
  width: 100%;
  height: 100%;
`;

export const StyledIonLabel = styled(IonLabel)`
  width: 100%;
  padding-bottom: 0px;
  margin-bottom: 0px;
`;

export const StyledAmbianceTitle = styled.div`
  display: flex;
  align-items: center;
  & ion-text {
    overflow: hidden;
    & h3 {
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;

      & ion-skeleton-text {
        margin: 0;
        height: 19px !important;
      }
    }
  }
  & ion-icon {
    margin-top: 0;
  }
`;

export const StyledAmbianceVerticalBorders = styled.div`
  &::before {
    content: '';
    position: absolute;
    width: 3%;
    height: 100%;
    background-color: currentColor;
    top: 0;
    left: 0;
  }
  &::after {
    content: '';
    position: absolute;
    width: 3%;
    height: 100%;
    background-color: currentColor;
    top: 0;
    right: 0;
  }
`;

export const StyledAmbianceHorizontalBorders = styled.div`
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3%;
    background-color: currentColor;
    top: 0;
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3%;
    background-color: currentColor;
    bottom: 0;
  }
`;
