import styled from 'styled-components';

export const DottedWrapperStyle = styled.div`
  position: relative;
  margin: calc(${(props: { $size?: number }) => props.$size || 15}px / 2);
  padding-left: calc(${(props: { $size?: number }) => props.$size || 15}px / 2);
  padding-right: calc(${(props: { $size?: number }) => props.$size || 15}px / 2);
  margin-top: 4px;
  margin-bottom: 4px;
  display: inline-flex;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    width: ${(props: { $size?: number }) => props.$size || 15}px;
    height: ${(props: { $size?: number }) => props.$size || 15}px;
  }
  &:before {
    left: -${(props: { $size?: number }) => props.$size || 15}px;
    top: 0px;
    background: var(--ion-color-secondary);
  }
  &:after {
    right: -${(props: { $size?: number }) => props.$size || 15}px;
    bottom: 0px;
    background: var(--ion-color-primary);
  }
`;
