import { IonIcon, IonRippleEffect } from '@ionic/react';
import { chevronDown, chevronUp } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import { StyledSeeMoreContainer, StyledSeeMoreContent, StyledButtonSeeMore } from './SeeMore.styled';

export type SeeMoreProps = {
  children?: React.ReactNode;
  toggled?: boolean;
  nbLine?: number;
  maxHeight?: number | string;
  hideEllipsis?: boolean;
};

export const SeeMore = (props: SeeMoreProps) => {
  const { t } = useTranslation();
  const seeMoreContentRef = useRef<HTMLDivElement>(null);
  const [toggled, setToggled] = useState<boolean>(!!props.toggled);
  const [maxHeight, setMaxHeight] = useState<number>();

  return (
    <StyledSeeMoreContainer>
      <StyledSeeMoreContent
        className={!toggled && !props.hideEllipsis ? 'ellipsis' : undefined}
        style={
          toggled
            ? {
                maxHeight: maxHeight,
              }
            : {
                maxHeight: props.maxHeight ? props.maxHeight : (props.nbLine || 3) * 24,
              }
        }
      >
        {props.children}
      </StyledSeeMoreContent>
      {!maxHeight && (
        <StyledSeeMoreContent style={{ opacity: 0, position: 'absolute', zIndex: -9999 }} ref={seeMoreContentRef}>
          {props.children}
        </StyledSeeMoreContent>
      )}
      <StyledButtonSeeMore
        className="ion-activatable ripple-parent"
        onClick={() => {
          setToggled(!toggled);
          if (!maxHeight) {
            setMaxHeight(seeMoreContentRef.current?.getBoundingClientRect().height);
          }
        }}
      >
        <IonRippleEffect />
        <Typography>{toggled ? t('seeLess') : t('seeMore')}</Typography>
        <IonIcon slot="icon-only" icon={toggled ? chevronUp : chevronDown} />
      </StyledButtonSeeMore>
    </StyledSeeMoreContainer>
  );
};

export default SeeMore;
