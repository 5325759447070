import styled from 'styled-components';
import { IonText } from '@ionic/react';

export const StyledIonText = styled(IonText)`
  white-space: pre-line;
  &.text-primary,
  & .text-primary {
    ${(props) => (props.color ? '' : 'color: var(--text-color-primary);')}
  }
  &.text-secondary,
  & .text-secondary {
    ${(props) => (props.color ? '' : ' color: var(--text-color-secondary);')}
  }
`;
