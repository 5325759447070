import styled from 'styled-components';
import { IonNav, IonNavLink } from '@ionic/react';

export const StyledIonNavLink = styled(IonNavLink)`
  flex: 1;
  contain: inline-size;
`;

export const StyledIonNav = styled(IonNav)`
  z-index: 1;

  & .ion-page-hidden {
    z-index: 99 !important;
    &:not(.can-go-back) {
      display: flex !important;
    }
  }

  & .ion-page.can-go-back {
    display: flex !important;
    & ion-content {
      opacity: 1 !important;
      --background: rgba(var(--ion-color-dark-contrast-rgb), 0.54);
      backdrop-filter: blur(5px) !important;

      &::before {
        content: '';
        position: absolute;
        z-index: 0;
        width: 200%;
        height: 100%;
        background: rgba(var(--ion-color-dark-contrast-rgb), 0.4);
      }
      & ion-content {
        --background: none;
        backdrop-filter: inherit !important;
        &::before {
          content: none !important;
        }
        & > div {
          padding-bottom: 72px;
        }
      }
    }
  }
`;
