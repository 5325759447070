import React, { useEffect, useMemo, useState } from 'react';
import { StyledProgressBar, PlayerTime, PlayerTimeContainer } from './PlayerProgressBar.styled';
import useInterval from '../../utils/hooks/useInterval';
import { format } from 'date-fns';

const PlayerProgressBar = (props: { timer: number; duration: number; displayTime?: boolean }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const progressBarValue = +((currentTime * 100) / props.duration / 100).toFixed(4);
  const initDate = useMemo(() => Date.now() - props.timer, [props]);

  const tick = props.duration > 0 && currentTime < props.duration;

  useInterval(
    () => {
      setCurrentTime(() => {
        return Date.now() - initDate;
      });
    },
    tick ? 100 : null,
  );

  useEffect(() => {
    if (props.timer) {
      setCurrentTime(props.timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  return (
    <>
      <StyledProgressBar value={progressBarValue} style={{ height: 3 }} />
      {props.displayTime && (
        <PlayerTimeContainer>
          <PlayerTime className={currentTime > 0 ? 'current' : ''}>
            {format(
              currentTime < props.duration ? currentTime : props.duration,
              props.duration >= 60 * 60 * 1000 ? 'hh:mm:ss' : 'mm:ss',
            )}
          </PlayerTime>
          <PlayerTime className={currentTime >= props.duration ? 'current' : ''}>
            {format(props.duration, props.duration >= 60 * 60 * 1000 ? 'hh:mm:ss' : 'mm:ss')}
          </PlayerTime>
        </PlayerTimeContainer>
      )}
    </>
  );
};

export default PlayerProgressBar;
