import { useEffect, useState } from 'react';
import { IonPage, IonContent, IonModal, IonButton } from '@ionic/react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import DottedWrapper from '../components/DottedWrapper';
import Button from '../components/Button';

const StyledDownloadAppModal = styled(IonModal)`
  --height: auto;
  --max-width: 80%;
  --border-radius: 14px;

  .modal-content {
    padding-inline: 20px;
    padding-top: 10px;
    text-align: center;
    color: var(--ion-color-secondary-contrast);
    background-color: var(--ion-color-background-contrast);

    .secondary-button {
      --color: var(--ion-color-secondary-contrast);
      margin-bottom: 10px;
    }

    .logo {
      width: 60px;
      height: auto;
      margin-top: 10px;
    }

    .description {
      margin-top: 10px;
      margin-bottom: 30px;
    }

    .title {
      line-height: 0.7;
      padding-bottom: 8px;
    }
  }
`;

const DownloadAppAlertContainer = ({ platforms }: { platforms: string[] }) => {
  const [showAlert, setShowAlert] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const dontRemind = localStorage.getItem('dontRemindDownloadApp');
    if (!dontRemind) {
      setShowAlert(true);
    }
  }, []);

  const handleClose = () => {
    setShowAlert(false);
    localStorage.setItem('dontRemindDownloadApp', 'true');
  };

  const handleClick = () =>
    platforms.includes('android')
      ? window.open('https://play.google.com/store/apps/details?id=com.tshoko.mytshoko')
      : window.open('https://apps.apple.com/app/apple-store/id6449025633');

  return (
    <IonPage>
      <IonContent>
        <StyledDownloadAppModal
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          className="download-app-modal"
        >
          <div className="modal-content">
            <img src="./theme/mobileapp-icon.png" className="logo" />
            <DottedWrapper size={12}>
              <h2 className="title">{t('downloadAppTitle')}</h2>
            </DottedWrapper>
            <div className="description">
              <Trans i18nKey="downloadAppDesc" components={{ strong: <strong /> }} />
            </div>
            <Button onClick={handleClick} expand="block">
              {t('downloadApp')}
            </Button>
            <IonButton onClick={handleClose} fill="clear" className="secondary-button">
              {t('dontRemind')}
            </IonButton>
          </div>
        </StyledDownloadAppModal>
      </IonContent>
    </IonPage>
  );
};

export default DownloadAppAlertContainer;
