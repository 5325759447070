import React, { Ref } from 'react';
import { IonText } from '@ionic/react';
import { StyledIonText } from './Typography.styled';
type IonTypographyProps = React.ComponentPropsWithRef<typeof IonText>;
export type TypographyProps = IonTypographyProps & {
  tag?: keyof HTMLElementTagNameMap;
  loading?: boolean;
  lines?: number;
  variant?: 'primary' | 'secondary';
  innerRef?: Ref<HTMLIonTextElement>;
};

const Typography = (props: TypographyProps) => {
  const { children, tag, className, ...rest } = props;
  const tagType = tag || React.Fragment;

  // eslint-disable-next-line @typescript-eslint/dot-notation
  let LoadingText;
  if (props.loading) {
    LoadingText = () =>
      React.createElement(
        tagType as keyof JSX.IntrinsicElements,
        tag
          ? { className: `${className || ''} ${props.variant === 'secondary' ? 'text-secondary' : 'text-primary'}` }
          : {},
        <></>,
      );
  }

  const Text = () =>
    React.createElement(
      tagType as keyof JSX.IntrinsicElements,
      tag
        ? { className: `${className || ''} ${props.variant === 'secondary' ? 'text-secondary' : 'text-primary'}` }
        : {},
      <>{children}</>,
    );
  return (
    <StyledIonText
      className={`${className ? className : ''} ${props.variant === 'secondary' ? 'text-secondary' : 'text-primary'}`}
      ref={props.innerRef}
      {...rest}
    >
      {props.loading && LoadingText ? <LoadingText /> : <Text />}
    </StyledIonText>
  );
};

export default Typography;
