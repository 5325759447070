import { IonIcon, IonSkeletonText } from '@ionic/react';
import React from 'react';
import { StyledIonAvatar, StyledIonImg } from './Avatar.styled';
import avatar from './avatar.svg';

export type AvatarProps = {
  imgSrc?: string;
  loading?: boolean;
};

const Avatar = (props: AvatarProps) => {
  return (
    <StyledIonAvatar className={!props.imgSrc && !props.loading ? 'no-img' : ''}>
      {props.loading ? (
        <IonSkeletonText animated={true} />
      ) : props.imgSrc ? (
        <StyledIonImg src={props.imgSrc} />
      ) : (
        <IonIcon src={avatar} />
      )}
    </StyledIonAvatar>
  );
};

export default Avatar;
