import { useSelector } from 'react-redux';
import AdminOffline from '../layouts/AdminOfflineLayout';
import { HeaderWithSwitchBox } from './components/HeaderWithSwitchBox';
import { RootState } from '../store/rootReducer';

const Offline = () => {
  const appState = useSelector((state: RootState) => state.AppReducer);

  return appState.boxDetail ? (
    <AdminOffline renderHeader={() => <HeaderWithSwitchBox title={appState.boxDetail?.name} />} />
  ) : null;
};

export default Offline;
