import styled from 'styled-components';
import { IonImg, IonItem } from '@ionic/react';

export const StyledIonItem = styled(IonItem)`
  &.playlist-item {
    --background: none;
    --inner-border-width: 0;
    --border-color: transparent;
    scroll-snap-stop: normal;
    scroll-snap-align: start;
    flex: 1;

    &.loading {
      & ion-label {
        & ion-skeleton-text {
          height: 13px !important;
        }
      }
    }
  }
  & .sc-ion-label-md-s {
    margin-bottom: 0;
    padding-bottom: 11px;
    border-bottom: 1px solid var(--text-color-secondary);

    & h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }
    & h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      color: var(--text-color-secondary);
    }
  }
`;

export const StyledPlaylistSubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  & ion-text {
    overflow: hidden;
  }
`;

export const StyledPlaylistTitle = styled.div`
  display: flex;
  align-items: center;
  & ion-text {
    overflow: hidden;
  }
`;

export const StyledIonImg = styled(IonImg)`
  aspect-ratio: 1 / 1;
`;
