import React from 'react';
import { IonSpinner } from '@ionic/react';

type IonSpinnerProps = React.ComponentProps<typeof IonSpinner>;

export type SpinnerProps = IonSpinnerProps;
const Spinner = (props: SpinnerProps) => {
  return <IonSpinner {...props} name="crescent" color="primary" />;
};

export default Spinner;
