import styled from 'styled-components';
import { useContext } from 'react';
import { KeyboardContext } from '../../contexts/KeyboardContext';
import { isPlatform } from '@ionic/react';
import Button from '../../components/Button';

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: calc(var(--ion-safe-area-top) - 8px);

  & .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    background-position: center;
    background-size: cover;
  }
`;

export const StyledForm = styled(({ ...props }) => {
  const { keyboardState } = useContext(KeyboardContext);
  return (
    <div
      {...props}
      style={{
        transform: `translate(0px, calc(${
          keyboardState?.state === 'open' || keyboardState?.state === 'opening'
            ? `(-${(isPlatform('ios') && keyboardState?.keyboardHeight) || 0}px / 2) + var(--ion-safe-area-bottom, 0px)`
            : `0px`
        }))`,
      }}
    />
  );
})`
  transition: transform 0.25s;
  display: flex;
  flex: 1;
`;

export const StyledForgotPasswordButton = styled(Button)`
  margin-top: 15px;
`;
