import { useDispatch, useSelector } from 'react-redux';
import BottomSheet from '../../../components/BottomSheet';
import { useArtistBottomSheetLayoutProps } from '../../../layouts/ArtistBottomSheetLayout';
import React from 'react';
import { RootState } from '../../../store/rootReducer';
import { closeArtistBottomSheet, setConfirmBlacklistArtistModale } from '../../../store/modaleSlice';
import ArtistDrawer from '../../Drawer/ArtistDrawer';

const ArtistBottomSheet = () => {
  const appState = useSelector((state: RootState) => state.AppReducer);

  const dispatch = useDispatch();
  const ArtistBottomSheetLayoutPropsBuilder = useArtistBottomSheetLayoutProps();
  const artistBottomSheet = useSelector((state: RootState) => state.ModaleReducer.artistBottomSheet);
  const artistBottomSheetIsOpen = useSelector((state: RootState) => state.ModaleReducer.artistBottomSheetIsOpen);

  return artistBottomSheet ? (
    <BottomSheet
      {...{
        isOpen: artistBottomSheetIsOpen,
        onDidDismiss: () => dispatch(closeArtistBottomSheet()),
      }}
      {...ArtistBottomSheetLayoutPropsBuilder({
        artist: artistBottomSheet,
        actions: {
          ...(artistBottomSheet.seeArtistPage
            ? {
                onSeeArtistButton: {
                  artistId: artistBottomSheet.id,
                  callback: () => {
                    dispatch(closeArtistBottomSheet());
                  },
                  artistDrawerComponent: (p) => <ArtistDrawer artistId={p.artistId} />,
                },
              }
            : {}),
          blacklistArtist: appState.isAdminMode
            ? () => {
                dispatch(setConfirmBlacklistArtistModale(artistBottomSheet));
              }
            : undefined,
        },
      })}
    />
  ) : null;
};

export default ArtistBottomSheet;
