import { IonButton, IonSkeletonText, IonSpinner } from '@ionic/react';
import styled from 'styled-components';
import { ButtonProps } from './Button';

export const StyledIonButton = styled((props: ButtonProps) => <IonButton {...props} />)`
  --border-radius: 0;
  --padding-start: 0;
  --padding-end: 0;
  --background: ${(props) => (props.loading ? 'none' : '')};
  --border-width: ${(props) => (props.loading ? 0 : '')};
  pointer-events: ${(props) => (props.spinning || props.loading ? 'none' : '')};

  & .button-content {
    padding-left: 26px;
    padding-right: 26px;
  }
`;

export const StyledIonButtonSpinner = styled((props) => <IonSpinner name="dots" {...props} />)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledIonButtonSkeletonContent = styled((props) => <IonSkeletonText animated={true} {...props} />)`
  --border-radius: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  margin: 0;
`;
