import { IonItem } from '@ionic/react';
import styled from 'styled-components';

export const StyledArtistIonItem = styled(IonItem)`
  &.artist-item {
    --background: none;
    --inner-border-width: 0;
    --border-color: transparent;
    scroll-snap-stop: normal;
    scroll-snap-align: start;

    & .artist-thumbnail {
      width: 80px;
      margin-right: 16px;
      & ::part(native) {
        padding: 0;
      }
    }
  }
  & .sc-ion-label-md-s {
    margin-bottom: 0;
    padding-bottom: 11px;

    & h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }
  }
`;

export const StyledArtistName = styled.div`
  display: flex;
  align-items: center;
  & ion-text {
    overflow: hidden;
  }
`;

export const StyledIonItem = styled(IonItem)`
  --background: none;
  --border-color: transparent;
  color: var(--text-color-secondary);

  & .menu-item {
    display: flex;
    width: 100%;
    height: 48px;
    justify-content: center;
    align-items: center;

    & ion-icon,
    ion-spinner {
      margin-inline-end: 12px;
      margin-top: 12px;
      margin-bottom: 12px;
      font-size: 24px;
    }
    & ion-label {
      width: 212px;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
`;
