import React from 'react';
import { IonContent } from '@ionic/react';
import InfiniteGrid from '../../../../components/InfiniteGrid';
import ArtistCard, { ArtistCardDefaultProps } from '../../../../components/ArtistCard';
import ArtistDrawer from '../../../../features/Drawer/ArtistDrawer';
import useKeyboard from '../../../../utils/hooks/useKeyboard';
import { StyledIonNavLink } from '../../../../features/Drawer/Drawer.styled';

const SearchTabArtists = (props: {
  loading?: boolean;
  onLoadData?: () => void;
  artists: ArtistCardDefaultProps['artist'][];
}) => {
  const { hideKeyboard } = useKeyboard();
  return (
    <IonContent scrollEvents onIonScrollStart={() => hideKeyboard?.()}>
      <InfiniteGrid
        loadingElement={<ArtistCard loading />}
        loading={props.loading}
        onScroll={props.onLoadData}
        elementWidth={`calc(100% / ${Math.floor(window.innerWidth / (320 / 2))})`}
      >
        {props.artists.map((artist, index) => (
          <StyledIonNavLink
            key={`artist-${artist.id}-${index}`}
            routerDirection="forward"
            component={() => <ArtistDrawer artistId={artist.id} />}
          >
            <ArtistCard artist={artist} onClick={() => {}} />
          </StyledIonNavLink>
        ))}
      </InfiniteGrid>
    </IonContent>
  );
};

export default SearchTabArtists;
