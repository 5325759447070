import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../../components/AlertModal';
import { closeConfirmPlayPlaylistModale } from '../../../store/modaleSlice';
import { RootState } from '../../../store/rootReducer';
import { setToast } from '../../../store/toastSlice';
import SocketService from '../../../services/socket';

const ConfirmPlayPlaylist = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const confirmPlayPlaylistModale = useSelector((state: RootState) => state.ModaleReducer.confirmPlayPlaylistModale);
  const confirmPlayPlaylistModaleIsOpen = useSelector(
    (state: RootState) => state.ModaleReducer.confirmPlayPlaylistModaleIsOpen,
  );
  return (
    <AlertModal
      isOpen={confirmPlayPlaylistModaleIsOpen}
      onDidDismiss={() => {
        dispatch(closeConfirmPlayPlaylistModale());
      }}
      header={t('playPlaylistAlertTitle')}
      subHeader={t('playPlaylistAlertDesc')}
      buttons={[
        {
          text: t('cancel'),
          role: 'cancel',
        },
        {
          text: t('play'),
          role: 'confirm',
          handler: () => {
            if (confirmPlayPlaylistModale?.playlistId) {
              SocketService.setCurrentPlaylistId(confirmPlayPlaylistModale.playlistId);
              dispatch(
                setToast({
                  isOpen: true,
                  header: t('playlistAdded'),
                  message: t('playlistAddedMessage'),
                  duration: 5000,
                }),
              );
            }
          },
        },
      ]}
    />
  );
};

export default ConfirmPlayPlaylist;
