import styled from 'styled-components';
import { IonContent, IonHeader, IonModal } from '@ionic/react';

export const StyledIonModal = styled(IonModal)`
  --width: 100%;
  --height: calc(100% + var(--ion-safe-area-bottom));
  backdrop-filter: blur(5px);
`;

export const StyledIonContent = styled(IonContent)`
  --background: none;
  padding-top: calc(var(--ion-safe-area-top) - 16px);
  margin-top: 16px;

  &::part(scroll) {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
  }

  & ion-button {
    width: 48px;
  }

  & .bottom-sheet-content {
    border-top: 1px solid var(--text-color-secondary);
    padding-top: 16px;
  }
`;

export const StyledIonHeader = styled(IonHeader)`
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    overflow: hidden;
  }
`;
