import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import BottomSheet from '../../../components/BottomSheet';
import { closeSpotBottomSheet } from '../../../store/modaleSlice';
import { useSpotBottomSheetLayoutProps } from '../../../layouts/SpotBottomSheetLayout';
import Socketservice from '../../../services/socket';
import { setToast } from '../../../store/toastSlice';
import { useTranslation } from 'react-i18next';

const SpotBottomSheet = () => {
  const appState = useSelector((state: RootState) => state.AppReducer);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const SpotBottomSheetLayoutPropsBuilder = useSpotBottomSheetLayoutProps();
  const spotBottomSheetIsOpen = useSelector((state: RootState) => state.ModaleReducer.spotBottomSheetIsOpen);
  const spotBottomSheet = useSelector((state: RootState) => state.ModaleReducer.spotBottomSheet);

  return spotBottomSheet ? (
    <BottomSheet
      {...{
        isOpen: spotBottomSheetIsOpen,
        onDidDismiss: () => dispatch(closeSpotBottomSheet()),
      }}
      {...SpotBottomSheetLayoutPropsBuilder({
        spot: spotBottomSheet,
        actions: {
          playNow:
            spotBottomSheet.id && appState.isAdminMode
              ? () => {
                  Socketservice.playSpot(spotBottomSheet.id);
                  dispatch(
                    setToast({
                      isOpen: true,
                      header: t('spotAdded'),
                      duration: 5000,
                    }),
                  );
                  dispatch(closeSpotBottomSheet());
                }
              : undefined,
        },
      })}
    />
  ) : null;
};

export default SpotBottomSheet;
