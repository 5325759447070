import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlayerBarContainer } from '../components/PlayerBar';
import PlayerModalLayout from '../layouts/PlayerModalLayout';
import { RootState } from '../store/rootReducer';
import useConnectionLostGuard from './hooks/useConnectionLostGuard';
import PlayerContentLayout from '../layouts/PlayerContentLayout';
import SocketService from '../services/socket';
import useBackToProgConfirm from './hooks/useBackToProgConfirm';
import { useLocation } from 'react-router-dom';
import { AppStateLabel, useAppPages } from './hooks/useAppPages';
import { setConfirmJukeboxModaleIsOpen } from '../store/modaleSlice';
import { setPlayerBarIsOpen } from '../store/modaleSlice';
import { useTranslation } from 'react-i18next';
import { HeaderWithSwitchBox } from './components/HeaderWithSwitchBox';
import { updateVolume } from '../store/playerSlice';
import { setToast } from '../store/toastSlice';

const Player = (props: { fromAdminPage?: boolean; currentAppState?: AppStateLabel }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const backToProg = useBackToProgConfirm();
  const connectionLostGuard = useConnectionLostGuard();
  const playerCurrentMusic = useSelector((state: RootState) => state.PlayerReducer.currentMusic);
  const playerParams = useSelector((state: RootState) => state.PlayerReducer.params);
  const playerVolume = useSelector((state: RootState) => state.PlayerReducer.volume);
  const toast = useSelector((state: RootState) => state.ToastReducer.toast);
  const playerModaleIsOpen = useSelector((state: RootState) => state.ModaleReducer.playerModaleIsOpen);
  const appState = useSelector((state: RootState) => state.AppReducer);
  const appPages = useAppPages();
  const adminPage = location.pathname === appPages.playingAdmin[0].href;
  const { t } = useTranslation();
  const modalRef = useRef<HTMLIonModalElement>(null);

  const handleMute = (action: string) => {
    if (action === 'mute') {
      dispatch(updateVolume('50'));
      SocketService.mute(playerVolume / 2 + 50);
    } else {
      SocketService.unmute();
    }
  };

  return !playerCurrentMusic ||
    !playerParams.isTshokoConnected ||
    playerParams.tshokoStatus === 'stop' ? null : props.fromAdminPage ? (
    <PlayerContentLayout
      admin
      isEphemeral={playerParams.currentPlaylistMode === 'ephemeral'}
      toasted={!!toast}
      onSkipForward={() => {
        connectionLostGuard(() => SocketService.passMusic());
      }}
      volumeValue={Math.round(playerVolume / 5) * 5}
      onVolumeChange={(volume) => {
        const convertedVolume = volume / 2 + 50;
        dispatch(updateVolume(`${convertedVolume}`));
        SocketService.setVolume(convertedVolume);
      }}
      onMuteChange={(action) => handleMute(action)}
      shuffle={playerParams.isPlaylistRandom}
      onShuffleButton={() => {
        SocketService.setPlaylistRandom(!playerParams.isPlaylistRandom);
        dispatch(
          setToast({
            isOpen: true,
            header: playerParams.isPlaylistRandom ? t('shuffleDisabled') : t('shuffleActivated'),
            duration: 3000,
          }),
        );
      }}
      track={playerCurrentMusic}
      jukebox={playerParams.isJukeboxModeOn}
      jukeboxDisabled={!appState.boxDetail?.options.jukebox}
      onJukeboxButton={() => dispatch(setConfirmJukeboxModaleIsOpen(true))}
      onBackToProgButton={() => backToProg()}
      progName={playerParams.currentPlaylistTitle}
      renderHeader={() => <HeaderWithSwitchBox title={appState.boxDetail?.name || ''} subtitle={t('administration')} />}
    />
  ) : !adminPage &&
    (props.currentAppState === 'playingAdmin' ||
      props.currentAppState === 'playingUser' ||
      props.currentAppState === 'playingUserLimitedJukebox') ? (
    <>
      <span
        onClick={() => {
          dispatch(setPlayerBarIsOpen(true));
        }}
      >
        <PlayerBarContainer
          onSkipForward={
            appState.isAdminMode
              ? () => {
                  connectionLostGuard(() => SocketService.passMusic());
                }
              : undefined
          }
          track={playerCurrentMusic}
        />
      </span>
      {appState.boxDetail && (
        <PlayerModalLayout
          track={playerCurrentMusic}
          isEphemeral={playerParams.currentPlaylistMode === 'ephemeral'}
          isOpen={playerModaleIsOpen}
          onDidDismiss={() => {
            dispatch(setPlayerBarIsOpen(false));
          }}
          volumeValue={Math.round(playerVolume / 5) * 5}
          onVolumeChange={(volume) => {
            const convertedVolume = volume / 2 + 50;
            dispatch(updateVolume(`${convertedVolume}`));
            SocketService.setVolume(convertedVolume);
          }}
          onMuteChange={(action) => handleMute(action)}
          admin={appState.isAdminMode}
          jukebox={playerParams.isJukeboxModeOn}
          jukeboxDisabled={!appState.boxDetail.options.jukebox}
          onJukeboxButton={() => SocketService.setJukeboxModeOn(!playerParams.isJukeboxModeOn)}
          onSkipForward={() => {
            connectionLostGuard(() => SocketService.passMusic());
          }}
          onBackToProgButton={() => {
            backToProg();
          }}
          shuffle={playerParams.isPlaylistRandom}
          onShuffleButton={() => {
            SocketService.setPlaylistRandom(!playerParams.isPlaylistRandom);
            dispatch(
              setToast({
                isOpen: true,
                header: playerParams.isPlaylistRandom ? t('shuffleDisabled') : t('shuffleActivated'),
                duration: 3000,
              }),
            );
            dispatch(setPlayerBarIsOpen(false));
          }}
          progName={playerParams.currentPlaylistTitle}
          modal={modalRef}
          renderHeader={() => (
            <HeaderWithSwitchBox
              title={appState.isAdminMode ? appState.boxDetail?.name : undefined}
              subtitle={appState.isAdminMode ? t('administration') : t('music')}
              onDismissModal={() => {
                modalRef.current?.dismiss();
              }}
            />
          )}
        />
      )}
    </>
  ) : null;
};

export default Player;
