import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrackItemDefaultProps } from '../../components/TrackItem';
import ArtistTracksLayout from '../../layouts/Search/ArtistTracksLayout';
import { useGetBlacklistsQuery } from '../../services/api.service';
import { useLazyArtistDetailedQuery } from '../../services/qobuz.service';
import { RootState } from '../../store/rootReducer';
import { PAGINATION_SIZE } from '../Search/selector';
import { buildTrackItemFromTrackDto } from '../Search/types';
import { useVoteTrackHook } from '../hooks/useVoteTrack';
import { setTrackBottomSheet } from '../../store/modaleSlice';

type ArtistTracksPageWrapperType = {
  artistId: number;
};

const ArtistTracksDrawer = (props: ArtistTracksPageWrapperType) => {
  const dispatch = useDispatch();
  const appState = useSelector((state: RootState) => state.AppReducer);

  const [fetchDetailedArtist, { data, isFetching, isLoading }] = useLazyArtistDetailedQuery();
  const { data: aggregatedBlacklists } = useGetBlacklistsQuery(appState.boxDetail?._id || '');
  const initialArtistTracksState = {
    artistId: '',
    tracksOffset: 0,
    tracks: [] as TrackItemDefaultProps['track'][],
  };
  const [artistTracksState, setArtistTracksState] = useState(initialArtistTracksState);
  const votesList = useSelector((state: RootState) => state.PlayerReducer.votesList);
  const voteTrack = useVoteTrackHook();
  const isJukeboxModeOn = useSelector((state: RootState) => state.PlayerReducer.params.isJukeboxModeOn);

  useEffect(() => {
    fetchDetailedArtist(
      {
        artist_id: `${props.artistId}`,
        limit: PAGINATION_SIZE,
        offset: 0,
      },
      true,
    )
      .unwrap()
      .then((artistDetailedWithTracksDto) => {
        setArtistTracksState(() => ({
          artistId: `${props.artistId}`,
          tracks: artistDetailedWithTracksDto.top_tracks.map((trackDto) =>
            buildTrackItemFromTrackDto(trackDto, { blacklists: aggregatedBlacklists, userVotes: votesList }),
          ),
          tracksOffset: 0,
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.artistId]);

  return data && !isLoading ? (
    <ArtistTracksLayout
      adminMode={appState.isAdminMode}
      artist={{ id: data.id, name: data.name.display }}
      tracks={artistTracksState.tracks}
      isTracksFetching={isFetching}
      onClickTrack={(track) => {
        dispatch(setTrackBottomSheet(track));
      }}
      onClickTrackVote={
        isJukeboxModeOn
          ? (track) => {
              voteTrack(track);
            }
          : undefined
      }
    />
  ) : (
    <ArtistTracksLayout loading />
  );
};

export default ArtistTracksDrawer;
