import React, { useEffect, useState } from 'react';
import { PlaylistItemDefaultProps } from '../../components/PlaylistItem';
import AmbianceLayout from '../../layouts/Search/AmbianceLayout';
import { useLazyGetAmbiancePlaylistsQuery } from '../../services/api.service';
import useConnectionLostGuard from '../hooks/useConnectionLostGuard';
import { getAmbianceFromQuerySelector, PAGINATION_SIZE } from '../Search/selector';
import { buildPlaylistItemFromPlaylistDto } from '../Search/types';
import store from '../../store/store';
import { useDispatch } from 'react-redux';
import PlaylistDrawer from './PlaylistDrawer';
import { setConfirmPlayAmbianceModale } from '../../store/modaleSlice';

type AmbianceDrawerProps = { ambianceId: string; color?: string };

const AmbianceDrawer = (props: AmbianceDrawerProps) => {
  const dispatch = useDispatch();
  const connectionLostGuard = useConnectionLostGuard();
  const ambianceQuery = getAmbianceFromQuerySelector(store.getState())(`${props.ambianceId}`);
  const [fetchAmbiancePlaylists, { isFetching, isLoading }] = useLazyGetAmbiancePlaylistsQuery();
  const initialAmbiancePlaylistsState = {
    ambianceId: '',
    playlistsOffset: 0,
    playlists: [] as PlaylistItemDefaultProps['playlist'][],
  };
  const [ambiancePlaylistsState, setAmbiancePlaylistsState] = useState(initialAmbiancePlaylistsState);

  useEffect(() => {
    fetchAmbiancePlaylists(
      {
        ambianceId: `${props.ambianceId}`,
        limit: PAGINATION_SIZE,
        skip: 0,
      },
      true,
    )
      .unwrap()
      .then((ambiancePlaylists) => {
        setAmbiancePlaylistsState(() => ({
          ambianceId: `${props.ambianceId}`,
          playlists: ambiancePlaylists.playlists.map((playlistDto) => buildPlaylistItemFromPlaylistDto(playlistDto)),
          playlistsOffset: 0,
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ambianceId]);

  const ambianceDetailed =
    ambianceQuery && ambiancePlaylistsState
      ? {
          ...ambianceQuery,
          img: ambianceQuery.image,
          playlists: ambiancePlaylistsState.playlists,
        }
      : undefined;

  return ambianceDetailed && !isLoading ? (
    <AmbianceLayout
      ambiance={ambianceDetailed}
      onPlaylistClickDrawerComponent={(p) => (
        <PlaylistDrawer playlistId={p.playlistId} ambianceColor={props.color} ambianceId={props.ambianceId} />
      )}
      onPlayClick={() => {
        connectionLostGuard(() => {
          dispatch(setConfirmPlayAmbianceModale({ ambianceId: props.ambianceId }));
        });
      }}
      playlists={ambianceDetailed.playlists}
      playlistsCount={ambianceDetailed.playlistsCount}
      isPlaylistsFetching={isFetching}
      loadDataPlaylists={
        ambianceDetailed.playlists.length < ambianceDetailed.playlistsCount
          ? () => {
              const newOffset = ambiancePlaylistsState.playlistsOffset + PAGINATION_SIZE;
              fetchAmbiancePlaylists(
                {
                  ambianceId: ambianceDetailed.id,
                  limit: PAGINATION_SIZE,
                  skip: newOffset,
                },
                true,
              )
                .unwrap()
                .then((ambianceDetailedWithPlaylistsDto) => {
                  setAmbiancePlaylistsState((oldAmbiancePlaylistsState) => ({
                    ambianceId: ambianceDetailed.id,
                    playlists: [
                      ...oldAmbiancePlaylistsState.playlists,
                      ...ambianceDetailedWithPlaylistsDto.playlists.map((playlistDto) =>
                        buildPlaylistItemFromPlaylistDto(playlistDto),
                      ),
                    ],
                    playlistsOffset: newOffset,
                  }));
                });
            }
          : undefined
      }
    />
  ) : (
    <AmbianceLayout loading ambiance={props.color ? { color: props.color } : undefined} />
  );
};

export default AmbianceDrawer;
