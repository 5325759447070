import { IonButton } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonButton = styled(IonButton)`
  &.play-button {
    --padding-start: 0;
    --padding-end: 0;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    font-size: 20px;
    aspect-ratio: 1/1;
  }

  & div {
    width: 20px;
    height: 20px;
    margin-left: 4px;
    pointer-events: none;
  }
`;
