import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../../components/AlertModal';
import { closeConfirmPlayAmbianceModale } from '../../../store/modaleSlice';
import { RootState } from '../../../store/rootReducer';
import { setToast } from '../../../store/toastSlice';
import SocketService from '../../../services/socket';

const ConfirmPlayAmbiance = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const confirmPlayAmbianceModale = useSelector((state: RootState) => state.ModaleReducer.confirmPlayAmbianceModale);
  const confirmPlayAmbianceModaleIsOpen = useSelector(
    (state: RootState) => state.ModaleReducer.confirmPlayAmbianceModaleIsOpen,
  );
  return (
    <AlertModal
      isOpen={confirmPlayAmbianceModaleIsOpen}
      onDidDismiss={() => {
        dispatch(closeConfirmPlayAmbianceModale());
      }}
      header={t('playAmbianceAlertTitle')}
      subHeader={t('playAmbianceAlertDesc')}
      buttons={[
        {
          text: t('cancel'),
          role: 'cancel',
        },
        {
          text: t('play'),
          role: 'confirm',
          handler: () => {
            if (confirmPlayAmbianceModale?.ambianceId) {
              SocketService.setCurrentAmbianceId(confirmPlayAmbianceModale.ambianceId);
              dispatch(
                setToast({
                  isOpen: true,
                  header: t('ambianceAdded'),
                  message: t('ambianceAddedMessage'),
                  duration: 5000,
                }),
              );
            }
          },
        },
      ]}
    />
  );
};

export default ConfirmPlayAmbiance;
