import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledPageContent, StyledContentContainer } from './InfoLayout.styled';
import Button from '../../components/Button';
import { IonCol, IonRow } from '@ionic/react';
import DottedWrapper from '../../components/DottedWrapper/DottedWrapper';

export type InfoLayoutProps = {
  loading?: boolean;
  img?: string;
  buttonHref?: string;
  buttonText?: string;
};

const InfoLayout = (props: InfoLayoutProps) => {
  const { t } = useTranslation();
  const logoNode = (
    <div>
      <img src="./theme/mobileapp-logo.png" style={{ width: '100%' }} />
    </div>
  );
  return (
    <StyledPageContent>
      <StyledContentContainer>
        <div
          className="content"
          style={{
            backgroundImage: `url(${
              props.img || 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
            })`,
          }}
        >
          <IonRow className="ion-justify-content-center ion-text-center">
            <IonCol style={{ maxWidth: 320 }}>
              {props.buttonHref?.includes('tshoko.fr') ? <DottedWrapper size={22}>{logoNode}</DottedWrapper> : logoNode}
              <IonRow class="ion-margin ion-justify-content-center">
                <a href={props.buttonHref} target="_blank">
                  <Button loading={props.loading}>{props.buttonText || t('about')}</Button>
                </a>
              </IonRow>
            </IonCol>
          </IonRow>
        </div>
      </StyledContentContainer>
    </StyledPageContent>
  );
};

export default InfoLayout;
