import React from 'react';
import { IonContent, IonHeader, IonNavLink } from '@ionic/react';
import { StyledPageContent, StyledHeaderContainer, StyledArtistTitleContainer } from './ArtistTracksLayout.styled';
import Typography from '../../../components/Typography';
import IconButton from '../../../components/IconButton';
import { chevronBack } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { TrackItemDefaultProps } from '../../../components/TrackItem';
import TrackList from '../../../components/TrackList';

export type ArtistTracksLayoutDefaultProps = {
  loading?: boolean;
  artist: {
    id: number;
    name: string;
  };
  tracks: TrackItemDefaultProps['track'][];
  isTracksFetching: boolean;
  onClickTrack: (track: TrackItemDefaultProps['track']) => void;
  onClickTrackVote?: (track: TrackItemDefaultProps['track']) => void;
  adminMode?: boolean;
};

type ArtistTracksLayoutLoadingProps = {
  loading: true;
};

export type ArtistTracksLayoutProps = ArtistTracksLayoutDefaultProps | ArtistTracksLayoutLoadingProps;

const ArtistTracksLayout = (props: ArtistTracksLayoutProps) => {
  const { t } = useTranslation();

  return (
    <>
      <IonContent>
        <StyledPageContent>
          <StyledHeaderContainer>
            <IonHeader>
              <IonNavLink routerDirection="back">
                <IconButton icon={chevronBack} size="large" />
              </IonNavLink>
              <StyledArtistTitleContainer>
                {props.loading ? (
                  <Typography tag="h2" loading />
                ) : (
                  <Typography tag="h2">{props.artist.name}</Typography>
                )}
                {props.loading ? (
                  <Typography tag="h5" variant="secondary" loading />
                ) : (
                  <Typography tag="h5" variant="secondary">
                    {t('popularTracks')}
                  </Typography>
                )}
              </StyledArtistTitleContainer>
            </IonHeader>
          </StyledHeaderContainer>
          <IonContent>
            <TrackList
              tracks={
                props.loading
                  ? Array.from({ length: 30 }).map(() => ({ loading: true }))
                  : props.tracks.map((tr) => ({
                      track: tr,
                      onClick: () => {
                        props.onClickTrack(tr);
                      },
                      onVoteClick: () => {
                        props.onClickTrackVote?.(tr);
                      },
                    }))
              }
            />
          </IonContent>
        </StyledPageContent>
      </IonContent>
    </>
  );
};

export default ArtistTracksLayout;
