import React from 'react';
import { useTranslation } from 'react-i18next';
import BottomSheet, { BottomSheetProps } from '../../components/BottomSheet';
import { Without } from '../../utils/types';
import { IonIcon, IonLabel, IonNavLink } from '@ionic/react';
import { StyledArtistIonItem, StyledIonItem, StyledArtistName } from './ArtistBottomSheetLayout.styled';
import { person, banOutline } from 'ionicons/icons';
import Typography from '../../components/Typography';
import ArtistCard from '../../components/ArtistCard';

export type ArtistBottomSheetLayoutProps = Without<BottomSheetProps, 'onDidDismiss' | 'isOpen'>;

type ArtistBottomSheetProps = {
  artist: {
    id: number;
    name: string;
    img?: string;
  };
  actions?: {
    onSeeArtistButton?: {
      artistId: number;
      callback: () => void;
      artistDrawerComponent: (props: { artistId: number }) => JSX.Element;
    };
    blacklistArtist?: () => void;
  };
};

export const useArtistBottomSheetLayoutProps = () => {
  const { t } = useTranslation();

  const ArtistBottomSheetLayoutBuilder = (props: ArtistBottomSheetProps) => {
    const actionArtist = props.actions?.onSeeArtistButton;
    return {
      header: (
        <StyledArtistIonItem className={`artist-item`} mode="md">
          <div className="artist-thumbnail">
            <ArtistCard artist={props.artist} hideText />
          </div>
          <IonLabel>
            <StyledArtistName>
              <Typography tag="h3" className="ion-text-nowrap">
                {props.artist.name}
              </Typography>
            </StyledArtistName>
          </IonLabel>
        </StyledArtistIonItem>
      ),
      content: (
        <>
          {actionArtist && (
            <IonNavLink component={() => actionArtist.artistDrawerComponent({ artistId: props.artist.id })}>
              <StyledIonItem button mode="md" onClick={() => actionArtist.callback()}>
                <div className="menu-item">
                  <IonIcon icon={person} />
                  <IonLabel>{t('seeArtistPage')}</IonLabel>
                </div>
              </StyledIonItem>
            </IonNavLink>
          )}
          {props.actions?.blacklistArtist && (
            <StyledIonItem button mode="md" onClick={() => props.actions?.blacklistArtist?.()}>
              <div className="menu-item">
                <IonIcon icon={banOutline} />
                <IonLabel>{t('blacklistArtist')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
        </>
      ),
      initialBreakpoint:
        Math.ceil(
          ((148 + Object.values(props.actions || {}).filter((a) => !!a).length * 48) * 100) / window.innerHeight,
        ) / 100,
    };
  };
  return ArtistBottomSheetLayoutBuilder;
};

// used only in storybook :
const ArtistBottomSheetLayout = (
  props: Without<BottomSheetProps, keyof ArtistBottomSheetLayoutProps> & ArtistBottomSheetProps,
) => {
  const ArtistBottomSheetLayoutPropsBuilder = useArtistBottomSheetLayoutProps();
  const { artist, actions, ...bottomSheetProps } = props;
  return (
    <BottomSheet
      {...bottomSheetProps}
      {...ArtistBottomSheetLayoutPropsBuilder({
        artist,
        actions,
      })}
    />
  );
};

export default ArtistBottomSheetLayout;
