import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import Router from './Router';
import store from './store/store';
import { KeyboardContextProvider } from './contexts/KeyboardContext';

const AppWithStore = () => (
  <KeyboardContextProvider>
    <Provider store={store}>
      <IonApp>
        <Suspense fallback={<div>loading...</div>}>
          <IonReactRouter>
            <Router />
          </IonReactRouter>
        </Suspense>
      </IonApp>
    </Provider>
  </KeyboardContextProvider>
);

export default AppWithStore;
