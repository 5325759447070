import React from 'react';
import RestrictedSearchLayout from '../../layouts/Search/RestrictedSearchLayout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { useGetCurrentPlaylistTracksQuery } from '../../services/api.service';
import { setTrackBottomSheet } from '../../store/modaleSlice';
import { useVoteTrackHook } from '../hooks/useVoteTrack';

const RestrictedSearch = () => {
  const dispatch = useDispatch();
  const voteTrack = useVoteTrackHook();

  const appState = useSelector((state: RootState) => state.AppReducer);

  const { data: currentPlaylistTracks, isSuccess } = useGetCurrentPlaylistTracksQuery({
    boxId: appState.boxDetail?._id || '',
  });

  if (!appState || !isSuccess) return null;

  return (
    <RestrictedSearchLayout
      tracks={currentPlaylistTracks}
      onClickTrack={(track) => dispatch(setTrackBottomSheet(track))}
      onClickTrackVote={(track) => {
        voteTrack(track);
      }}
    />
  );
};

export default RestrictedSearch;
